
#career{
    .row{
        max-width: 990px !important;
        @include breakpoint(1024px down){
          width: 92%;
        }
        .row{
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
    }

    #subpage-main-paralax{

        .paralax-content{
            h1{
                margin-bottom: 0px;
                padding: 0px;
                border-bottom: none;
                font-size: 42px;
                line-height: 1.25em;
                text-transform: uppercase;
                padding-left:0!important;
            }
            h2{
                font-size: 32px;
                line-height: 1.9em;
                font-weight: 500;
            }
        }
    }

    .career-section{

        .widget-content-bg{
            margin-top: 80px;
            margin-bottom: 70px;
            padding-bottom: 10px;
            border-bottom: 2px solid $red-color-site;
            .paralax-svg-icon{
                margin-left: 0px;
                margin-top: 0px;
            }
            .paralax-content-columns{
              float:left !important;
            }
            .paralax-content{
              padding-left: 65px;
                @include breakpoint(1024px down){
                  padding-left: 0;
                  height: 40px;
                  vertical-align: middle;
                  display: table-cell;
                }
                h1{
                    font-size: 36px;
                    border-bottom: none !important;
                    padding-bottom: 0 !important;
                    padding-top: 0 !important;
                    padding-left: 0 !important;
                    margin-bottom: 0 !important;
                    @include breakpoint(736px down){
                      font-size: 18px !important;
                    }
                }
            }
            @include breakpoint(736px down){
              margin-top: 50px;
              margin-bottom: 10px;
              padding-bottom: 0px;
            }
        }

        .side-nav{
            margin-bottom: 100px;
            list-style: none;
            *{
                opacity: 1;
            }
            li{
                border: none;
                a.level-one{
                    color: $lightblack;
                    font-size: 20px;
                    margin-bottom: 20px;
                    pointer-events: none;
                    cursor: default;
                    @include breakpoint(736px down){
                      font-size: 18px;
                    }
                }
                &.has-dropdown{
                    ul{
                        border: none;
                        @include clearfix;
                        li{
                            float: left;
                            margin-bottom: 2%;
                            width: 49%;
                            padding: 0;
                            background: none;
                            border: none;
                            position: relative;
                            overflow: hidden;

                            @include breakpoint(medium down) {
                                width: 100%;
                            }

                            &:nth-child(2n){
                                margin-left: 2%;
                                @include breakpoint(medium down) {
                                    margin-left: 0px;
                                }
                            }

                            a{
                                background-color: $red-color-site;
                                color: $white;
                                display: inline-block;
                                font-size: 20px;
                                font-weight: 300;
                                height: 54px;
                                line-height: 54px;
                                overflow: hidden;
                                padding: 1px 16px 0;
                                position: relative;
                                text-decoration: none;
                                text-transform: uppercase;
                                transition: background-color 0.25s ease-in-out 0s;
                                width: 100%;
                                @include breakpoint(small down) {
                                    font-size: 14px;
                                }
                                &::before{
                                    content: "";
                                    display: inline-block;
                                    float: right;
                                    font-family: "fontawesome";
                                    font-size: 30px;
                                    line-height: 51px;
                                    margin-left: 31px;
                                }
                                // &::after{
                                //     background: $white;
                                //     content: "Aplikuj";
                                //     display: inline-block;
                                //     float: right;
                                //     height: 52px;
                                //     line-height: 52px;
                                //     padding: 0 20px;
                                //     position: absolute;
                                //     right: -200px;
                                //     top: 1px;
                                //     color: $red-color-site;
                                //     text-transform: uppercase;
                                //     font-weight: bold;
                                //     transition: right 0.25s ease-in-out 0s;
                                // }
                                &:hover{
                                    // &::after{
                                    //     right: 1px;
                                    // }
                                    .btnaplikuj{
                                      right: 1px;
                                    }
                                }
                              .btnaplikuj{
                                background: $white;
                                content: "Aplikuj";
                                display: inline-block;
                                float: right;
                                height: 52px;
                                line-height: 52px;
                                padding: 0 20px;
                                position: absolute;
                                right: -200px;
                                top: 1px;
                                color: $red-color-site;
                                text-transform: uppercase;
                                font-weight: bold;
                                transition: right 0.25s ease-in-out 0s;

                              }
                            }
                        }
                    }
                }
            }
        }
        h3.header{
          font-size: 20px;
          margin-bottom: 20px;
        }
        .form-content{

            .row{
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
            }

            label{
                display: none;
            }

            [type="text"],
            [type="password"],
            [type="date"],
            [type="datetime"],
            [type="datetime-local"],
            [type="month"],
            [type="week"],
            [type="email"],
            [type="number"],
            [type="search"],
            [type="tel"],
            [type="time"],
            [type="url"],
            [type="color"],
            textarea{
                border: 2px solid $red-color-site;
                font-size: 18px;
                padding: 10px;
                line-height: 1.2em;
                height: 55px;
                box-shadow: none;
                &::-moz-placeholder {
                    color: $gray;
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            .button{
                background: $red-color-site;
                color: $white;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                width: 100%;
                height: 54px;
                margin-bottom: 20px;                
                border-color: $red-color-site;
                font-family: "Roboto", sans-serif !important;
                &:hover{
                    border-color: $red-color-site;;
                }
            }

            textarea{
                height: 100px;
            }

            .form-file{
                label{
                    display: block;
                    border: 2px dashed $red-color-site;
                    margin: 0;
                    margin-bottom: 10px;
                    font-size: 15px;
                    padding: 8px;
                    color: $gray;
                    p{
                        display: block;
                        color: #bf1818;
                        line-height: 1.5em;
                        padding: 0px;
                        font-size: 15px;
                        margin: 0px;
                    }
                }
                input[type="file"]{
                    display: none;
                }
                p{
                    display: none;
                }
            }
            .form-checkboxes{
                color:#333333;
              ul{
                margin-left:0;
                li{
                  label{
                    display:block;
                    color:#333333;
                  }
                }
              }
            }
        }
    }
}
