//
//	To include additional font:
//
//	1. Add your font files to '/public/themes/your-theme/fonts' directory
//		(Required formats: '.eot', '.woff', '.ttf', '.svg')
//
//	2. use:
//		@include font-face('Font-name', 'font-file-name', 'font-weight(optional)', 'font-style(optional), 'font-stretch(optional)');
//
//
//	e.g.
//		@include font-face('YourFont', 'font-name-webfont');
//		@include font-face('YourFont', 'font-name-bold-webfont', bold);
//		@include font-face('YourFont', 'font-name-italic-webfont', normal, italic);



@import '../../bower/font-awesome/scss/font-awesome';

.font-icon{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    // text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include font-face("edito",'edito');

[class^="edito_"], [class*=" edito_"], .edito-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'edito' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.edito_dots-two-vertical:before {
  content: "\e91f";
}
.edito_dots-three-vertical:before {
  content: "\e920";
}
.edito_ribbon:before {
  content: "\e90f";
}
.edito_power-plug:before {
  content: "\e90a";
}
.edito_files-empty:before {
  content: "\e910";
}
.edito_file-libreoffice:before {
  content: "\e911";
}
.edito_file-excel:before {
  content: "\e912";
}
.edito_file-word:before {
  content: "\e913";
}
.edito_file-openoffice:before {
  content: "\e914";
}
.edito_file-pdf:before {
  content: "\e915";
}
.edito_file-zip:before {
  content: "\e916";
}
.edito_file-video:before {
  content: "\e917";
}
.edito_file-play:before {
  content: "\e918";
}
.edito_file-music:before {
  content: "\e919";
}
.edito_file-picture:before {
  content: "\e91a";
}
.edito_file-text:before {
  content: "\e91b";
}
.edito_profile:before {
  content: "\e91c";
}
.edito_file-text2:before {
  content: "\e91d";
}
.edito_file-empty:before {
  content: "\e91e";
}
.edito_cancel:before {
  content: "\e90e";
}
.edito_info:before {
  content: "\e90b";
}
.edito_warning:before {
  content: "\e90d";
}
.edito_check:before {
  content: "\e90c";
}
.edito_magnifier:before {
  content: "\e909";
}
.edito_arrow-long-right:before {
  content: "\e908";
}
.edito_help:before {
  content: "\e903";
}
.edito_arrow_right:before {
  content: "\e901";
}
.edito_arrow_left:before {
  content: "\e902";
}
.edito_add:before {
  content: "\e900";
}
.edito_close:before {
  content: "\e907";
}
.edito_more_vert:before {
  content: "\e904";
}
.edito_arrow-left:before {
  content: "\e905";
}
.edito_arrow-right:before {
  content: "\e906";
}
