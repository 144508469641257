body.mourning{
    img,
    .widget .rotator-widget .owl-item .item{
        filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); 
        filter: gray;
        -webkit-filter: grayscale(100%);
    }
    header .top-bar .name .logo{
        position: relative;
        margin-right: -34px;
        padding-right: 34px;
        &:before{
            @extend .edito-icon;
            content: '\e90f';
            color: #000;
            font-size: 52px;
            position: absolute;
            right: -9px;
            bottom: 5px;
            z-index: 1;
        }
    }
}