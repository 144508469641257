
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #offer{
    .offer-contact{
      .offer-image-contact{
            background-image: none !important;
      }
    }
  }

  #offer .offer-section .content .add_active{
    @include breakpoint(1024px down){
        transform: translate(0, -80%) !important;
    }
  }
  .widget-corporate-governance .paralax-content ul li{
    margin-top:0 !important;
    display: list-item;
  }
  #finanse{
    &.animated {  
      opacity:1 !important;
    }
  } 
}

@supports (-ms-ime-align:auto) {
  #offer{
    .offer-contact{
      .offer-image-contact{
            background-image: none !important;
      }
    }
  }
  #offer .offer-section .content .add_active{
    @include breakpoint(1024px down){
        transform: translate(0, -80%) !important;
    }
  }
}
