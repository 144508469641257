
.reveal-overlay{
    background: rgba(153, 153, 153, 0.85);
    z-index: 999999;
    .reveal{
        max-width: 100%;
        width: 100%;
        background: $lightblack;
        border: none;
        padding: 100px;
        height: auto !important;
        min-height: auto !important;
        top:70px !important;
        margin-bottom:70px;
        overflow: hidden;
        @include breakpoint(736px down){
            padding: 20px;
            margin-bottom:70px;
        }
        *{
            color: white;
        }
        .column{
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 30px;
          display: inline-block;
          float:left;
        //   width:100%;
          vertical-align: top;
          @include breakpoint(736px down){
              width: 100%;
          }
        }
        .tabs-nav{
            list-style: none;
        }
        .reveal-header{
            border-bottom: 2px solid #fff;
            margin-bottom: 100px;
            padding-bottom: 30px;
            @include breakpoint(736px down){
                margin-bottom: 50px;
            }
            .reveal-title{
                display: inline-block;
                float: left;
                font-size: 35px;
                max-width: 90%;
                @include breakpoint(736px down){
                    font-size: 20px;
                    margin-top: 13px;
                    font-size: 22px !important;
                    margin-bottom: 0 !important;
                    max-width: 80%;
                }
            }
            .close-button{
                display: inline-block;
                background-color: #bf1818;
                color: #fff;
                float: right;
                font-size: 24px;
                height: 50px;
                transition: background-color 0.25s ease-in-out 0s;
                width: 50px;
            }
            @include clearfix;
        }           
    }

    .modal-cg-biography{
        .column{
            width:32%;
            @include breakpoint(small down){
                width: 100%;
            }
        }
    }
    .back-to-parent{
        background-color: $red-color-site;
        box-sizing: border-box;
        color: $white;
        display: inline-block;
        font-size: 14px;
        height: 54px;
        line-height: 54px;
        padding: 1px 16px 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.25s ease-in-out 0s;
        margin-bottom: 10px;
        &::after{
            content: "\f104";
            display: inline-block;
            float: left;
            font-family: "fontawesome";
            font-size: 30px;
            line-height: 51px;
            margin-right: 15px;
        }
    }
    .show-biography{
        background-color: $red-color-site;
        box-sizing: border-box;
        color: $white;
        display: inline-block;
        font-size: 14px;
        height: 54px;
        line-height: 54px;
        margin-top: 15px;
        padding: 1px 16px 0;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 0.25s ease-in-out 0s;
        &::after{
            content: "\f105";
            display: inline-block;
            float: right;
            font-family: "fontawesome";
            font-size: 30px;
            line-height: 51px;
            margin-left: 31px;
        }
    }
    .item-gallery{
        text-align: center;
        margin-top: 20px;
        a{
            float: left;
        }      
        img{
            margin-bottom: 15px;
            margin-right: 15px;
        }        
    }
    .biography-description{
        p{
           margin-bottom: 15px;
        }
    }  
}
