// lib
@import 'lib/variable';
@import 'lib/functions';
@import 'lib/mixins';

@import 'app/fonts';
//
// body{
//     font-family: $main-font;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top:0px;
//     left:0px;
//     margin: 0;
//     *{
//         box-sizing: border-box;
//     }
//     a{
//         text-decoration: none;
//         &:hover{
//             text-decoration: none;
//         }
//     }
//     .half_left,.half_right{
//         position: absolute;
//         width: 50%;
//         height: 100%;
//         top:0px;
//     }
//     .half_left{
//         left: 0px;
//         background-color: $blue-dark;
//     }
//     .half_right{
//         right: 0px;
//     }
//     .title_page{
//         position: absolute;
//         @include center();
//         margin-top: -5%;
//         font-size: 160px;
//         width: 400px;
//         margin-left: 2px;
//         span{
//             vertical-align: middle;
//             display: inline-block;
//             &.cross{
//                 width: 52px;
//                 overflow: hidden;
//                 box-sizing: border-box;
//             }
//         }
//         .white{
//             color:$white;
//             &.cross{
//                 margin-right: -52px;
//             }
//         }
//         .black{
//             color:$blue-dark;
//             &.cross{
//                 @include transform(rotate(180deg));
//             }
//         }
//     }
//     .content{
//         width: 100%;
//         max-width: 1280px;
//         margin: 0 auto;
//     }
//     .back_home{
//         position: absolute;
//         right: 10%;
//         top: 10%;
//         font-size: 17px;
//         font-weight: bold;
//         color: $blue-dark;
//         i{
//             font-size: 22px;
//             vertical-align: top;
//             display: inline-block;
//             margin-right: 7px;
//             @include duration();
//         }
//         &:hover{
//             text-decoration: none;
//             i{
//                 margin-right: 14px;
//             }
//         }
//     }
//     .logo{
//         position: absolute;
//         right: 10%;
//         bottom: 15%;
//     }
//     .text{
//         position: absolute;
//         color: $white;
//         bottom: 15%;
//         width: 50%;
//         padding-left: 15%;
//         padding-right:10px;
//         .title{
//             font-size: 45px;
//             font-weight: bold;
//             text-align: center;
//             margin-bottom: 18px;
//         }
//         .desc{
//             font-size: 20px;
//         }
//         @media screen and (max-width: 1100px) {
//             padding: 0 10px;
//             bottom: 7%;
//         }
//     }
//     @media screen and (max-width: 676px) {
//         .title_page{
//             width: 225px;
//             font-size: 80px;
//             margin-left: 12px;
//             span{
//                 &.cross{
//                     width: 27px;
//                     &.white{
//                         margin-right: -27px;
//                     }
//                 }
//             }
//         }
//         .text{
//             .title{
//                 font-size: 21px;
//                 margin-bottom: 5px;
//             }
//             .desc{
//                 font-size: 12px;
//             }
//         }
//         .logo{
//             right: calc(25% - 62.5px);
//             padding: 0 10px;
//             bottom: 7%;
//             img{
//                 width: 125px;
//             }
//         }
//         .back_home{
//             font-size: 0;
//             background: $blue-dark;
//             color: $white;
//             padding: 10px 11px;
//             i{
//                 display: none;
//             }
//             &:before{
//                 display: inline-block;
//                 font: normal normal normal 14px/1 FontAwesome;
//                 font-size: inherit;
//                 text-rendering: auto;
//                 -webkit-font-smoothing: antialiased;
//                 -moz-osx-font-smoothing: grayscale;
//                 content: '\f015';
//                 font-size: 37px;
//             }
//         }
//     }
// }

#error{
  .paralax-content{
    .top-big-text{
      font-size:90px;
    }
  }
  .widget{
    .heading, .news-list
    {
        padding: 20px;
        max-width: 75rem;
        margin-left: auto;
        margin-right: auto;
        clear: both;
    }
      .date, .title{
          color: #000;
      }
      .date{
          display:none;
      }
      .title:hover{
          color: #000;
      }
    .read-more.btn{
        clear: both;
        display:block;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-left: 45%;
    }
    a, a:hover{
      color:$white;
    }

  }
  #redirect{
    .paralax-content{
        padding: 210px 0;
        text-align: center;
    }

  }

  .back_home{
      padding: 0 16px;
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      font-size: 14px;
      height: 54px;
      line-height: 54px;
      box-sizing: border-box;
      padding-top: 1px;
      text-decoration: none;
      background-color: #bf1818;
      -moz-transition: background-color 0.25s ease-in-out;
      -o-transition: background-color 0.25s ease-in-out;
      -webkit-transition: background-color 0.25s ease-in-out;
      transition: background-color 0.25s ease-in-out;
      &:hover{
        background-color: #E84747;
      }
      &:before{
        content: "\f105";
        display: inline-block;
        font-family: 'fontawesome';
        font-size: 30px;
        line-height: 51px;
        float: right;
        margin-left: 31px;
      }
  }
}
