

.for-suppliers-modal{
    .reaval-content{
        .column{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .tabs-nav{
        width: 30%;
        float: left;
        @include breakpoint(736px down){
            width: 100%;
            margin-bottom: 0;
        }
    }
    ul.tabs-nav{
        margin-left: 0;
        list-style: none;
        li{
            margin-bottom: 50px;
            @include breakpoint(736px down){
                margin-bottom: 20px;
                line-height: 1;
            }
            &.is-active{
                a{
                    color: $red-color-site;
                }
            }
            a{
                color: $blue-light;
                font-size: 18px;
                font-weight: 700;
                text-decoration: none;
                -moz-transition: color 0.25s ease-in-out;
                -o-transition: color 0.25s ease-in-out;
                -webkit-transition: color 0.25s ease-in-out;
                transition: color 0.25s ease-in-out;
                &:hover{
                    color: $red-color-site;
                }
            }
        }
    }
    .tabs-content{
        width: 70%;
        float: left;
        @include breakpoint(736px down){
            width: 100%;
        }
        ul{
            list-style-type: disc;
        }
        p{
            color: $blue-light;
            font-size: 18px;
            line-height: 1.7em;
            margin-bottom: 25px;
            @include breakpoint(736px down){
                font-size: 14px;
                line-height: 1.5em;
            }
        }
        h2{
            color: $blue-light;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 30px;
        }
        h3{
            font-size: 18px;
            color: $blue-light;
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: 30px;
            b{
                font-weight: 700;
            }
        }
        h5{
            clear: both;
            display: block;
            color: $blue-light;
            margin: 0px;
            margin-bottom: 5px;
            font-weight: normal;
            font-size: 16px !important;
        }

        .files {
            .files{
                display: block;
                @include clearfix;
                .title{
                    display: none;
                }
            }
            .file a{
                i{
                    display: none;
                }
                content: "Pobierz";
                padding: 0 16px;
                display: inline-block;
                text-transform: uppercase;
                color: $blue-light;
                font-size: 14px;
                height: 54px;
                line-height: 54px;
                box-sizing: border-box;
                padding-top: 1px;
                text-decoration: none;
                background-color: $red-color-site;
                -moz-transition: background-color 0.25s ease-in-out;
                -o-transition: background-color 0.25s ease-in-out;
                -webkit-transition: background-color 0.25s ease-in-out;
                transition: background-color 0.25s ease-in-out;
                margin-top: 5px;
                font-weight: normal;
                &::after{
                    content: "\f105";
                    display: inline-block;
                    font-family: 'fontawesome';
                    font-size: 30px;
                    line-height: 51px;
                    float: right;
                    margin-left: 19px;
                }
                &:hover{
                    background-color: $btn-color;
                }
            }
        }
    }
}
