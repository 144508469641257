
#offer-downloads{

    #subpage-main-paralax .paralax-content h1{
        border-bottom: none;
        font-size: 42px;
        line-height: 1.25em;
        margin-bottom: 0;
        padding: 0;
        text-transform: uppercase;
    }

    #budownictwo{

        .widget-offer-downloads{

            .container-downloads-image{
                // background-size: 100%;
            }
        }
    }
}
