.login-widget{
    display: inline-block;
    vertical-align: middle;
    form{
        overflow: visible;
    }
    .dropdown{
        margin: 0px;
        .user{
            padding: rem-calc(0px 4px);
            width: rem-calc(32px);
            box-sizing: border-box;
            text-align: left;
            font-size: rem-calc(22px);
            color: $navy-blue;
            position:relative;
            cursor: pointer;
            padding: rem-calc(0px 6px);
            width: rem-calc(37px);
            border:1px solid $white;
            border-bottom:0px;
            background: $white;
            z-index: 2;
            &:before{
                content: '';
                @include triangle($navy-blue,4px,3px,'bottom');
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
            }
        }
        > .is-active{
            .user{
                border-color:$border-default-color;
            }
        }
        .submenu{
            border: 1px solid $border-default-color;
            top: calc(100% - 1px)!important;
            margin: 0;
            padding: rem-calc(20px 0 0);
            min-width: rem-calc(275px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            .welcome{
                font-size: rem-calc(16px);
                text-align: center;
                margin-bottom: rem-calc(25px);
                .user-image{
                    text-align: center;
                    margin-bottom: rem-calc(15px);
                    img{
                        border-radius: rem-calc(75px);
                    }
                }
            }
            .is-submenu-item:not(.welcome){
                border-top: 1px solid $border-default-color;
                button,a{
                    display: block;
                    width: 100%;
                    background: transparent;
                    color: $navy-blue;
                    height: rem-calc(40px);
                    line-height: rem-calc(40px);
                    margin: 0;
                    padding: 0;
                    background-color: $white;
                    @include duration(0.2s);
                    text-align: center;
                    &:before{
                        display: none;
                    }
                    &:hover{
                        background-color: $smoke!important;
                    }
                }

            }
        }
    }
    .not-logged{
        a{
            height: rem-calc(30px);
            line-height: rem-calc(30px);
            margin: 0;
        }
    }
}

.login-modal {
    padding: 0;
    border: 0;
    .close-button{
        position: absolute;
        top: 0;
        right: 0;
        color: $white;
        background: $blue-primary;
        font-size: rem-calc(33px);
        padding: rem-calc(0 14px);
    }
    form {
        > .row {
            .message{
                .login-messages{
                    display: block;
                    &.error{
                        color: $red;
                    }
                    &.success{
                        color:$green;
                        display: inline-block;
                        vertical-align: middle;
                        max-width: 207px;
                    }
                    &:last-of-type{
                        margin-bottom: 20px;
                    }
                }
                .edito-overlay{
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 20px;
                    margin-left: 14px;
                }
            }
            .registered-users{
                float: none;
                display: table-cell;
                padding: rem-calc(20px 70px 20px 40px);
                .button{
                    background: $blue-dark;
                    &:before{
                        border: 2px solid $blue-dark;
                    }
                    &:hover,&:focus{
                        color: $blue-dark;
                    }
                }
                .input-section{
                    position: relative;
                    margin-bottom: rem-calc(20px);
                    span{
                        float: left;
                        position: absolute;
                        left: rem-calc(18px);
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: rem-calc(24px);
                        color: $gray;
                    }
                    @include placeholder-color();
                    @include autofill-color($gray-light);

                    input{
                        display: inline-block;
                        margin: 0;
                        padding-left: rem-calc(53px);
                        height: rem-calc(50px);
                        line-height: rem-calc(50px);
                        border: 0;
                        box-shadow: none;
                        background: $gray-light;
                    }
                }
                .welcome{
                    margin: rem-calc(0 0 30px);
                }
                .si-label{
                    margin: rem-calc(0 0 20px);
                }
                .forgot{
                    text-align: right;
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                    a{
                        color:$blue-dark;
                        text-decoration: underline;
                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
                .login-btn{
                    float: none;
                    display: inline-block;
                    margin-right: rem-calc(-5px);
                    vertical-align: middle;
                    button{
                        margin: 0;
                    }
                }
                .social-sing{
                    margin: rem-calc(15px -70px -20px -40px);
                    background: $gray-light;
                    overflow: hidden;
                    .social{
                        padding: rem-calc(20px);
                        float: left;
                        display: block;
                        width: rem-calc(60px);
                        color:$white;
                        text-align: center;
                        &.facebook{
                            background-color: $facebook-color;
                        }
                        &.google{
                            background-color: $google-color;
                        }
                        &.twitter{
                            background-color: $twitter-color;
                        }
                        &.linkedin{
                            background-color: $linkedin-color;
                        }
                    }
                }
                @media #{breakpoint(1400px down)}{
                    padding: rem-calc(20px 35px 20px 30px);
                    .social-sing{
                        margin: rem-calc(15px -35px -20px -30px);
                    }
                }
                @media #{breakpoint(1170px down)}{
                    display:block;
                    width:100%;
                }
                @media #{breakpoint(640px down)}{
                    padding: rem-calc(20px);
                    .forgot{
                        padding: 0;
                        padding-right: rem-calc(10px);
                    }
                    .social-sing{
                        margin: rem-calc(15px -20px -20px -20px);
                    }
                }
            }
            .new-users{
                float: none!important;
                display: table-cell;
                background: $blue-dark;
                color: $white;
                text-align: center;
                padding: rem-calc(20px);
                .welcome{
                    margin: rem-calc(20px 0 40px);
                }
                p{
                    font-size: rem-calc(19px);
                    margin-bottom: rem-calc(40px);
                }
                @media #{breakpoint(1170px down)}{
                    display:block;
                    width:100%;
                }
            }
            margin: 0 !important;
            padding: 0;
            border: 0;
        }
    }
}
