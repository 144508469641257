.widget {
    .banners {
        .banner {
            margin-bottom: rem-calc(10);
            padding: rem-calc(6);
            text-align: center;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.section-6 {
    .widget {

        .banners {
            margin: 0;
            padding: 0;

            .banner {
                margin: 0;
                padding: 0;
                display: none;

                &:first-child {
                    display: block;
                }

                .th {
                    border: 0;
                    padding: 0;
                    margin: 0;
                    width: 100%;

                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
.section-8{

}
