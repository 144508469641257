.widget-images{
  float: left;
  &.left{
    @include breakpoint(736px down){
        text-align: center !important;
    }
  }
}
.widget-images-in{
  width: 80%;
  display: inline-block;
  @include breakpoint(736px down){
    width: 100%;
  }
}
.image {
    margin: rem-calc(4);
    overflow: hidden;
    display: inline-block;
    position:relative;
  /*   &:before{
        content: '';
        background-color: $black;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top:-30%;
        left: 0px;
        z-index: 2;
        @include duration();
    } */
    &:not(.no-zoom){
        &:after{
            content: '\e909';
            @extend .edito-icon;
            font-size: 50px;
            position: absolute;
            @include center();
            top:40%;
            opacity: 0;
            @include duration(0.6s);
            color:$white;
            z-index: 3;
        }
    }
    &:hover,&:focus{
        &:before{
            opacity: 0.7;
            top: 0px;
        }
        &:after{
            top:50%;
            opacity: 1;
        }
        outline: 0px;
    }
}

.images {
    &.center {
        text-align: center;
    }

    &.left {
        .image{
            float: left;
            margin-right: rem-calc(20);
            margin-left: 0px;
            @include breakpoint(736px down){
                float:none;
                clear: both;
            }
        }
    }

    &.right {
        .image{
            float: left;
            margin-left: rem-calc(20);
            margin-right: 0px;
            @include breakpoint(736px down){
                float:none;
                clear: both;
            }
        }
    }

    &.large {
        width: 100%;
        margin: 0;
        text-align: center;

        .image {
            clear: both;
        }
    }

    &.vertical {
        &.center{
            .image{
                display: table;
                margin: 0 auto;
                margin-bottom: rem-calc(10);
            }
        }
        .image {
            display: inline-block;
            clear: both;
            margin-bottom: rem-calc(10);
            .th {
                width: 100%;
            }
        }

        &.small {
            margin-bottom: rem-calc(10);
        }
    }

    &.horizontal {
        text-align:center;
        .image {
            display: inline-block;
        }
    }
    .widget & {
        margin: 0 -1px;
        &.grid{
            overflow:hidden;
            margin: 0 -1px 20px;
            .image{
                float:left;
                width: 243px;
                height: 200px;
                img{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include transform(translateY(-50%));
                    z-index: 0;
                }
                &:first-of-type{
                    width: 408px;
                    height: 408px;
                    img{

                    }
                }
                &:nth-child(6){
                    width: 495px;
                }
                &:nth-child(8){
                    width: 409px;
                }
            }
            @include breakpoint(1200px down) {
                width: 1046px;
                margin: 0 auto;
                .image{
                    width: 200px;
                    height: 198px;
                    &:first-of-type{
                        height: 404px;
                    }
                    &:nth-child(6){
                        width: 408px;
                    }
                }

            }
            @include breakpoint(1070px down) {
                width: 835px;
                .image{
                    &:nth-child(4){
                        width: 408px;
                    }
                }
            }
            @include breakpoint(860px down) {
                width: 627px;
                .image{
                    &:nth-child(4),&:nth-child(8){
                        width: 200px;
                    }
                    &:nth-child(5){
                        width: 408px;
                    }
                }
            }
            @include breakpoint(627px down) {
                width: 100%;
                max-width: 495px;
                .image{
                    width: calc(50% - 3px);
                    height: auto;
                    display: inline-block;
                    float: none;
                    margin: 0;
                    img{
                        position: static;
                        transform: initial;
                    }
                    &:first-of-type{
                        width:100%;
                        height: auto;
                    }
                    &:nth-child(4),&:nth-child(8),&:nth-child(5){
                        width: calc(50% - 3px);
                    }
                    &:nth-child(6){
                        width:100%;
                        max-height: 200px;
                        img{
                            position: relative;
                            transform: translateY(-30%);
                        }
                    }
                }
            }
        }
    }
}
