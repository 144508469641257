
.widget-contact-modal-form{
    .paralax-content{
        h1{
            margin-bottom: 150px;
        }
        .content-text{
            text-align: center;
        }
        ul{
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            li{
                float: none;
                text-align: center;
                a{
                    background-color: $red-color-site;
                    color: $white;
                    display: inline-block;
                    font-size:14px;
                    height: 54px;
                    line-height: 54px;
                    padding: 1px 40px 0 16px;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: background-color 0.25s ease-in-out 0s;
                    // min-width: 400px;
                    position: relative;
                    height: 55px;
                    overflow: hidden;
                    word-wrap: break-word;
                    @include breakpoint(small down){
                        min-width: 100%;
                        max-width: 100%;
                        font-size: 10px;            
                    };
                    &::after{
                        content: "\f105";
                        display: inline-block;
                        float: right;
                        font-family: "fontawesome";
                        font-size: 30px;
                        line-height: 51px;
                        margin-left: 31px;
                        position: absolute;
                        right: 15px;
                        top: 2px;
                    }
                    &:hover{
                        background-color: $btn-hover-color;
                    }
                }
            }
        }
        .modal{
            text-align: center;
            margin-top: 30px;
            a.modal-button{
                background-color: $red-color-site;
                color: $white;
                display: inline-block;
                font-size: 14px;
                height: 54px;
                line-height: 54px;
                padding: 1px 40px 0 16px;
                text-decoration: none;
                text-transform: uppercase;
                transition: background-color 0.25s ease-in-out 0s;
                // min-width: 400px;
                position: relative;
                height: 55px;
                overflow: hidden;
                word-wrap: break-word;
                @include breakpoint(small down){
                    min-width: 100%;
                    max-width: 100%;
                    font-size: 10px;
                };
                &::after{
                    content: "\f105";
                    display: inline-block;
                    float: right;
                    font-family: "fontawesome";
                    font-size: 30px;
                    line-height: 51px;
                    margin-left: 31px;
                    position: absolute;
                    right: 15px;
                    top: 2px;
                }
                &:hover{
                    background-color: $btn-hover-color;
                }
            }
        }
    }
}

.reveal-overlay{

    .reveal.modal-contact{
        top:70px !important;
        margin-bottom: 70px;

        // .columns{
        //     width: 58.3333333333%;
        //     float:left;
        // }
        .reveal-header {
            margin-bottom: 30px;
        }
        .row-modal{
            // margin-left: -15px;
            // margin-right: -15px;
            color: $white;
            .columns:nth-child(2){
                &::before{
                    border-left: 1px solid $input-dark-color;
                    content: "";
                    display: inline-block;
                    float: left;
                    height: 770px;
                    left: -77px;
                    position: relative;
                    width: 10px;
                    @include breakpoint(1480px down){
                        left: -50px;
                    }
                    @include breakpoint(1366px down){
                        left: -15px;
                    }
                    @include breakpoint(1024px down){
                        left: 0px;
                        top:0px;
                        border-left:none;
                        border-top: 1px solid $input-dark-color;
                        height: 1px;
                        width: 100%;
                        margin-top:20px;
                        margin-bottom:30px;
                    }
                }
            }
            h1{
              font-size:28px;
            }
            h2{
                font-size: 18px;
                font-weight: 700;
                line-height: 28px;
                margin: 0;
            }
            .edito-column div *{
                font-size: 22px;
                font-weight: 500;
                line-height: 28px;
            }
            h3{
                font-size: 18px;
                font-weight: 500;
                margin: 20px 0 0;
                padding: 0;
            }
            p{
                color: $white;
                font-size: 16px;
                font-style: normal;
                line-height: 20px;
                margin: 0;
            }
            .modal-form-content{

                .header{
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 28px;
                    margin-top: 0;
                    margin-bottom: 10px;
                }
                .description{
                    color: $white;
                    font-size: 16px;
                    font-style: normal;
                    line-height: 20px;
                    margin: 0;
                }
               .column{        
                margin-bottom: 0;
                }
                label{
                    margin: 0;
                    @include breakpoint(479px down){
                        margin: 5px 0 0 0;
                    }
                }
  
                form{
                    margin-top: 40px;
                    .form-text,.form-textarea, .form-email{
                        input, textarea{
                            color: black;
                            background: $input-dark-bg;
                            border: none;
                            box-shadow: none;
                            padding: 15px;
                            color: $input-dark-color;
                            font-size: 13px;
                        }
                        input{
                            line-height: 40px;
                            padding: 0 15px;
                            @include breakpoint(480px down){
                              // padding: 0;
                              margin-bottom: 0;
                            }
                        }
                        small.error{
                            color: $red-color-site;
                            position: relative;
                            top: -15px;
                            display: inline-block;
                        }
                        label{
                            text-transform: uppercase;
                            font-weight: 700;
                            padding: 0;
                            font-size: 13px;
                            @include breakpoint(480px down){
                              margin-bottom: 0;
                            }
                            var{
                                display: none;
                            }
                        }
                        @include breakpoint(480 up){

                            label{
                                width: 30%;
                                float: left;
                            }
                            label + div{
                                width: 70%;
                                float: left;
                            }
                        }
                        @include breakpoint(1200px down){

                            font-size: 12px;
                        }
                    }
                    .form-checkboxes{
                        label{
                            margin-left: 10px;
                            color: $input-dark-color;
                            font-size: 12px;
                            font-weight: 500;
                            margin: 0;
                            margin-bottom: 10px;
                            span{
                                border: 3px solid black;
                                height: 15px;
                                outline: 2px solid #2b3237;
                                width: 15px;
                                background: black;
                                &::after, &::before{
                                    display: none;
                                }
                            }
                            input:checked + span{
                                background: $red-color-site;
                            }
                        }
                    }
                    .button[type="submit"]{
                        float: right;
                        background-color: $red-color-site;
                        color: $white;
                        display: inline-block;
                        font-size: 14px;
                        height: 54px;
                        line-height: 54px;
                        padding: 1px 16px 0;
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: background-color 0.25s ease-in-out 0s;
                        @include breakpoint(small down){
                            min-width: 100%;
                            height: auto;
                        };
                        &::after{
                            content: "\f105";
                            display: inline-block;
                            float: right;
                            font-family: "fontawesome";
                            font-size: 30px;
                            line-height: 51px;
                            margin-left: 31px;
                        }
                        &:hover{
                            background-color: $btn-hover-color;
                        }
                    }
                }
            }

        }
    }
}
