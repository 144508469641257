html[lang="de"]{
    .customers-slider-wrapper{
        .slider-title{
            width:135px;
        }
    }
}
.customers-slider-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    .customers-slider{
        .item{
            text-align: center;
            float:left;
            img{
                height: 48px;
                margin: 0 auto;
            }
        }
    }
    .slider-title{
        float: left;
        width: 120px;
        @include breakpoint(1024px down){
            padding-left: 35px;
        }
        @include breakpoint(736px down){
            width: 100%;
            text-align: center;
            float: none;
            margin-bottom: 10px;
            padding-left: 0px;
        }
        h5{
            color: $white;
            font-weight: normal;
            position: relative;
            top: 15px;
            font-size: 16px;
            @include breakpoint(736px down){
                top: 5px;
              }
        }
    }
}
