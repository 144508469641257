.gallery-module {
    .gallery {
        .title {
            font-size: rem-calc(18);
            line-height: rem-calc(22);
        }

        p {
            margin: rem-calc(0 0 10);
        }

        .description {
            .name {
                display: block;
                font-size: rem-calc(15);
                line-height: rem-calc(18);
                margin: rem-calc(0 0 10);
            }

            p {
                margin: 0;
            }
        }
    }
}

.gallery-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .gallery {
        @include border(1, 'bottom');
        margin: rem-calc(0 0 20);
        padding: rem-calc(0 0 20);
        overflow: hidden;

        .image {
            float: left;
            overflow: hidden;
            margin: rem-calc(0 20 0 0);
        }

        .title {
            font-size: rem-calc(15);
            line-height: rem-calc(20);
        }

        p {
            margin: 0;
            padding: 0;
        }

        .read-more {
            margin: rem-calc(15 0 0);
        }

        &:last-child {
            border: 0;
            padding: 0;
            margin: 0;
        }

        &:nth-child(even){
            .image{
                float: right;
                margin: rem-calc(0 0 0 20);
            }
        }
    }
    @media #{breakpoint(small down)}{
        margin: rem-calc(0 10px);
        .gallery {
            .image {
                float: none!important;
                margin: 0 auto!important;
                display: block;
                text-align: center;
            }

            .title {
                clear: both;
            }
        }
    }
}