.forum-module{
    @import "forum/category";
    .lead{
        margin-bottom: 0px;
    }
    .accordion-title:focus{
        outline: none;
    }
    ul.pagination{
        text-align: inherit;
        margin: 0;
        li{
            display: inline-block;
            vertical-align: middle;
            span, a{
                font-size: 14px;
                vertical-align: middle;
                cursor: pointer;
            }
            &.active{
                position: relative;
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    width: 100%;
                    height: 1px;
                    background-color: $gray;
                    left: 50%;
                    @include transform(translateX(-50%));
                }
            }
            &:first-of-type, &:last-of-type{
                span, a{
                    font-size: 0px;
                    line-height: 0px;
                    &:before{
                        content: '\e902';
                        @extend .edito-icon;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
            &:last-of-type{
                span, a{
                    &:before{
                        content: '\e901';
                    }
                }
            }
        }
    }
    tr,th{
        > label{
            margin: 0px;
        }
        &.active{
            background-color: $gray-light;
        }
    }
    .hidden{
        display: none;
    }
    .author{
      font-size: 14px;
    }
    .post-footer{
        font-size: 13px;
    }
}
