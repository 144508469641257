
.widget-offer-downloads{


    @include clearfix;

    .fixed-row{
        margin-left: auto;
        margin-right: auto;
        max-width: 65rem;
    }

    .container-downloads-image{
        padding-top: 40px;
        padding-bottom: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        background-attachment: fixed;
    }

    .downloads-svg-icon {
        text-align: center;
        svg {
            height: 116px;
            width: 116px;
            @include breakpoint(992px down){
                height: 42px;
                width: 42px;
            }
        }
    }

    .downloads-content{
        margin-top: -30px;
        @include breakpoint(992px down){
            margin-top: 20px;
        }
        @include breakpoint(small down){
            padding-left: 30px;
        }
    }


    .download-item{
        &.hidden{
            display: none;
        }
        a{
            transition: all 0.3s ease;
            font-size: 18px;
            line-height: 32px;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 3px;
            svg{
                fill: $grayblack;
                height: 30px;
                width: 30px;
                margin-right: 10px;
                position: relative;
                top: 8px;
                transition: all 0.3s ease;
            }
            &.show-hidden-downloads{
                svg{
                    transform: rotate(-90deg);
                }
                &:hover{
                    svg{
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    .downloads-offer-title{
        h3{
            font-weight: 700;
        }
    }


    &.background-dark{
        background: $lightblack;

        .container-downloads-image{
            background-size: cover;
        }

        .downloads-offer-title{
            h3{
                color: $white;
            }
        }

        .downloads-svg-icon svg{
            fill: $white !important;
        }

        .download-item a{
            color: $white;
            svg{
                fill: $white;
            }
            &:hover{
                color: $red-color-site;
                svg{
                    fill: $red-color-site;
                }
            }
        }
    }

    &.background-light{

        background: $white;

        .downloads-offer-title{
            h3{
                color: $red-color-site;;
            }
        }
        .downloads-svg-icon{
           fill: $red-color-site !important;
         }
        .download-item a{
            color: $grayblack;
            &:hover{
                color: $red-color-site;
                svg{
                    fill: $red-color-site;
                }
            }
        }
    }
}
