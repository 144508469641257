#offer{

    #subpage-main-paralax{
        position: relative;
        .paralax-content{
            padding-bottom: 30px;
            width: 92%;
            margin: 200px auto 0 auto;
            @include breakpoint(736px down){
                margin: 100px auto 0 auto;
            }
        }
        .widget h3.heading{
            display: none;
        }
        .paralax-content-columns{
            padding-bottom: 100px;
            @include breakpoint(736px down){
                padding-bottom: 20px;
            }
        }

    }
    .row{
        max-width:100%;
        min-width:100%;
        @include breakpoint(1024px down){
            overflow: hidden;
        }
    }
    .background-dark{
        position:relative;
    }
    .red-svg .cls-1,
    .red-svg .cls-2, .cls-412 {
        fill: $red-color-site !important;
    }

    .heading{
        margin: 0;
        padding: 0;
        background: white;
        background-color: white;
    }
    .top-big-text {
        line-height: 1.29em;
    }
    .widget-menu-icon{
        clear: both;
        // position: absolute;
        // bottom:90px;
        width: 100%;
        @include breakpoint(736px down){
            // bottom:0px;
        }
        li{
            float:none;
            margin-right: 44px !important;
            &:last-of-type{
                margin-right: 0 !important;
            }
            @include breakpoint(736px down){
                width: 75px;
                margin-right: 15px !important;
                margin-bottom: 20px !important;
            }
        }
        a:hover svg .svg_circle {
            fill: rgba(191, 24, 24, 0.8) !important;
        }
        a{
            -moz-transition: color 0.25s ease-in-out !important;
            -o-transition: color 0.25s ease-in-out  !important;
            -webkit-transition: color 0.25s ease-in-out  !important;
            transition: color 0.25s ease-in-out  !important;
            color: $blue-light;
            font-size: 14px;
            text-decoration: none !important;
            display: block;
        }
        .widget-menu-icon-svg{
            svg{
                width:75px;
            }
            .red-svg .cls-1,
            .red-svg .cls-2, .cls-412 {
                fill: $white !important;
                @include breakpoint(1024px down){
                    fill: $red-color-site !important;
                }
            }

        }
        .widget-menu-icon-text{
            margin-top:20px;
            @include breakpoint(736px down){
                font-size:12px;
                margin-top: 0;
            }
        }
        .svg-fillred{
            -moz-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            -o-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            -webkit-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            fill: $blue-light;
        }

        .svg_circle{
            fill:transparent !important;
            -moz-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            -o-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            -webkit-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
            stroke: white;
            stroke-width: 2px;
        }

        .svg-fillwhite{
            fill:$white;
        }
    }
    .offer-link-download{        
        max-width: 990px;
        margin: 0 auto;   
        padding-bottom: 30px;
        @include breakpoint(1024px down){
        //   padding-left: 0;
        //   position: absolute;
        //   width: 92%;
        //   bottom:40px;
        //   left: 0;
        //   right: 0;
        }
        a{
            background-color: $red-color-site;
            padding: 15px;
            color:$white;
            text-decoration: none;
            text-transform: uppercase;
            font-size:14px;
            display: table;
            margin-bottom: 30px;
            transition: background-color 0.25s ease-in-out;
            float: left;
            margin-right: 30px;
            // margin-left:10px;
          
            @include breakpoint(1024px down){
                width: 100%;
                float: left;
                margin-left: 0;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 50px;
                }
            }
            // @include breakpoint(736px down){
            //     margin-left:0px;
            // }
            &:hover{
                  background-color: #E84747;
            }
        }

        .link-arrow{
            border: solid $white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            margin-left:10px;
            @include breakpoint(1024px down){
                float: right;
                margin-top:5px;
            }
        }
    }
    .offer-shadow{
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 23%, transparent 24%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.00) 23%, transparent 24%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 170px;
    }
    button.arrow:before{
        content:'';
        background-image:url('/storage/image/core_files/2017/3/28/101d2f2f7e27d7f7ec96ed94c1985886/arrow-right2.png');
        background-size: cover;
        background-position: center;
        display: block;
        margin: 0 auto;
        margin-left: 30px;
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        -moz-transition: transform 0.5s ease-in-out;
        -o-transition: transform 0.5s ease-in-out;
        -webkit-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        width: 35px;
        height: 90px;
        position: absolute;
        top: 42%;
        @include breakpoint(1024px down){
            transform: rotate(270deg);
            top: 12px;
            left: 0;
            right: 0;
            height: 40px;
            margin-left: auto;
        }
    }
    >div:first-child{
        ul{
            margin: 0 auto;
            text-align: center;
            float: none;
            display: table;
        }
        li{
            display: inline-block;
            margin-right: 40px;
            &:first-child{
                padding-top:0px;
            }
            a{
                color: $white;
                font-size: 14px;
                text-decoration: none;
                display: block;
                -moz-transition: color 1s ease-in-out;
                -o-transition: color 1s ease-in-out;
                -webkit-transition: color 1s ease-in-out;
                transition: color 1s ease-in-out;
            }
        }
    }

    #mieszanki{
        .offer-image{
            background-position: left !important;
        }
        strong{
            color:$black
        }
        .alert{
            padding: 90px 25px 25px 25px;
            background-color: #f0f0f0;
            position: relative;
            margin-top: 20px;
            float: right;
            &::before{
                content: '';
                background-image: url('/themes/stomil/images/alert.svg');
                position: absolute;
                width: 48px;
                height: 48px;
                top: 25px;
                bottom: 0;
                margin-bottom: 15px;
                left: 25px;

            }

            p{
                font-weight: 700;
                color: $red-color-site !important;
                line-height: 1.5em !important;
                font-size: 18px !important;
                @include breakpoint(736px down){
                    font-size: 14px !important;
                }
            }

        }
       
        .offer-link-download{
            a{
                color:$white !important;
                &:hover{
                    color:$white !important;
                }
            }
        }
    }
    #motoryzacja{
        .animate{
            opacity:1 !important;
        }
        .fadeInUp{
            animation-name: 'none';
        }
        .offer-image-contact{
            background-position: right !important;
            background-size: inherit !important;
            @include breakpoint(1024px down){
                background-size: cover !important;
            }
        }
    }
    .offer-image{
        position: relative;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        background-color: $white;
        @include breakpoint(1024px down){
            background-size: cover !important;
        }
    }
    .offer-section{
        overflow: hidden;
        >div {
            // @include breakpoint(1024px down){
            //     transform: translate(0, 100%);
            // }
        }
        > div:hover

        {
            .offer-contact{
                -moz-transform: translate(-120px, 0);
                -o-transform: translate(-120px, 0);
                -ms-transform: translate(-120px, 0);
                -webkit-transform: translate(-120px, 0);
                transform: translate(-120px, 0);
                @include breakpoint(1024px down){
                    transform: translate(0, 90%);
                }
            }

        }


        h2{
            font-size: 35px;
            color: $red-color-site !important;
            font-weight: 700;
            -moz-transition: color 0.5s ease-in-out;
            -o-transition: color 0.5s ease-in-out;
            -webkit-transition: color 0.5s ease-in-out;
            transition: color 0.5s ease-in-out;
            margin-bottom: 35px;
            position:absolute;
            z-index:0;
            @include breakpoint(736px down){
              margin-left: 65px;
              margin-top: 28px;
              font-size:21px !important;
            }

        }
        .row{
            .row{
                margin: 0px;
                @include breakpoint(1024px down){
                    padding-bottom: 30px;
                }
            }
        }
        .offer-main-content-svg{
            width:140px;
            float:left;
            fill: $red-color-site !important;
            position:absolute;

            svg{
                width: 116px;
                height: 116px;
                @include breakpoint(736px down){
                    width: 50px;
                    float: left;
                    margin-right: 10px;
                    position: static;
                    margin-top: -14px;
                }
            }

        }
        .offer-columns-row{
            margin-top: 110px;
            margin-bottom: 45px;
            @include clearfix;
            @include breakpoint(736px down){
                margin-top: 95px;
            }
            .column-title{
                font-size: 18px;
                font-weight: 700;
                color: $lightblack;
                padding-right: 30px;
                @include breakpoint(736px down){
                    margin-bottom: 15px;
                }


            }
            a.column-title-link{
                &:hover{
                    text-decoration: underline !important;
                }
            }
        }
        .content{
            .remove_active{
                transform: translate(-120px, 0);
                @include breakpoint(1024px down){
                    transform: translate(0, 0);
                }
            }
            .add_active{
                transform: translate(-98%, 0) !important;
                @include breakpoint(1024px down){
                    transform: translate(0, calc(-100% + 50px)) !important;
                    padding: 0px;
                }

            }
            &.active{
                .offer-main-content-svg{
                    .red-svg .cls-1,
                    .red-svg .cls-2, .cls-412 {
                        fill: $white !important;
                        @include breakpoint(1024px down){
                            fill:$red-color-site !important;
                        }
                    }
                    fill:$white !important;
                    z-index:1;
                    @include breakpoint(1024px down){
                        fill:$red-color-site !important;
                        z-index:auto;
                    }
                }
                h2{
                    color:$white !important;
                    position:absolute;
                    z-index:1;
                    @include breakpoint(1024px down){
                        color:$red-color-site !important;
                        z-index:auto;
                    }
                }

                button.arrow:before{
                    -moz-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                    @include breakpoint(1024px down){
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .offer-main-content{
            width: 92%;
            max-width: 990px;
            margin: 0 auto;
            overflow: hidden;
            padding-top: 45px;

            @include breakpoint(736px down){
                padding-top: 40px;
                padding-left: 0;
                padding-bottom: 30px;
            }
        }

        .offer-main-content-text{
            float: left;
            padding-left: 150px;
            width: 100%;
            @include breakpoint(736px down){
                padding-left: 0px;
                p{
                    strong{
                        font-size:16px !important;
                    }
                }
            }
        }

        .offer-contact{
            position:absolute;
            top:0;
            width:100%;
            height:100%;
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
            transition: transform 0.5s ease-in-out;
            left:98%;
            @include breakpoint(1024px down){
                left: 0;
                top: calc(100% - 50px);
                -webkit-transform: translate(0, 0) !important;
                transform: translate(0, 0) !important;
                transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
                height: auto;
            }
            .row{
                width: 92%;
                margin: auto;
                max-width: 990px !important;
                min-width: 990px !important;
                text-align: right;
                position:relative;
                height:100%;
                overflow: hidden;
            }
            .offer-image-contact{
                position: relative;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                height:100%;
                background-color: $red-color-site;
                background-blend-mode: multiply;
                cursor: pointer;
            }
            &.add_active{
              .offer-image-contact{
                cursor: default;
              }
            }
            .offer-contact-text{
                position: absolute;
                right: 0;
                bottom: 25%;
                float:right;
                margin-right: 40px;
                list-style: none;
                text-align: left;
                @include breakpoint(1024px down){
                    bottom: auto;
                    float: left;
                    padding: 30px 0 0 30px;
                    position: relative;
                }

                h2{
                    font-size: 26px !important;
                    color: $blue-light !important;
                    font-weight:300;
                    margin-bottom:0;
                    line-height:26px;
                    position: relative;
                    a, span{
                      font-size: 26px !important;
                      color: $blue-light !important;
                      font-weight:300;
                      margin-bottom:0;
                      line-height:26px;
                      position: relative;
                    }
                    @include breakpoint(1024px down){
                        font-size: 20px !important;
                        position: relative;
                        margin-left: 0;
                    }
                }
                h3{
                    font-size: 35px !important;
                    font-weight: 700 !important;
                    margin-top:38px !important;
                    margin-bottom:38px !important;
                    line-height: 26px !important;
                    position: static;
                    padding-left: 0;
                    clear: both;
                    color: #FFF;
                    a, span{
                      font-size: 35px !important;
                      font-weight: 700 !important;
                      margin-top:38px !important;
                      margin-bottom:38px !important;
                      line-height: 26px !important;
                      position: static;
                      padding-left: 0;
                      clear: both;
                      color: #FFF;
                    }
                    @include breakpoint(1024px down){
                        font-size: 22px !important;
                        margin: 20px 0 !important;
                    }
                }
                h4{
                    font-size: 26px !important;
                    color: #FFF !important;
                    font-weight: 700 !important;
                    margin-bottom: 0px !important;
                    line-height: 26px !important;
                    text-transform: inherit;
                    position: static;
                    padding-left: 0;
                    clear: both;
                    color: #FFF;
                    a, span{
                      font-size: 26px !important;
                      color: #FFF !important;
                      font-weight: 700 !important;
                      margin-bottom: 0px !important;
                      line-height: 26px !important;
                      position: static;
                      padding-left: 0;
                      clear: both;
                      color: #FFF;
                      @include breakpoint(1024px down){
                          font-size: 16px !important;
                      }
                    }
                    @include breakpoint(1024px down){
                        font-size: 16px !important;
                    }
                }
                ul{
                    position: absolute;
                    right: 0;
                    bottom: 25%;
                    float:right;
                    margin-right: 40px;
                    list-style: none;
                    @include breakpoint(1024px down){
                        bottom: auto;
                        float: left;
                        padding: 30px 0 0 30px;
                        position: relative;
                    }
                    li{

                        margin-bottom: 0px !important;
                        line-height: 26px !important;
                        position: static;
                        padding-left: 0;
                        clear: both;
                        color: $blue-light;
                        font-size: 26px !important;
                        @include breakpoint(1024px down){
                            font-size: 20px !important;
                        }
                        a{
                            font-size: 26px !important;
                            color: $blue-light !important;
                            font-weight: 700 !important;
                            @include breakpoint(1024px down){
                                font-size: 16px !important;
                            }
                        }

                        &:before{
                            display:none;
                        }
                    }
                }

            }


        }



        ul{
            margin-left: 0;
            float:left;
        }
        p{
            color: $black !important;
            font-size: rem-calc(22px) !important;
            margin-bottom: 15px !important;
            line-height: 24px !important;
        }

        li.item-separator{
            display: block;
            clear: both;
            color: $lightblack;
            font-weight: 700;
            font-size: 18px !important;
            line-height: 1.5em;
        }

        li.item-gallery{
            display: block;
            font-weight: 300;
            margin-bottom: 15px !important;
            line-height: 1.5em !important;
            font-size: 18px !important;
            color: $lightblack;
            position: relative;
            padding-left: 30px;
            padding-right: 30px;
            clear: both;
            @include breakpoint(736px down){
                line-height: 1.1em !important;
                font-size: 16px !important;
            }

            &:before{
                content: '';
                width: 6px;
                height: 6px;
                display: block;
                background-color: $red-color-site;
                position: absolute;
                left: 0;
                top: 10px;
            }
            a{
                font-size: 18px;
                color: $lightblack;
                @include breakpoint(736px down){
                    font-size: 16px;
                    line-height: 1.3;
                }
            }
        }
        li.item-content{
            svg{
                bottom: 0;
                left: 35px;
                margin-bottom: 15px;
                position: static;
                top: 0;
                width: 48px;
                height:48px;
                .cls-1 {
                    fill: #e52620;
                    stroke: #e30613;
                    stroke-width: 0.57px;
                }
            }
            .item-content-wrap{
                background-color: #f0f0f0;
                padding: 25px;
                color: $red-color-site !important;
                font-size: 18px !important;
                font-weight: 700 !important;
                line-height: 1.5em !important;
                p{
                    color: $red-color-site !important;
                    font-size: 18px !important;
                    font-weight: 700 !important;
                    line-height: 1.5em !important;
                }
            }
        }
    }

}

.offer{
    .modal-cg-product{
        .reveal-content{
            p{
                font-size:16px;
            }   
            table, td, th{
                border: none !important; 
            }      
            table{
                width:100% !important;  
                margin-bottom: 25px;                         
                td{
                    padding-right: 20px;
                   
                    &:last-child{
                        padding-right: 0;
                    }
                    @include breakpoint(768px down){
                        width: 100%;
                        float: left;
                    }
                }                
            }
            .show-biography{
                margin-top:0;
                
                &:after{
                    margin-left:12px;
                }
            }

            h4{
                &.biography-title{
                    padding-right: 10px;
                    word-wrap: break-word;
                }
            }
            #column-left-product{
                float: left;
                width:50%;
                padding-right: 10px;
                @include breakpoint(736px down){                 
                    width:100%;
                }
            }
            #column-right-product{
                float: right;
                width:50%;
                @include breakpoint(736px down){
                    float: left;
                    width:100%;
                }
            }
        }
    }
}
