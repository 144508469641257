@mixin font-face($font, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: $font;
        src: url(font-url($file-name)+'.eot');
        src: url(font-url($file-name)+'.eot?#iefix') format('embedded-opentype'),
        url(font-url($file-name)+'.ttf') format('truetype'),
        url(font-url($file-name)+'.svg##{$font}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

@mixin burger-menu-icon($color: $burger-color, $height: $burger-height, $width: $burger-width, $elem-spacing: $burger-spacing, $complete: false) {
    @if ($complete == true) {
        content: '';
        position: absolute;
        width: $width;
        height: $height;
        top: 50%;
        left: 50%;
        margin-left: -($width / 2);
        margin-top: -(round((2 * $elem-spacing + 3 * $height) / 2));
    }
    background: $color;
    box-shadow: 0 ($elem-spacing + $height) 0 0 $color, 0 (2* ($elem-spacing + $height)) 0 0 $color;
}
@mixin border($width: $border-default-width, $dim: '', $color: $border-default-color, $style: $border-default-style) {
    $attr-name: border;

    $width: rem-calc($width);
    @if $dim != "" {
        $attr-name: border-#{$dim};
    }

    @if $width == 0 or $style == none {
        #{$attr-name}: 0 none;
    } @else {
        #{$attr-name}: $width $style $color;
    }
}

@mixin triangle($color: $triangle-color, $side-width: $triangle-side-width, $height: $triangle-height, $direction: '', $hover: false) {
    @if ($direction == 'top') {
        @if ($hover == false) {
            @include border($height,'bottom', solid, $color);
            @include border($side-width,'left', solid, transparent);
            @include border($side-width,'right', solid, transparent);
        } @else if ($hover == true) {
            border-bottom-color: $color;
        }
    } @else if ($direction == 'right') {
        @if ($hover == false) {
            @include border($height, 'left' ,solid, $color);
            @include border($side-width, 'top', solid, transparent );
            @include border($side-width,'bottom', solid, transparent);
        } @else if ($hover == true) {
            border-left-color: $color;
        }
    } @else if ($direction == 'bottom') {
        @if ($hover == false) {
            @include border($height,'top', solid, $color);
            @include border($side-width, 'left', solid, transparent);
            @include border($side-width, 'right', solid, transparent);
        } @else if ($hover == true) {
            border-top-color: $color;
        }
    } @else if ($direction == 'left') {
        @if ($hover == false) {
            @include border($height,'right', solid, $color);
            @include border($side-width, 'top', solid, transparent);
            @include border($side-width, 'bottom', solid, transparent);
        } @else if ($hover == true) {
            border-right-color: $color;
        }
    }
}
@mixin duration($time:0.3s)
{
    -webkit-transition-duration: $time;
    transition-duration: $time;
}

@mixin background($color, $opacity: 1) {
    $red: red($color);
    $green: green($color);
    $blue: blue($color);
    background-color: rgba($red, $green, $blue, $opacity);
}
@mixin transform($rule){
    -ms-transform: $rule;
    -webkit-transform: $rule;
    transform: $rule;
}

@mixin btn(){
    #side-right &{
        margin: 0 auto;
        height: 40px;
        line-height: 40px;
        padding: 0 22px;
    }
    &.primary{
        // background: $blue-dark;
        background-color: $red-color-site !important;
        &:hover{
            background-color: $white;
            // color: $blue-dark;
        }
    }

    // background: $blue-dark;
    color: $white;
    position:relative;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    padding: rem-calc(0 28);
    height: rem-calc(50);
    display: inline-block;
    line-height: rem-calc(50);
    background: $red-color-site !important;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // border: 2px solid $blue-dark;
        z-index: -1;
        // border-radius: inherit;
        opacity: 0;
        // -webkit-transform: scale3d(0.6, 0.6, 1);
        // transform: scale3d(0.6, 0.6, 1);
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
        -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }
    [class*='fa']{
        margin-left: -10px;
        margin-right: 10px;
        font-size: 18px;
    }
    &:after{
        display: none;
    }
    &:hover,&:focus{
      background-color: $btn-color !important;
      text-decoration: none !important;
      color: $white;
        // background-color: $white!important;
        // color: $blue-dark;
        &:before{
            // -webkit-transform: scale3d(1, 1, 1);
            // transform: scale3d(1, 1, 1);
            opacity: 1;
            z-index: 1;
        }
        outline: 0px;
    }
    &.blue-primary,&[data-color*="00608a"]
    {
        // background: $blue-primary;
        &:before{
            // border: 2px solid $blue-primary;
        }
        &:hover,&:focus{
            // color:$blue-primary;
            &:before{

            }
        }
    }
    &.primary{
        border: 0;
        // background: $blue-primary;
        &:before{
            // border: 2px solid $blue-primary;
        }
        &:hover,&:focus{
            // color:$blue-primary;
            &:before{

            }
        }
    }
}

@mixin center(){
    top:50%;
    left: 50%;
    @include transform(translateY(-50%) translateX(-50%));
}
@mixin placeholder(){
    ::-webkit-input-placeholder{
        @content;
    }
    :-moz-placeholder{
        @content;
    }
    ::-moz-placeholder{
        @content;
    }
    :-ms-input-placeholder{
        @content;
    }
}

@mixin placeholder-color($color: $gray){
    @include placeholder(){
        color: $color;
    }
}

@mixin autofill-color($color){
    input:-webkit-autofill {
        -webkit-box-shadow:0 0 0 50px $color inset;
    }

    input:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px $color inset!important;
    }
}

@mixin default-user($width:200px,$height:200px,$bottom:-15px){
    &.circle{
        border-radius: 90px;
        overflow: hidden;
    }
    background: $smoke;
    position: relative;
    width: $width;
    height: $height;
    border: 1px solid $border-default-color;
    @extend .font-icon;
    &:before{
        content: '\f007';
        display: block;
        font-size: #{$height - 20};
        position: absolute;
        bottom: $bottom;
        left: 50%;
        @include transform(translateX(-50%));
        color: $gray;
    }
    &.medium{
        width: $width/2;
        height: $height/2;
        &:before{
            font-size: #{($height/2) - 20};
            bottom: rem-calc($bottom/2);
        }
    }

}
@mixin message_type($color,$icon)
{
    border-right: 4px solid $color;
    &:before{
        background-color: $color;
    }
    &:after{
        content: $icon;
    }
    .heading{
        color: $color;
    }
    ul{
        li{
            &:before{
                background-color: $color;
            }
        }
    }
}


@mixin section-white-font(){
  color:$white !important;
  h2.heading{
    color:$white !important;
    border-bottom: 2px solid $white;
    border-color:$white !important;
    font-size: 36px !important;
    padding-bottom: 27px !important;
    padding-top: 15px !important;
    margin-bottom: 70px !important;
    font-weight: 400 !important;
    @include breakpoint(736px down){
      margin-bottom: 20px !important;
      font-size: 21px !important;
    }
  }
  h2{
    color:$white !important;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    @include breakpoint(736px down){
      font-size: 18px !important;
    }
  }
  p{
    font-size: 26px;
    margin-bottom: 60px;
    color:white!important;
    @include breakpoint(736px down){
      font-size:18px;
      margin-bottom: 30px;
    }
  }
  &.rd-section{
    color:$white!important;
    table{
      tbody{
        background-color: transparent;
        background: transparent;
      }
      p{
        color:$white!important;
        font-size: 18px;
        @include breakpoint(736px down){
          font-size:16px;
        }
      }
      td{
        padding: 0;
      }
      tr:nth-child(even){
        background-color: transparent;
        background: transparent;
      }
    }
  }
}
@mixin section-black-font(){
  color:$black !important;
  h2.heading{
    color:$black !important;
    border-bottom: 2px solid $rd-h2-color;
    font-size: 36px !important;
    padding-bottom: 27px !important;
    padding-top: 15px !important;
    margin-bottom: 70px !important;
    font-weight: 400 !important;
    @include breakpoint(736px down){
      margin-bottom: 20px !important;
      font-size: 21px !important;
    }
  }
  h2{
    color:$black !important;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    @include breakpoint(736px down){
      font-size: 18px !important;
    }
  }
  p{
    font-size: 26px;
    margin-bottom: 60px;
  }
  &.rd-section{
    color:$lightblack!important;
    table{
      tbody{
        background-color: transparent;
        background: transparent;
      }
      p{
        color:$lightblack!important;
        font-size: 18px;
      }
      td{
        padding: 0;
      }
      tr:nth-child(even){
        background-color: transparent;
        background: transparent;
      }
    }
  }
}
@mixin paralax{
  overflow: hidden;
  width: 100% !important;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include breakpoint(1024px down){
    background-attachment:inherit;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin divcontent{

  .row{
    margin-left: auto;
  }
  .medium-6.column{
    padding-left: 0;
    padding-right: 0 !important;
    p{
      font-size: 18px !important;
      margin-bottom:20px !important;
      line-height: 1.5;
      @include breakpoint(736px down){
        font-size: 16px !important;
      }
    }
  }
  .medium-6.column:nth-child(3n-1){
    padding-left: 30px;
    @include breakpoint(736px down){
      padding-left: 0px;
    }
  }
  section.columns{
  	position: relative;
  }
}
@mixin divcontent-medium-4{
  h2 {
    margin-bottom: 15px;
    margin-top: 0px;
    font-weight: 700;
    font-size: 20px;
    color: $lightblack;
    @include breakpoint(736px down){
      font-size: 18px;
    }
  }
  div.medium-4.edito-column{
    padding: 30px!important;
     &:first-of-type{
       padding-left: 0px !important;
     }
    &:last-child{
      padding-right: 0px !important;
      @include breakpoint(736px down){
        padding-right: 25px !important;
      }
    }
    @include breakpoint(736px down){
      padding: 0 25px 70px 0 !important;
    }
  }

  .edito-column p{
    font-size: 18px !important;
    color: #000 !important;
    margin-bottom: 0 !important;
    line-height: 1.5;
    @include breakpoint(736px down){
      font-size: 16px !important;
    }
  }
  p{
      color: $red-color-site !important;
      font-size: 26px !important;
      margin-bottom: 60px !important;
      @include breakpoint(736px down){
        margin-bottom: 30px !important;
        font-size: 18px !important;
      }
  }
  .medium-4.column{
    padding-left: 0;
    padding-right: 0;
  }
}
