.main-rotator{
  @include paralax();
  height:100vh;
  min-height: 1000px;
  // margin-top: -70px;
  position: relative;
    .rotator-widget{
      max-height: none;
      width:100%;
    }
    .home-slider-wrapper{
      max-width: none;
      .slick-prev, .slick-next{
        display: none !important;
      }
      .slider-title{
        display: none;
      }
      // .slick-slide{
      //   display: none;
      //   &.slick-current{
      //     display: block;
      //   }
      // }
      .slick-track{

      }
      .home-slider {
        background-color: $black;
        .item{
          height:100vh;
          // background-attachment: fixed;
          position: relative;
        }
        .slick-slide{
          // width: 100%;
          position: relative;
        }

        .desc{
          text-align: center;
          width: 92%;
          max-width: 772px;
          position: absolute;
          height: 224px;
          margin: auto;
          padding: 0;
          top: 50%;
          transform: translate(-50%, -47%);
          left: 50%;
          @include breakpoint(736px down){
              padding: 22px 10px;
              top:25%;
              transform: translate(-50%, -25%);
          }
          h3{
              &.title{
                  text-align: center;
                  font-size:42px;
                  color:$white !important;
                  text-transform: uppercase;
                  line-height: 1.3;
              }
          }
          .top-big-text{
              text-transform: uppercase;
              font-size: 42px !important;
              line-height: 1.29em !important;
              margin-top: 29px !important;
              margin-bottom:1rem !important;
              @include breakpoint(736px down){
                  font-size: 20px !important;
                  margin-bottom:0 !important;
              }
          }
          .top-small-text{
              font-size: 32px !important;
              line-height: 0.9em !important;
              margin-top: 0px !important;
              @include breakpoint(736px down){
                font-size: 18px !important;
                line-height: 1.2em !important;
                margin-top: 5px !important;
              }
          }
        }
        .slick-item-paralax{
          overflow: hidden;
          background-color: #fff;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 100vh;
          position: relative;
        }
      }
    }
}
