/**
 * Foundation for Sites by ZURB
 * Version 6.2.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

@import 'settings';
@import '../bower/foundation/scss/foundation';

// Components
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-flex-video;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
// @include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
