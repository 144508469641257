
/*==============================================*/
/*--------------- LIGHTBOX STYLE ---------------*/
/*==============================================*/

img[rel=lightbox]
{
    position: fixed;
    z-index: 9999;

    -ms-touch-action: none;
    touch-action: none;
}
.lightbox {
    z-index: 999999999999999999999;
}
.lightboxOverlay {
    z-index: 999999999999999999999;
}
/* IMAGE LIGHTBOX SELECTOR */

#imagelightbox
{
    cursor: pointer;
    position: fixed;
    z-index: 99999999999999999999;

    -ms-touch-action: none;
    touch-action: none;

    -webkit-box-shadow: 0 0 3.125em rgba( 0, 0, 0, .75 ); /* 50 */
    box-shadow: 0 0 3.125em rgba( 0, 0, 0, .75 ); /* 50 */
}

/* ACTIVITY INDICATION */



/* OVERLAY */

#imagelightbox-overlay
{
    background-color: rgba(60, 60, 60, 0.73);
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* "CLOSE" BUTTON */

#imagelightbox-close
{
    text-align: left;
    position: fixed;
    z-index: 10002;
    top: 2.5em; /* 40 */
    right: 2.5em; /* 40 */

    background-color: #bf1818;
    color: #fff;
    float: right;
    font-size: 24px;
    height: 50px;
    -webkit-transition: background-color 0.25s ease-in-out 0s;
    transition: background-color 0.25s ease-in-out 0s;
    width: 50px;

    -webkit-transition: color .3s ease;
    transition: color .3s ease;
}
#imagelightbox-close:focus, #imagelightbox-close:active{
    outline:0px;
}
#imagelightbox-close:hover,
#imagelightbox-close:focus{	background-color: #bf1818;}
#imagelightbox-close:before,
#imagelightbox-close:after{
    width: 5px;
    background-color: #fff;
    content: '';
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 50%;
    margin-left: -3px;

}
#imagelightbox-close:before{
    -webkit-transform: rotate( 45deg );
    -ms-transform: rotate( 45deg );
    transform: rotate( 45deg );
}
#imagelightbox-close:after{
    -webkit-transform: rotate( -45deg );
    -ms-transform: rotate( -45deg );
    transform: rotate( -45deg );
}

/* CAPTION */

#imagelightbox-caption{
    text-align: center;
    color: $white;
    // background-color: $blue-dark;
    position: fixed;
    z-index: 10001;
    left: 0;
    right: 0;
    bottom: 0;
    // padding: 0.625em; /* 10 */
}

/* NAVIGATION */

#imagelightbox-nav{
    background-color: #444;
    background-color: rgba( 0, 0, 0, .5 );
    border-radius: 20px;
    position: fixed;
    z-index: 10001;
    left: 50%;
    bottom: 3.75em; /* 60 */
    padding: 0.313em; /* 5 */

    -webkit-transform: translateX( -50% );
    -ms-transform: translateX( -50% );
    transform: translateX( -50% );
}
#imagelightbox-nav button{
    width: 1em; /* 20 */
    height: 1em; /* 20 */
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.313em; /* 5 */
}
#imagelightbox-nav button.active{background-color: #1e252b;}

/* ARROWS */

.imagelightbox-arrow{
    width: 3.75em; /* 60 */
    height: 7.5em; /* 120 */
    background-color: #1e252b;
    vertical-align: middle;
    display: none;
    position: fixed;
    z-index: 10001;
    top: 50%;
    margin-top: -3.75em; /* 60 */
    border: 1px;
    @include duration();
}
.imagelightbox-arrow:hover,
.imagelightbox-arrow:focus{
    background-color: #1e252b;
    outline:0px;
}
.imagelightbox-arrow:active{background-color: #1e252b;}
.imagelightbox-arrow-left{left: 2.5em; /* 40 */}
.imagelightbox-arrow-right{right: 2.5em; /* 40 */}
.imagelightbox-arrow:before{
    width: 0;
    height: 0;
    border: 1em solid transparent;
    content: '';
    display: inline-block;
    margin-bottom: -0.125em; /* 2 */
}
.imagelightbox-arrow-left:before{
    border-left: none;
    border-right-color: #bf1818 !important;
    margin-left: -0.313em; /* 5 */
}
.imagelightbox-arrow-right:before{
    border-right: none;
    border-left-color: #bf1818 !important;
    margin-right: -0.313em; /* 5 */
}

#imagelightbox-overlay,
#imagelightbox-close,
#imagelightbox-caption,
#imagelightbox-nav,
.imagelightbox-arrow{
    -webkit-animation: fade-in .25s linear;
    animation: fade-in .25s linear;
}


// ========= LOADER =========//

#imagelightbox-loading {
    width: 74px;
    margin: 60px auto;
    font-size: 0;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    margin: 0;
}
#imagelightbox-loading:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 6px solid #e5e5e5;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#imagelightbox-loading:after {
  position: absolute;
  content: '';
  z-index: -1;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: 6px solid #e5e5e5;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.box, .lt, .rt, .lb, .rb {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 40px;
  height: 20px;
  opacity: 1;
}
.box:before, .lt:before, .rt:before, .lb:before, .rb:before {
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  // border-top: 6px solid $blue-primary;
  // border-right: 6px solid transparent;
  // border-bottom: 6px solid transparent;
  // border-left: 6px solid transparent;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lt {
  margin-right: -6px;
  -webkit-animation: lt 2s linear -2000ms infinite;
  -moz-animation: lt 2s linear -2000ms infinite;
  animation: lt 2s linear -2000ms infinite;
}
.lt:before {
  top: 0;
  left: 0;
  -webkit-animation: not-clockwise 1s linear infinite;
  -moz-animation: not-clockwise 1s linear infinite;
  animation: not-clockwise 1s linear infinite;
}

.rt {
  -webkit-animation: lt 2s linear -1000ms infinite;
  -moz-animation: lt 2s linear -1000ms infinite;
  animation: lt 2s linear -1000ms infinite;
}
.rt:before {
  top: 0;
  right: 0;
  -webkit-animation: clockwise 1s linear infinite;
  -moz-animation: clockwise 1s linear infinite;
  animation: clockwise 1s linear infinite;
}

.lb {
  margin-right: -6px;
  -webkit-animation: lt 2s linear -1500ms infinite;
  -moz-animation: lt 2s linear -1500ms infinite;
  animation: lt 2s linear -1500ms infinite;
}
.lb:before {
  bottom: 0;
  left: 0;
  -webkit-animation: not-clockwise 1s linear infinite;
  -moz-animation: not-clockwise 1s linear infinite;
  animation: not-clockwise 1s linear infinite;
}

.rb {
  -webkit-animation: lt 2s linear -500ms infinite;
  -moz-animation: lt 2s linear -500ms infinite;
  animation: lt 2s linear -500ms infinite;
}
.rb:before {
  bottom: 0;
  right: 0;
  -webkit-animation: clockwise 1s linear infinite;
  -moz-animation: clockwise 1s linear infinite;
  animation: clockwise 1s linear infinite;
}

label {
  margin: 1em 0 1em .5em;
  font-size: .8em;
  cursor: pointer;
}

input[type="checkbox"]#spin {
  cursor: pointer;
  margin: 1em 0 1em 1em;
}
input[type="checkbox"]#spin:checked ~ .container {
  -webkit-animation: clockwise 6s linear infinite;
  -moz-animation: clockwise 6s linear infinite;
  animation: clockwise 6s linear infinite;
}


// ====^==== LOADER ====^====//

@media only screen and (max-width: 41.250em) /* 660 */
{
    #container
    {
        width: 100%;
    }
    #imagelightbox-close
    {
        top: 1.25em; /* 20 */
        right: 1.25em; /* 20 */
    }
    #imagelightbox-nav
    {
        bottom: 1.25em; /* 20 */
    }

    .imagelightbox-arrow
    {
        width: 2.5em; /* 40 */
        height: 3.75em; /* 60 */
        margin-top: -2.75em; /* 30 */
    }
    .imagelightbox-arrow-left
    {
        left: 1.25em; /* 20 */
    }
    .imagelightbox-arrow-right
    {
        right: 1.25em; /* 20 */
    }
}
@media only screen and (max-width: 20em) /* 320 */
{
    .imagelightbox-arrow-left
    {
        left: 0;
    }
    .imagelightbox-arrow-right
    {
        right: 0;
    }
}
