.edito-overlay{
    // background-color: rgba(60, 60, 60, 0.73);
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // animation: fade-in .25s linear;
    .edito-load{
        background-image: url('/themes/stomil/images/lightbox/loading.gif');
        width: 90px;
        height:90px;
        margin: auto;
        font-size: 0;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 9999;
        margin: 0;
        // &:before {
        //     position: absolute;
        //     content: '';
        //     top: 0;
        //     left: 0;
        //     width: 40px;
        //     height: 40px;
        //     border: 6px solid #e5e5e5;
        //     border-radius: 100%;
        //     -webkit-box-sizing: border-box;
        //     -moz-box-sizing: border-box;
        //     box-sizing: border-box;
        //   }
        // &:after {
        //     position: absolute;
        //     content: '';
        //     z-index: -1;
        //     top: 0;
        //     right: 0;
        //     width: 40px;
        //     height: 40px;
        //     border: 6px solid #e5e5e5;
        //     border-radius: 100%;
        //     -webkit-box-sizing: border-box;
        //     -moz-box-sizing: border-box;
        //     box-sizing: border-box;
        //   }
          // .box, .lt, .rt, .lb, .rb {
          //   position: relative;
          //   display: inline-block;
          //   overflow: hidden;
          //   width: 40px;
          //   height: 20px;
          //   opacity: 1;
          //   &:before{
          //       position: absolute;
          //       content: '';
          //       width: 40px;
          //       height: 40px;
          //       border-top: 6px solid $blue-primary;
          //       border-right: 6px solid transparent;
          //       border-bottom: 6px solid transparent;
          //       border-left: 6px solid transparent;
          //       border-radius: 100%;
          //       -webkit-box-sizing: border-box;
          //       -moz-box-sizing: border-box;
          //       box-sizing: border-box;
          //       }
          // }
        //   .lt {
        //     margin-right: -6px;
        //     -webkit-animation: lt 2s linear -2000ms infinite;
        //     -moz-animation: lt 2s linear -2000ms infinite;
        //     animation: lt 2s linear -2000ms infinite;
        //     &:before {
        //         top: 0;
        //         left: 0;
        //         -webkit-animation: not-clockwise 1s linear infinite;
        //         -moz-animation: not-clockwise 1s linear infinite;
        //         animation: not-clockwise 1s linear infinite;
        //       }
        //   }
        //   .rt {
        //     -webkit-animation: lt 2s linear -1000ms infinite;
        //     -moz-animation: lt 2s linear -1000ms infinite;
        //     animation: lt 2s linear -1000ms infinite;
        //     &:before {
        //         top: 0;
        //         right: 0;
        //         -webkit-animation: clockwise 1s linear infinite;
        //         -moz-animation: clockwise 1s linear infinite;
        //         animation: clockwise 1s linear infinite;
        //     }
        // }
        // .lb{
        //     margin-right: -6px;
        //     -webkit-animation: lt 2s linear -1500ms infinite;
        //     -moz-animation: lt 2s linear -1500ms infinite;
        //     animation: lt 2s linear -1500ms infinite;
        //     &:before {
        //         bottom: 0;
        //         left: 0;
        //         -webkit-animation: not-clockwise 1s linear infinite;
        //         -moz-animation: not-clockwise 1s linear infinite;
        //         animation: not-clockwise 1s linear infinite;
        //     }
        // }
        // .rb {
        //     -webkit-animation: lt 2s linear -500ms infinite;
        //     -moz-animation: lt 2s linear -500ms infinite;
        //     animation: lt 2s linear -500ms infinite;
        //     &:before {
        //         bottom: 0;
        //         right: 0;
        //         -webkit-animation: clockwise 1s linear infinite;
        //         -moz-animation: clockwise 1s linear infinite;
        //         animation: clockwise 1s linear infinite;
        //     }
        // }

    }
    &.disable{
        position: relative;
        width: 90px;
        height: 90px;
        background: transparent;
        .edito-load{
            top: 0;
            left: 0;
        }
    }
    &.transparent{
        background: transparent;
        z-index: 9999;
    }
    &.inline{
        position: static;
        text-align: center;
        .edito-load{
            position: relative;
            display: inline-block;
            top: 0;
            left: 0;
        }
    }
}
@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(315deg);
  }
}
@-moz-keyframes clockwise {
  0% {
    -moz-transform: rotate(-45deg);
  }
  100% {
    -moz-transform: rotate(315deg);
  }
}
@keyframes clockwise {
  0% {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg);
  }
}
@-webkit-keyframes not-clockwise {
  0% {
    -webkit-transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(-315deg);
  }
}
@-moz-keyframes not-clockwise {
  0% {
    -moz-transform: rotate(45deg);
  }
  100% {
    -moz-transform: rotate(-315deg);
  }
}
@keyframes not-clockwise {
  0% {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(-315deg);
    -moz-transform: rotate(-315deg);
    -ms-transform: rotate(-315deg);
    -o-transform: rotate(-315deg);
    transform: rotate(-315deg);
  }
}
@-webkit-keyframes lt {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes lt {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lt {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
