.alert-box {
    padding: rem-calc(15px 5px 15px 100px);
    border: 1px solid rgba(220, 220, 220, 0.4);
    background-color: #ffffff;
    border-right: 4px solid $red-color-site !important;
    position:relative;
    margin-bottom: rem-calc(15px);
    &:before{
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        top: -1px;
        background-color: $red-color-site;
        width: rem-calc(80px);
        height: calc(100% + 2px);
    }
    &:after{
        @extend .edito-icon;
        content: '\e90b';
        position: absolute;
        color:$white;
        left: rem-calc(20px);
        top: 50%;
        font-size: rem-calc(40px);
        transform: translateY(-50%);
    }
    .heading{
        margin: 0;
        font-weight: bold;
        font-size: rem-calc(20px);
        color: $red-color-site;
    }
    .close{
        position: absolute;
        right: rem-calc(7px);
        top: rem-calc(3px);
        line-height: rem-calc(13px);
    }
    p{
        margin: 0px;
    }
    ul{
        margin: 0px;
        li{
            padding-left: rem-calc(12px);
            position: relative;
            list-style: none;
            &:before{
                content: '';
                width: rem-calc(5px);
                height: rem-calc(5px);
                background: $red-color-site;
                display: block;
                position: absolute;
                left: 0;
                top: rem-calc(9px);
            }
        }
    }
    &.success{
        @include message_type($red-color-site,'\e90c');
    }
    &.alert{
        @include message_type($red-color-site,'\e90e');
    }
    &.warning{
        @include message_type($red-color-site,'\e90d');
    }
}

.reveal-modal {
    border-radius: 0;

    .title {
        font-size: rem-calc(18);
        line-height: rem-calc(22);
        font-weight: normal;
        color: $secondary-color;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0 0 rem-calc(10);
    }
}
