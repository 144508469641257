.surveys-module {
    @media #{breakpoint(small down)}{
        padding: 0 10px;
    }
    .label {
        margin: rem-calc(0 0 3);
    }

    .answers {
        margin: rem-calc(10 0 30);

        .answer {
            padding: rem-calc(0 0 5);

            .title {
            }
        }
    }

    .description {
        margin: rem-calc(0 0 20);
    }

    .form {
        ul {
            margin: rem-calc(20 0 0) !important;

            li {
                @include border(1, 'bottom');
                margin: rem-calc(0 0 10) !important;
                padding: rem-calc(0 10 10) !important;
                overflow: hidden;
                display: block;

                &:last-child {
                    border: 0;
                }

                label {
                    font-size: rem-calc(12);

                    input {
                        margin: rem-calc(2 10 5 0);
                        float: left;
                    }
                }
            }
        }
    }
}

.surveys-list {
    .survey {
        @media #{breakpoint(small down)}{
            padding: rem-calc(0 10px);
        }
        @include border(1, 'bottom');
        margin: rem-calc(0 0 20);
        padding: rem-calc(0 0 20);
        overflow: hidden;

        .question-label {
            float: left;
            font-size: rem-calc(15);
            line-height: rem-calc(20);
            margin-right: rem-calc(3);
        }

        .title {
            font-size: rem-calc(24);
            line-height: rem-calc(28);
            margin: 0px;
            padding: 0px;
            font-weight: bold;
            margin-bottom: rem-calc(12);
            @media #{breakpoint(small down)}{
                font-size: rem-calc(20);
                line-height: rem-calc(24);
            }
        }

        .date {
            padding: rem-calc(0 0 10 0);
            margin: 0;
        }

        &:last-child {
            border: 0;
            margin: 0;
        }

        .bottom_data{
            span{
                display: inline-block;
                border-right: 1px solid $border-default-color;
                padding-right: rem-calc(10px);
                margin-right: rem-calc(5px);
                margin-bottom: rem-calc(10px);
                &:last-child{
                    border: 0px;
                }
            }
        }
    }
}