.users-module{
    .form-content{
            > .row:not(.checkbox) {
                > .columns{
                    margin-bottom: rem-calc(20);
                    label{
                        width: rem-calc(120);
                        margin: 0;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: right;
                        padding-right: rem-calc(10);
                    }
                    label + .row{
                        display: inline-block;
                        width: calc(100% - 95px);
                        vertical-align: middle;
                    }
                    .row{
                        @include autofill-color($white);
                        input[type='text'],
                        input[type='password']{
                            margin-bottom: rem-calc(0);
                        }
                    }
                }
            }
            > .row.checkbox{
                > .columns{
                    margin-bottom: rem-calc(20);
                    label{
                        margin: rem-calc(0 0 0 125);
                    }
                }
            }
            hr{
                margin: 20px auto 40px;
                width: rem-calc(200);
                left: rem-calc(60);
                position: relative;
            }
            .image-upload{
                text-align: center;
                margin: rem-calc(0 0 20 125);
                .selected-image{
                    display: inline-block;
                    text-align: left;
                    width: rem-calc(200);
                    height: rem-calc(200);
                    position: relative;
                    .edit{
                        position: absolute;
                        bottom: rem-calc(11);
                        right: rem-calc(12);
                        background: $blue-primary;
                        color: $white;
                        padding: rem-calc(6 13);
                        font-size: rem-calc(12);
                    }
                }

            }
            .desc{
                font-size: rem-calc(18);
                margin: rem-calc(27px 0px 27px 126px);
            }
            @media #{breakpoint(small down)}{
                .image-upload{
                    margin: rem-calc(0 0 20px 0);
                }
                > .row:not(.checkbox){
                    > .columns{
                        padding-right: 0;
                        label{
                            text-align: left;
                            font-size: rem-calc(18px);
                            width: 100%;
                        }
                        label + .row{
                            width: 100%;
                        }
                    }
                }
                > .row.checkbox{
                    > .columns{
                        margin-bottom: rem-calc(20px);
                        label{
                            margin: 0;
                        }
                    }
                }
                hr{
                    margin: 20px auto;
                    left: rem-calc(0px);
                }
                .desc{
                    margin: rem-calc(27px 0px);
                }
            }
        }
    &.social-manages{
        .plug{
            .social{
                overflow:hidden;
                margin-bottom: rem-calc(10px);
                padding-bottom: rem-calc(10px);
                border-bottom: 1px solid $border-default-color;
                .ico{
                    width: rem-calc(63px);
                    height: rem-calc(50px);
                    display: block;
                    float: left;
                    text-align: center;
                    line-height: rem-calc(50px);
                    color:$white;
                    position: relative;
                    z-index: 2;
                    &.facebook{
                        background-color: $facebook-color;
                    }
                    &.google{
                        background-color: $google-color;
                    }
                    &.twitter{
                        background-color: $twitter-color;
                    }
                    &.linkedin{
                        background-color: $linkedin-color;
                    }
                }
                .connect{
                    float: right;
                    line-height: rem-calc(50px);
                    width: calc(100% - 66px);
                    .animate-pug{
                        float: left;
                        @include transform(rotate(180deg) translateX(9px));
                        display: inline-block;
                        line-height: rem-calc(50px);
                        position: relative;
                        font-size: rem-calc(28px);
                        z-index: 0;
                        color: $blue-dark;
                        @include duration();
                    }
                    a{
                        float: right;
                        &:hover + .animate-pug{
                            @include transform(rotate(180deg) translateX(-9px));
                        }
                    }
                }
            }
            &.un{
                margin-top: rem-calc(20px);
                .social{
                    .connect{
                        .animate-pug{
                            @include transform(rotate(180deg) translateX(-9px));
                        }
                        a{
                            &:hover + .animate-pug{
                                @include transform(rotate(180deg) translateX(9px));
                            }
                        }
                    }
                }
            }
        }
    }
    &.login{
        @extend .login-modal;
        form > .row{
            .registered-users{
                display: block;
                width: 100%;
            }
        }
    }
    @media #{breakpoint(small down)}{
        margin:rem-calc( 0 10px);
    }
}