.job-offer-widget{
    margin-bottom: 100px;
    @include breakpoint(736px down){
      margin-bottom: 20px;
    }
    .job-subtitle{
        font-size: 20px;
        font-weight: normal;
        @include breakpoint(736px down){
          font-size: 14px !important;
        }
    }
    .job-description{
        padding-right: 10px;
        *{
            color: #1e252b;
            display: inline-block;
            font-size: 18px !important;
            font-weight: 300;
            line-height: 1.3em !important;
            @include breakpoint(736px down){
              font-size: 14px !important;
            }
        }
        ul {
            list-style: none;
            li{
                color: $home-color-text;
                position: relative;
                font-size: 18px !important;
                line-height: 1.3em !important;
                padding-left: 20px;
                display: block;
                float: none;
                @include breakpoint(736px down){
                  font-size: 14px !important;
                }
                &::before{
                    content: "";
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    background: $red-color-site;
                    position: absolute;
                    top: 10px;
                    left: -15px;
                }
            }
        }
    }
}
