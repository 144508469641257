.news-module {
  .details_news{
    border-bottom: 1px solid rgba(10, 0, 0, .08);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    height: 22px;
    margin-bottom: 20px;
  }
  .autor_news{
    text-align: right;
    float: left;
    width: 50%;
    font-weight: 100;
    font-size: rem-calc(13);
    color:$news-color-date;
  }

  .date {
    font-size: rem-calc(13);
    margin-bottom: rem-calc(0);
    padding-bottom: rem-calc(7);
    color:$news-color-date;
    font-weight: 100;
    float: left;
    width: 50%;
    text-align: left;
    // padding-top: 10px;
  }
  .autor_news_name{
    font-weight: bold;
    float:right;
    margin-left:3px;
    color:$black !important;
    line-height: 1.4;
  }

    .news {
        float:left;
        .title {
            font-size: rem-calc(18);
            line-height: rem-calc(22);

        }

        .date {
            margin: 0;
            padding: rem-calc(0 0 15);
            font-size: 13px;
            font-weight: 100;
            width: 100% !important;
            color:$news-color-date;
        }

        .content {
            overflow: hidden;
            margin: 0;
            padding: 0;

            p {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    @media #{breakpoint(medium down)}{
        padding: 0 20px;
    }
    @media #{breakpoint(small down)}{
        padding: rem-calc(0 10px);
    }
}
.aktualnosci{
  padding-bottom: 40px;
}

#main-list-news{
  .main-section{
    .row:nth-child(2n){
      width: 990px !important;
      @include breakpoint(1024px down){
        max-width: 990px !important;
        width: 92% !important;
      }
    }
  }
  .columns{
    padding-right: 0;
  }
  .right-off-canvas-menu{
    width: 36% !important;
    margin-left:20px;
    @include breakpoint(1024px down){
      padding: 0;
      width: 100% !important;
      float: left;
    }

    @include breakpoint(736px down){
      margin-left: 0px;
      padding: 0 0.625rem;
    }

    .widget{
      li{
        margin-bottom: 28px !important;
      }
    }
    li.news{
      border-bottom: 1px solid rgba(10, 0, 0, 0.08);
      width: 100%;
      &.current{
        display: none;
        border-bottom: 2px solid #CB1218;
        h3.title{
            a{
              color:#cb1218 !important;
            }
        }
      }
    }
    h3.heading, a.image, a.read-more, p{
      display: none;
    }
    .date{
      &:first-child{
        display: none;
      }
      color:#8a8989;
      font-weight: lighter;
      font-size: 13px;
    }

    h3.title{
      line-height: 22px;
      &.current{
        a{
          color:#cb1218 !important;
        }
      }
      a{
        font-size: 15px;
        text-transform: uppercase;
        color: #bf1818;
        line-height: 15px;
        // letter-spacing: 0.7px;
        &:hover{
          text-decoration:underline;
        }
      }
    }
  }

  .news-module{
    &.news-list{
      .foto-news{
        .image{
           display: block !important;
        }
        img{
          max-width: 100%;
          min-width: none;
          margin-bottom: 0px !important;         
        }
      }
    } 

    .content{
      p{
        margin-bottom: 20px;
      }
    }
    .foto-news{
      text-align: center;
      max-height: 380px;
      height: 380px;
      margin-bottom: 20px;
      overflow: hidden;
      @include breakpoint(560px down){
          max-height: 250px;
          height: 250px;
      }
      img{
        height: 100%;
        max-width: 100%;
        min-width: auto;
        @include breakpoint(1024px down){
          height: auto;
        }
      }
    }
  }
  .all-news-list{
    .row{
      width: inherit !important;
    }
    .large-7{
      width: 100% !important;
    }

    .right-off-canvas-menu{
      display: none;
    }
  }
}

.news-module.news-list {
    margin: 0;
    padding: 0;
    list-style: none;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .news {
        // @include border(1, 'bottom');
        overflow: hidden;
        margin: 0px;
        // padding-bottom: rem-calc(25);
        padding-bottom: 55px;
        margin-bottom: rem-calc(35.8);
        max-width: 990px;
        margin: 0 auto;

        // &:not(:first-of-type){
            .foto-news{
                width: rem-calc(200);
            }
        // }

        .foto-news{
            padding: 0;
            height: auto;
            margin-right: rem-calc(30);
            float: left;
            a.image{
              @include breakpoint(736px down){
                width: 100%;
              }
            }
        }

        .content{
            overflow: hidden;
            padding-left: 15px;
            padding-top:12px;
            @include breakpoint(1024px down){
              padding-top: 0;
            }
        }
        .title {
            // font-weight: bold;
            font-size: rem-calc(20);
            line-height: rem-calc(20);
            padding: 0px;

            a{
                font-size: rem-calc(24);
                line-height: rem-calc(28);
                color: $black;
                margin: 0px;
                padding: 0px;
                &:hover,&:focus{
                    color:$black;
                    text-decoration: none;
                    outline: 0px;
                }
            }
        }

        .date {
            font-size: rem-calc(13);
            margin-bottom: rem-calc(0);
            padding-bottom: rem-calc(7);
            color:$news-color-date;
            font-weight: 100;
            float: left;
            width: 50%;
            text-align: left;
        }

        .desc {
            line-height: rem-calc(22);
            margin-bottom: 0.2875rem;
            font-size: rem-calc(15);;
            font-family: $arial-font;
            padding-right: 55px;
        }
        .read-more-text{
          font-size: rem-calc(12);
          color:#cb1218;
          text-transform: uppercase;
          font-weight: bold;

        }
        // &:first-of-type{
        //     padding-bottom: rem-calc(29);
        //     margin-bottom: rem-calc(59);
        //     .title{
        //         font-size: rem-calc(36);
        //         line-height: rem-calc(47);
        //     }
        // }

            .foto-news{
                float: left;
                margin-right: 0px;
                // margin-left: rem-calc(30);
                margin-left: 0px;
                width: 200px;
                min-height: 1px;
                height: auto !important;
                margin-bottom: 0px !important;
                img{
                  height: auto !important;
                }
            }

    }


    @media #{breakpoint(medium down)}{
        .news{
            // &:first-of-type{
                .foto-news{
                  img{
                      max-height: auto;
                  }
                }
            //     .content{
            //         clear: both;
            //     }
            // }
        }
    }
    @media #{breakpoint(small down)}{
        padding:rem-calc(0 10px);
        .news{
            .title{
                a{
                    font-size: rem-calc(20px);
                    line-height: rem-calc(30px);
                }
            }
            .time{
                font-size: rem-calc(13px);
                margin-bottom: rem-calc(20px);
                margin-top: rem-calc(10px);
            }
            .content{
              .desc{
                padding-right: 0px;
              }
              width: 100%;
            }
            // &:first-of-type{
            //     .title{
            //         margin-bottom: 0px;
            //         a{
            //             font-size: rem-calc(24px);
            //             line-height: rem-calc(37px);
            //         }
            //     }
            // }
            // &:not(:first-of-type){
                .foto-news{
                    width: 100%;
                    margin-right: rem-calc(7px);
                    margin-left: rem-calc(7px);

                }

        }
    }
}
