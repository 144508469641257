
  .slider-double-img{
    margin: auto;
    overflow: hidden;
    width:92%;
    max-width: 990px;
    .owl-stage-outer{
      margin: auto;
    }
    .owl-nav{
      display:block !important;
      font-size: 25px;
      .owl-prev{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        &:after{
          content: "\f104";
          display: inline-block;  
          font-family: "fontawesome";
          font-size: 30px;
        }
      }
      .owl-next{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        &:after{
          content: "\f105";
          display: inline-block;
          font-family: "fontawesome";
          font-size: 30px;
        }
      }
    }
    .owl-item{
        padding-left: 25px;
        padding-right: 25px;
        @include breakpoint(720px down){
          padding-left: 15px;
          padding-right: 15px;
        }
        .item{
          height: auto !important;
          max-height: 576px !important;
        }
        img{
        }
    }
  }
