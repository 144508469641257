:focus {
    // box-shadow: inset 0 0 rem-calc(4) 0 $blue-light !important;
    outline: none;
}

::selection {
    background-color: $blue-dark;
    color: $white;
}

.hide-wcag {
    position: absolute !important;
    height: rem-calc(1);
    width: rem-calc(1);
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

blockquote {
    margin: rem-calc(0 0 20);
    padding: rem-calc(20);
    border-left: rem-calc(5) solid $gray-dark;
    background: transparent;
    color: $gray;
    position: relative;
    text-align: justify;
    font-style: italic;

    p {
        margin: 0;
        padding: 0;
    }
}

table, th, td {
  border-style: solid !important;
}

.row{
  .row{
    margin-left:0;
    margin-right: 0;
  }
}

h1, h2, h3, h4, h5, h6{
    font-family: $main-font;
}
ul{

    li{

    }
}

.mt30{
    margin-top: 30px;
}

.heading {
    font-size: rem-calc(28);
    line-height: rem-calc(28);
    margin: rem-calc(0 0 32);
}

.title{
    border: rem-calc(0);
    margin-bottom: rem-calc(25);
    font-size: rem-calc(24);
    margin: rem-calc(0);
    text-align: left;
    position: relative;
    padding: rem-calc(0 0 15);
    color: $navy-blue;
    line-height: rem-calc(26);
}

.title {
    > a {
        @extend .title;
        padding: 0;

        &:hover {
            color: $primary-color;
        }
    }
}

.history-back {
    margin: rem-calc(40 0 0);
    @media #{$small-only}{
        margin: rem-calc(30 10 0);
    }
    &.no-margin{
        @media #{$small-only}{
            margin: rem-calc(0 10 0)!important;
        }
    }
    a {
        margin: 0;
    }
}

.read-more {
    color: $secondary-color;
    display: inline-block;
    font-weight: normal;
    padding: rem-calc(0 15 0 0);
    position: relative;

    &:after {
        content: "\f138";
        font-family: FontAwesome;
        position: absolute;
        top: 0;
        right: 0;
    }
    &:hover {
        color: $primary-color;
    }
    &.arrow{
        display: inline-block;
        font-size: rem-calc(14);
        display:inline-block;

        &:after{
            @include duration();
            position:relative;
            content: "\e908";
            @extend .edito-icon;
            left:0px;
            color:$navy-blue;
        }
        &:hover,&:focus{
            &:after{
                color:$blue-primary;
                left: 10px;
            }
            outline: 0px;
        }
    }
}

.widget {
    .heading {
        font-size: rem-calc(18);
        margin: rem-calc(0 0 20);
        padding: 0;
        &.bottom-menu-heading{
            line-height: rem-calc(28);
            margin: rem-calc(14 0 10);
            color: $smoke;
            font-size: rem-calc(18);
            font-weight: 600;
        }
    }

    .alert-box {
        margin-bottom: 0;
    }

    > .read-more {
        float: left;
    }
}

.dropdown {
    .title {
        padding: 0;

        a {
            line-height: rem-calc(24);
        }
    }
}

.list {
    margin: rem-calc(0 0 20);
    padding: 0;
    list-style: none;

    li {
        margin: rem-calc(0 0 14px);
        padding: rem-calc(0px 0 9px 13px);
        position: relative;
        @include border(1, 'bottom');

        &:last-child {
            border: 0;
        }

        > * {
            margin: 0;
            padding: 0;
            line-height: rem-calc(18px);
            font-size: rem-calc(14px);
            display: inline-block;
        }

        a {
            font-size: 0.75rem;
            display: inline-block;
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }
        &:before {
            content: " ";
            width: rem-calc(5px);
            height: rem-calc(5px);
            background: $blue-dark;
            position: absolute;
            left: 0px;
            top: rem-calc(6px);
        }
    }
    &.icon{
        li{
            &:before{
                display: none;
            }
        }
        &.horizontal{
            margin-top: rem-calc(25px);
            li{
                display: inline-block;
                border: 0px;
                margin: rem-calc(0 0 15px);
                padding: 0;
                width: rem-calc(146px);
                min-height: rem-calc(122px);
                vertical-align: top;
                text-align: center;
                i{
                    display: block;
                    font-size: rem-calc(60px);
                    line-height: normal;
                    color: $blue-dark;
                    margin-bottom: rem-calc(10px);
                }
                > * {
                    display: block;
                }
                a{
                    display: block;
                    font-size: rem-calc(17px);
                    line-height: rem-calc(20px);
                    font-weight: bold;
                    color:$blue-dark;
                    &:hover{
                        text-decoration: none;
                        color:$blue-primary;
                    }
                }
            }
        }
    }


}

#skip-links {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    z-index: 999;

    li {
        margin: 0;
        padding: 0;

        a {
            @extend .show-for-sr;

            &:focus {
                @extend .show-on-focus;
                box-shadow: none!important;
                float: left;
                padding: rem-calc(20);
                background: $blue-dark;
                color: $white;
            }
        }
    }
}

.tools{
    display: inline-block;
    vertical-align: middle;
    @media #{breakpoint(small down)}{
        display:none;
    }
}

.btn{
    @include btn();
    &.btn-xs{
        height: rem-calc(30px);
        line-height: rem-calc(30px);
        padding: rem-calc(0 10px);
}
}

a.wcag-tooltip.show-on-focus{
    font-size: rem-calc(12);
    font-weight: normal;
    background: $blue-dark;
    color: $white!important;
    outline: 0;
    box-shadow: none!important;
    position: absolute!important;
    z-index: 9;
    &:after{
        content:'';
        @include triangle($blue-dark,9px,9px,'top');
        top: -8px;
        position: absolute;
        left: 50%;
        @include transform(translateX(-50%));
    }
    &:before{
        display: none;
    }
}
.top-bar{
  &.fixedMenu{
    .mobile-menu-button{
      -webkit-transition: margin-top 0.2s ease-in-out;
      transition: margin-top 0.2s ease-in-out;
      margin-top: 20px;
      @media screen and (max-width: 736px){
        margin-top: 17px;
      }
    }
  }
}
.mobile-menu-button{
    float: right;
    display: none;
    position: relative;
    overflow: hidden;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: background 0.3s;
    margin-top:20px;
    -webkit-transition: margin-top 0.2s ease-in-out;
    transition: margin-top 0.2s ease-in-out;
    span{
        display: block;
        position: absolute;
        top: rem-calc(26);
        left: 0;
        right: rem-calc(18);
        height: rem-calc(2);
        background: $white;
        width: 100%;
        transition: background 0s 0.3s;
        height:3px;
        &:after, &:before {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height:3px;
            background-color: $white;
            content: "";
            transition-duration: 0.3s, 0.3s;
            transition-delay: 0.3s, 0s;
        }
        &:before{
            top: -10px;
            // transition-property: top, transform;
        }
        &:after{
            bottom: 20px;
            // transition-property: bottom, transform;
        }
    }
    // &.active{
    //     span{
    //         background: none;
    //         &:before,&:after{
    //             transition-delay: 0s, 0.3s;
    //         }
    //         &:before{
    //             top: 0;
    //             // @include transform( rotate(45deg));
    //         }
    //         &:after{
    //             bottom: 0;
    //             // @include transform( rotate(-45deg));
    //         }
    //     }
    // }
    @media screen and (max-width: 736px){
      margin-top: 17px;
    }
}

.date{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 0.4375rem;
}

button{
    &.answer, &.primary{
        // @extend .btn;
        @extend .fa;
        padding: 15px;
        font-size: 24px;
        font-family: "Roboto", sans-serif !important;
        background-color: #bf1818!important;
        &:hover,&:focus{
            color:$white!important;
        }
        &.yes{
            &:after{
                content: "\f164";
                display: block;
            }
        }
        &.no{
            &:after{
                content: "\f165";
                display: block;
            }
        }

    }
}

// .button{
//     @extend .btn;
//     &.secondary{
//         @extend .btn;
//         &:hover{
//             color: inherit;
//         }
//     }
// }

.bg-darkBlue{
    background-color: $blue-dark;
    @media #{breakpoint(small down)}{
        padding: 0 10px;
    }
}

.default-user{
    @include default-user();
}

.column:last-child:not(:first-child), .columns:last-child:not(:first-child){
    float: left;
}

@media #{breakpoint(medium down)} {
    .mobile-menu-button{
        display:block;
    }
}

.report-menu {
  display: inline-block;

  ul.menu {
      padding: 10px;
  }
}

.image-template{
  img{
    background-color: #1e252b;
    padding: 10px;
  }
}

ul{
  &.inline-list{
    margin-left:0;
    li{
      float: left;
      margin-right: 30px;
      list-style: none;
    }
  }
}
