.social{
    li{
        a{
            &:before{
                @extend .font-icon;
                font-size: rem-calc(18px);
                color: $blue-dark;
                display: inline-block;
                vertical-align: middle;
            }
            &:hover{
                &:before{
                    color: $blue-primary;
                }
            }
            &[href*='facebook'],&[href*='google'],&[href*='twitter'],&[href*='youtube'],&[href*='linkedin'],&[href*='wykop'],&[href*='instagram']{
                font-size: 0px!important;
            }
            &[href*='facebook']{
                &:before{
                    content: '\f09a';
                }
            }
            &[href*='google']{
                &:before{
                    content: '\f0d5';
                }
            }
            &[href*='twitter']{
                &:before{
                    content: '\f099';
                }
            }
            &[href*='youtube']{
                &:before{
                    content: '\f167';
                }
            }
            &[href*='linkedin']{
                &:before{
                    content: '\f0e1';
                }
            }
            &[href*='wykop']{
                &:before{
                    content: '\eafc';
                }
            }
            &[href*='instagram']{
                &:before{
                    content: '\f16d';
                }
            }
        }
    }
}