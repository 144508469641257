@media print {
    header,
    .breadcrumbs,
    .section-2,
    .section-3,
    .section-4,
    .button,
    .pagination-centered,
    footer {
        display: none !important;
    }

    body,
    .row,
    .column,
    .columns,
    .main-content {
        border: 0 !important;
        height: auto !important;
        width: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        float: none !important;
        position: static !important;
        overflow: visible !important;
        background: transparent !important;
        box-shadow: none !important;;
    }

    table {
        width: 100% !important;
    }
}