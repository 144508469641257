.fontsize {
    display: inline-block;
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        float: left;
        margin: 0;
        padding: 0;
        list-style: none;

        a {
            float: left;
            position: relative;
            padding: 0;
            width: rem-calc(16);
            height: rem-calc(25);
            font-size: 0;
            line-height: 0;

            &:before {
                font-family: FontAwesome;
                content: "\f031";
                font-size: rem-calc(10);
                line-height: rem-calc(15);
                color:$blue-dark;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                padding: 0;
            }

            &:hover,
            &:focus,
            &.active {
                &:before {
                    color: $blue-primary;
                }
            }
            &:focus{
                outline: 1px solid $blue-primary;
            }
        }

        &:nth-child(2) {
            a {
                width: rem-calc(20);

                &:before {
                    font-size: rem-calc(16);
                    line-height: rem-calc(21);
                }
            }
        }
        &:nth-child(3) {
            a {
                width: rem-calc(22);

                &:before {
                    font-size: rem-calc(22);
                    line-height: rem-calc(27);
                }
            }
        }
    }
}

@media #{$small-only, $medium-only} {
    .fontsize {
        padding: rem-calc(16 0 0);
    }
}

.fontsize-2 {
    font-size: 115%;
}

.fontsize-3 {
    font-size: 125%;
}