
#investor-relations{

    .alerts{
        .alert-box{
            margin: 0;
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    #subpage-main-paralax .paralax-content {
        margin-top: 205px;
        padding-bottom: 100px;
        @include breakpoint(1024px down){
            margin-top: 130px;
            padding-bottom: 150px;
        }
        @include breakpoint(736px down){
            margin-top: 100px;
            padding-bottom: 130px;
        }
        h1{
            border-bottom: none;
            font-size: 42px;
            line-height: 1.25em;
            margin-bottom: 90px;
            padding: 0;
            text-transform: uppercase;
            @include breakpoint(736px down){
              font-size: 30px !important;
              margin-left: 0 !important;
              padding-left: 0 !important;
              margin-bottom: 55px !important;
            }
        }
        .edito-column{
            @include breakpoint(736px down){
                padding-bottom: 20px;
            }
            img{
                max-width: none !important;
                height: 75px !important;
                width: auto !important;
                margin: 0 auto !important;
            }
        }
        .investor-relations-paralax-logo{
          margin: 0;
          display: table;
          width: 100%;
          overflow: hidden;
          padding: 0 100px;
          @include breakpoint(736px down){
              padding: 0 15px;
          }
          .medium-4{
            width: 40%;
            margin: 0;
            padding: 0;
            img{
              display: block !important;
              margin-left: auto !important;
              margin-right: auto !important;
              float: left !important;
              width: 60% !important;
              height: auto !important;
            }
          }
          .medium-4:last-child(){
            width: 20%;
            margin: 0;
            padding: 0;
            img{
              margin-top: 8% !important;
            }
          }
        }
    }

    .paralax-content-columns{

        @include breakpoint(736px down){
          padding-top: 40px;
          padding-bottom: 40px;
        }
    }


    #investors-contact{
      .widget li{
        list-style: none;
      }
    }
    #presentation{
        .downloads-content{
            padding-left: 0;
            margin-top: 0px;
            h1{
                margin-bottom: 30px;
            }
            .download-item{
                a{
                  margin-left: 1.25rem;
                }
            }
        }
    }
    #investors-events{
        ul{
            float: left;
            margin-left:0;
            li{
            display: block;
            width: 50%;
            float: left;
            font-size: 12px !important;
            padding-left: 50px;
            padding-right: 40px;
            min-height: 80px;
            position: relative;
            @include breakpoint(small down){
                width: 100%;
            };
            strong{
                display: block;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.5;
                @include breakpoint(736px down){
                  font-size: 16px;
                }
            }
            &::before{
                background-color: #bf1818;
                color: #fff;
                content: "\f105";
                font-family: fontawesome;
                font-size: 20px;
                height: 30px;
                left: 0;
                line-height: 30px;
                position: absolute;
                text-align: center;
                top: 5px;
                width: 30px;
            }
        }
      }
    }
    #transaction-notification{
        .paralax-content-columns{
          padding-top: 80px;
          padding-bottom: 60px;
        }
        .paralax-content{
            h1{
                border-bottom: 2px solid $lightblack;
                font-size: 36px;
                margin-bottom: 30px;
                padding-bottom: 15px;
                padding-left: 55px;
                padding-top: 6px;
            }
            p:first-of-type{
              font-size:15px !important;
            }
            ul {
                margin-top: 50px;
                margin-bottom: 50px;
                margin-left: 0px;
                li{
                    float: none;
                    text-align: center;
                    list-style: none;
                    a{
                        background-color: $red-color-site;
                        color: $white;
                        display: inline-block;
                        font-size:14px;
                        height: 54px;
                        line-height: 54px;
                        padding: 1px 40px 0 16px;
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: background-color 0.25s ease-in-out 0s;
                        // min-width: 400px;
                        position: relative;
                        height: 55px;
                        overflow: hidden;
                        word-wrap: break-word;
                        @include breakpoint(small down){
                            min-width: 100%;
                            max-width: 100%;
                            font-size: 10px;                    
                        };
                        &::after{
                            content: "\f105";
                            display: inline-block;
                            float: right;
                            font-family: "fontawesome";
                            font-size: 30px;
                            line-height: 51px;
                            margin-left: 31px;
                            position: absolute;
                            right: 15px;
                            top: 2px;
                        }
                        &:hover{
                            background-color: $btn-hover-color;
                        }
                    }
                }
            }
        }
    }
    .modal-contact{
      .row{
        width: 97%;
      }
    }
}
