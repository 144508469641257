.body {
    font-family: $body-font-family !important;
    font-stretch:normal;
    font-style:normal;
    font-variant-caps:normal;
    font-variant-ligatures:normal;
    font-variant-numeric:normal;    
    &:-webkit-full-screen-ancestor header,  &:-webkit-full-screen-ancestor #home-main-paralax{
        z-index: 0;
        position: relative;
    }
}
header {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 99999;

    .columns{
        width: 92%;
        max-width: 1075px;
        margin: 0 auto;
        float: none;
        padding: 0 !important;
    }

    .top-bar {
        text-align: center;
        .name {
            display:inline-block;
            vertical-align: top;
            float: left;
            .logo {
                float: left;
                overflow: hidden;
                margin-top: 35px;
                -webkit-transition: margin-top 0.2s ease-in-out;
                transition: margin-top 0.2s ease-in-out;
                @include breakpoint(1024px down) {
                    margin-top: 20px;
                }
                @include breakpoint(736px down) {
                    width: 230px;
                    margin-top: 21px;
                }
                img {
                    float: left;
                }
                &:focus{

                }
            }
        }
        .tob-bar-content{
            width: 92%;
            max-width: 1075px;
            margin: 0 auto;
        }
        .tools {
            overflow: hidden;
            float: right;
            margin: rem-calc(21 0);
            padding: rem-calc(10 15);
            @include border(1, 'right');
        }

        .top-bar-section {
            float:right;
            display: inline-block;
            vertical-align: bottom;
            @include breakpoint(1260px down) {
              margin-right: 40px;
            }
            @include breakpoint(1024px down) {
              margin-right: 0px;
            }
            .main-menu{
                margin-bottom: 0px;
            }
        }
    }
    .top-bar{
        -webkit-transition: background-color 0.25s ease-in-out;
        transition: background-color 0.25s ease-in-out;
        &.fixedMenu{
            @include duration();
            transition-property:top;
            -webkit-transition-property:top;
            position: fixed;
            background-color: $lightblack;
            top: 0px;
            z-index: 999;
            min-height: 70px;
            width: 100%;
            left: 0;

            .top-bar-content{
                width: 92%;
                max-width: 1075px;
                margin: 0 auto;
            }
            .logo{
                margin-top: 20px;
                -webkit-transition: margin-top 0.2s ease-in-out;
                transition: margin-top 0.2s ease-in-out;


            }
            ul{
                max-width: 792px;
                @include breakpoint(1024px down) {
                  max-width: 100%;
                }
            }
            .main-menu{
                > li{
                    > a{
                        color:$white;
                        padding-top: 24px;
                        -webkit-transition: padding-top 0.2s ease-in-out;
                        transition: padding-top 0.2s ease-in-out;
                        @include breakpoint(1024px down) {
                          padding-top: 0px;
                        }
                        &:before{
                            bottom:21px;
                        }
                    }
                }
            }
        }
    }

    @media #{breakpoint(large down)}{
        header {
            .top-bar{
                .top-bar-section{
                    margin-top:10px;
                }
            }
        }
    }
}

// .admin-preview{
//   header{
//     z-index:0 !important;
//   }
// }

.white{
    color:$white!important;
}

.no-margin{
    margin: 0!important;
}

a.disable-style{
    text-decoration: none!important;
    &:hover{
        text-decoration: none!important;
    }
}

.left{
    text-align: left!important;
}

.fleft{
    float:left;
    &.important{
        float:left!important;
    }
}

.fright{
    float:right;
    &.important{
        float:right!important;
    }
}

@media #{$small-only} {
    header {
        .top-bar {
            .tools {
                position: relative;
                right: rem-calc(10);
                top: rem-calc(6);
            }
        }
    }
}

#main-content.width-full{
    width: 100%;
}
#main-content{
    .widget{
        clear:both;
        overflow: hidden;
        margin-bottom: rem-calc(20);
        .heading{
            font-weight: bold;
            text-align: center;
            line-height: rem-calc(46);
            margin-top: rem-calc(59);
            margin-bottom: rem-calc(50);
            font-size: rem-calc(55);
            &.small-heading{
                font-size: rem-calc(24);
                margin-bottom: rem-calc(24);
                margin-top: rem-calc(0);
            }
            @media #{breakpoint(small down)}{
                font-size: rem-calc(30);
                font-weight: bold;
                text-align: center;
                line-height: rem-calc(46);
                margin-top: rem-calc(13);
                margin-bottom: rem-calc(31);
            }
        }
    }
    &[class*='medium']{
        @media #{breakpoint(small down)}{
            padding:0px;
        }
    }
}

h1.header{
    font-size: rem-calc(55);
    font-weight: bold;
    text-align: center;
    margin: rem-calc(0 0 23);
    line-height: rem-calc(64);
    @media #{breakpoint(medium down)}{
        font-size: 40px;
        line-height: 50px;
        margin: 50px 0 25px;
    }
    @media #{breakpoint(small down)}{
        font-size: 28px;
        line-height: 38px;
        margin: 15px 0 25px;
    }
}

#side-right{
    @media #{breakpoint(medium down)}{
        padding: rem-calc(0 20);
        padding-top: rem-calc(30);
        margin-top: rem-calc(30);
        border-top: 1px solid $border-default-color;
    }
    @media #{breakpoint(small down)}{
        padding: rem-calc(25 10);
    }
    .widget{
        clear:both;
        overflow: hidden;
        margin-bottom: rem-calc(20);;
        .heading{
            text-align: center;
            font-size: rem-calc(30);
            font-weight: bold;
        }
    }
}
.typical-section{
    .widget{
        padding-top: 80px;
        padding-bottom: 80px;
        background-color: $white;
        @include breakpoint(736px down){
          padding: 40px 0;
        }
    }
}

.columns{
    padding: 0;
}


.main-content{
    position: relative;
    padding-bottom: 15px;
}

.background-dark{
    background-color: $lightblack !important;
    .container-paralax-image{
        @include paralax();
    }
    .paralax-svg-icon{
        fill: $white;
        -moz-transition: fill 0.5s ease-in-out;
        -o-transition: fill 0.5s ease-in-out;
        -webkit-transition: fill 0.5s ease-in-out;
        transition: fill 0.5s ease-in-out;
    }
    .paralax-content-columns{
        width: 92%;
        max-width: 990px;
        margin: 0 auto;
        overflow: hidden;
        padding: 70px 0 80px 0;
        @include breakpoint(736px down){
          padding: 40px 0 50px 0;
        }
        &.columns{
            float:none;
        }
    }
    .row{
        max-width: 100% !important;
        min-width: 100% !important;
    }
    @include section-white-font();
}
.background-light{
    background-color: $white !important;

    .paralax-svg-icon{
        fill: #e52620 !important;
    }
    .paralax-content p, #experience .widget .paralax-content h1{
      color:$black !important;
    }
    .paralax-content{
      p:first-of-type{
        font-size: 26px !important;
        margin-bottom: 60px !important;
        @include breakpoint(736px down){
          margin-bottom: 30px !important;
          font-size: 18px !important;
        }
      }
    }
    .paralax-content-columns{
        width:92%;
        max-width: 990px;
        margin: 0 auto;
        overflow: hidden;
        @include breakpoint(736px down){
          width:92%;
        }
        &.columns{
            float:none;
        }
    }
    h2.heading{
      border-bottom: 2px solid $blue-light !important;
      font-size: 36px !important;
      padding-bottom: 27px !important;
      padding-top: 15px !important;
      font-weight: 400 !important;
      margin-bottom: 70px !important;
      color: $lightblack !important;
      border-color: $lightblack !important;
      font-weight: 400 !important;
      @include breakpoint(736px down){
        margin-bottom: 20px !important;
        font-size: 21px !important;
      }
  }
    .container-paralax-image{
        background-image: none !important;
    }
    .paralax-svg-icon{
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 15px;
        margin-top: 5px;
        @include breakpoint(736px down){
          width: 40px;

        }
    }

    .st1, .st2{
      fill: #e52620 !important;
    }
    .red-svg .cls-1,
    .red-svg .cls-2 {
        fill: #e52620;
    }

    .red-svg .cls-1 {
        stroke: none;
        stroke-miterlimit: 10;
        stroke-width: 1.42px;
    }
    .red-svg .cls-2 {
        fill: #e52620;
        stroke: none;
        stroke-miterlimit: 10;
        stroke-width: 1.42px;
    }

    .red-svg .cls-3 {
        fill: #e30613;
    }
}
.paralax-svg-icon{
    width: 50px;
    height: 50px;
    float: left;
    margin-right: 15px;
    margin-top: 5px;
    @include breakpoint(736px down){
      width: 40px;
    }
}


.red-svg .cls-1,
.red-svg .cls-2 {
    fill: #e52620;
}

.red-svg .cls-1 {
    stroke: none;
    stroke-miterlimit: 10;
    stroke-width: 1.42px;
}

#our-group .red-svg .cls-1, #responsibility .red-svg .cls-1, #shareholders .red-svg .cls-1{
    fill: none;
}

.red-svg .cls-2 {
    fill: #e52620;
    stroke: none;
    stroke-miterlimit: 10;
    stroke-width: 1.42px;
}

.red-svg .cls-3 {
    fill: #e30613;
}

.white-svg .cls-1,
.white-svg .cls-2 {
    fill: $blue-light;
}

.white-svg .cls-1 {
    stroke: $blue-light;
    stroke-miterlimit: 10;
    stroke-width: 1.42px;
}

.white-svg .cls-1 {
    fill: none;
}

.white-svg .cls-2,
.white-svg .cls-3 {
    fill: $blue-light;
}

.white-svg .cls-3 {
    stroke: $blue-light;
    stroke-miterlimit: 10;
    stroke-width: 1.42px;
}
#subpage-main-paralax{
    .background-dark {
        .paralax-content-columns{
            padding: 0px;
        }
    }
    .widget-paralax-bg{
      // margin-top: -70px;
    }
    .container-paralax-image{

    }
    .paralax-content{
        margin-top:230px;
        padding-bottom: 130px;
        text-align: center;
        p{
            color:$white !important;
            margin-bottom: 0px;
        }
        @include breakpoint(736px down){
          margin-top:130px;
          padding-bottom: 80px;
        }
    }
    .top-big-text{
        text-transform: uppercase;
        font-size: 42px;
        line-height: 1.25em;
        border: none;
        margin-bottom: 0;
        padding:0;
        @include breakpoint(736px down){
          font-size: 20px !important;
        }
    }
    .top-small-text{
        font-size: 32px;
        line-height: 1.9em;
        border: none;
        margin-bottom: 0;
        padding:0;
        @include breakpoint(736px down){
          font-size: 19px !important;
          line-height: 1em;
          margin-top: 10px;
        }
    }
    &.row{
        max-width: 100% !important;
        min-width: 100% !important;
    }
}

.home-rotator{
    position: absolute;
    bottom: 30px;
    width: 100%;
    overflow: hidden;
    z-index: 99998;
    p{
        float: left;
        margin-top: 10px;
    }
    .owl-carousel{
        .owl-stage-outer{
            height:48px;
        }
        .owl-stage{
            margin:auto;
            .owl-item{
                float:left;
                height: 48px;
                img{
                    max-width:100px !important;
                    max-height:48px;
                    margin:auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -moz-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }

            }
        }
    }
}

#home-main-paralax{
  // margin-top: -70px;
  height: 100vh;
  // max-height:1000px;
  overflow: hidden;
  a#for-suppliers{
    padding: 0 16px;
    display: inline-block;
    text-transform: uppercase;
    color: $blue-light;
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    box-sizing: border-box;
    padding-top: 1px;
    text-decoration: none;
    background-color: $red-color-site;
    -moz-transition: background-color 0.25s ease-in-out;
    -o-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    z-index: 99998;
    position: absolute;
    right: 0;
    top: 200px;
    &::after{
      content: "\f105";
      display: inline-block;
      font-family: 'fontawesome';
      font-size: 30px;
      line-height: 51px;
      float: right;
      margin-left: 31px;
    }
    &:hover{
      background-color: $btn-color;
    }
  }
@include breakpoint(736px down){
    a#for-suppliers{
      width: 100%;
      top: 70px;
    }
  }
    .widget{
        p{
            color:$white !important;
        }
        a{
            color:$white !important;
            &:hover{
                text-decoration: none;
            }
        }
    }
    &.row{
        width: 100%;
        max-width: 100%;
    }
    .background-dark{
          // margin-top: -70px;
        .container-paralax-image{
            height: 100vh;
            max-height:1000px;
            .paralax-content{
                text-align: center;
                width: 92%;
                max-width: 772px;
                position: absolute;
                height: 224px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0;
                h3{
                    &.title{
                        text-align: center;
                        font-size:42px;
                        color:$white !important;
                        text-transform: uppercase;
                        line-height: 1.3;
                    }
                }
                .top-big-text{
                    text-transform: uppercase;
                    font-size: 42px !important;
                    line-height: 1.29em !important;
                    margin-top: 29px !important;
                    margin-bottom:1rem !important;
                  @include breakpoint(736px down){
                        font-size: 20px !important;
                    }
                }
                .top-small-text{
                    font-size: 32px !important;
                    line-height: 0.9em !important;
                    margin-top: 0px !important;
                    @include breakpoint(736px down){
                      font-size: 18px !important;
                      line-height: 1.2em !important;
                      margin-top: 5px !important;
                    }
                }
            }
        }
    }
}

#home-button-top{
    &.scroll-td{
        font-size: 16px;
        color: $blue-light;
        display: block;
        text-transform: uppercase;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 130px;
        text-align: center;
        cursor: pointer;
        z-index: 99998;
        p{
            font-size: 16px !important;
            color: $blue-light !important;
            margin-top: 15px;
            display: block;
            text-transform: uppercase;
            &.scroll-text{
                margin-top:22px;
                line-height: 0;
                a{
                    font-size: 16px;
                }
            }
        }

        @media screen and (max-width: 736px) and (min-width: 320px) and (orientation: portrait){
              bottom: 140px;
        }
    }

}
#experience{
    .widget{
        .background-dark {
          .paralax-svg-icon{
            display: none;
          }
          .paralax-content{
              width: 100%;
              max-width: 990px;
              margin: 0 auto;
              overflow: hidden;
              p, h1{
                  color:$white;
                  margin-top:0px;
              }
              h1{
                  font-size: 36px;
                  color: $blue-light;
                  border-bottom: none!important;
                  padding-left: 0 !important;
                  padding-top:18px !important;
                  margin-bottom: 45px !important;
                  @include breakpoint(736px down){
                    font-size: 25px;
                    margin-bottom: 20px !important;
                    padding-top: 0 !important;
                    padding-bottom: 0;
                    line-height: 25px;
                  }
              }
          }
          p{
              font-size: 18px;
              line-height: 1.8em;
              margin-bottom: 15px;
              @include breakpoint(736px down){
                font-size: 16px;
                line-height: 1.5;
              }
          }
          p:nth-child(2){
              font-size: 26px !important;
              margin-bottom: 50px !important;
              color:#b3b3b3 !important;
              @include breakpoint(736px down){
                font-size: 18px !important;
                margin-bottom: 20px !important;
              }
          }
      }
        .paralax-content-columns{
            padding: 70px 0 55px 0;
            @include breakpoint(736px down){
              padding: 50px 0 30px 0;
            }

        }
      }
}

#home{
    #about-us{
        padding-top: 152px;
        padding-bottom: 60px;
        @media screen and (max-width: 1024px) and (min-width: 736px){
          padding-top: 50px;
          padding-bottom: 70px;
        }
        @include breakpoint(736px down){
          padding-top: 50px;
          padding-bottom: 30px;
        }

        section.content{
          @include breakpoint(736px down){
              width: 100%;
          }
          @media screen and (max-width: 1024px) and (min-width: 736px){
              width: 72%;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait){
              width: 65%;
          }
        }
        &.row{
            max-width: 990px !important;
            width: 92%;
            // min-width: 990px !important;
            .columns{
                padding: 0;
            }
        }
        ul{
            margin-left: 0;
        }
        .svg_circle{
            fill: transparent;
            -moz-transition: fill 0.25s ease-in-out;
            -o-transition: fill 0.25s ease-in-out;
            -webkit-transition: fill 0.25s ease-in-out;
            transition: fill 0.25s ease-in-out;
        }
        a:hover .svg-fillred, .content a:hover .cls-412 {
            fill: $blue-light;
        }
        .svg-fillred{
            -moz-transition: fill 0.25s ease-in-out;
            -o-transition: fill 0.25s ease-in-out;
            -webkit-transition: fill 0.25s ease-in-out;
            transition: fill 0.25s ease-in-out;
            fill: $red-color-site;
        }

        a:hover .svg_circle {
            fill: $red-color-site;
        }
        .svg-fillwhite{
            fill:white;
        }
        .svg_circle {
            stroke: #e52620;
            stroke-width: 2;
            fill: none;
        }
        .widget-menu-icon-svg{
            float: left;
            height: 50px;
            width: 50px;
            margin-right: 10px;
        }
        .widget-menu-icon-text{
            display: table-cell;
            vertical-align: middle;
            line-height: 1.2em;
            width: 175px;
          @include breakpoint(736px down){
              font-size: 18px;
            }
        }
    }

    .large-3{
        width:20% !important;
        margin-left: 5%;
        word-break: break-word;
        @media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-device-width: 1024px) and (min-device-width: 768px) and (orientation: portrait){
            width: 31% !important;
            margin-left: 4%;
        }
        @include breakpoint(736px down){
            width: 100% !important;
            margin-left: 0;
            position: relative;
            margin-top: 30px;
        }
    }
    p{
        color:$home-color-text;
        font-size:18px;
        margin:0;
        margin-top:58px;
        line-height: 1.8;

    }
    #about-us-left{
        h1{
            font-size:32px;
            line-height: 1;
            color:$lightblack;
            @include breakpoint(736px down){
              font-size: 25px;
            }
            &:last-child{
              font-size: 26px;
              color: #333333;
              @include breakpoint(736px down){
                font-size: 22px;
              }
            }
        }
        h2{
            font-size: 32px;
            line-height: 1;
            color: #1e252b;
            &:last-child{
              font-size: 26px;
              color: #333333
            }
        }
        h3.heading{
            margin-top: 82px;
            margin-bottom: 34px;
            font-size: 26px;
            color: #333333;
        }
        iframe{
            max-width: 100%;          
            @include breakpoint(550px down){
                height: 250px !important;
            }
            @include breakpoint(400px down){
                height: 200px !important;
            }
        }
        p{
            color:$home-color-text !important;
            font-size:18px !important;
            margin:0;
            margin-top:58px;
            line-height: 1.8;
            @include breakpoint(736px down){
              font-size: 16px !important;
              margin-top: 15px !important;
            }
            // padding-right: 20px;
        }
        li{
            display: inline-block;
            color: #666666;
            font-size: 20px;
            width: 235px;
            position: relative;
            line-height: 50px;
            box-sizing: border-box;
            margin-bottom: 20px !important;
            float: left;
            border:none;
            @include breakpoint(736px down){
              width: 100%;
            }
            a{
                color: #666666;
                font-size: 20px;
                text-decoration: none;
                -moz-transition: color 0.25s ease-in-out;
                -o-transition: color 0.25s ease-in-out;
                -webkit-transition: color 0.25s ease-in-out;
                transition: color 0.25s ease-in-out;
                display: table;
                height: 50px;
                &:hover{
                    color: $lightblack;
                }
            }
        }
    }

    #about-us-right{
        .title{
            color:$red-color-site;
            text-transform: uppercase;
            font-size: 16px;
            margin-top:10px;
            margin-bottom: 20px;
            line-height: 1.5em;
            @include breakpoint(736px down){
              margin-bottom: 10px;
            }
        }
        .date{
            &:first(2n){
                display: none !important;
            }
            &:nth-child(3n){
                display: none !important;
            }
            font-size: 12px;
            text-align: right;
            color: #808080;
            padding: 0;
            font-weight: normal;
        }
        .heading{
            font-size: 26px;
            color:$home-color-text !important;
            margin: 0 0 1.9rem;
            @include breakpoint(736px down){
              font-size: 22px;
            }
        }
        p{
            color:$home-color-text !important;
            font-size: 14px;
            margin-top: 20px;
            line-height: 1.5em;
            text-align:left;
            word-wrap: break-word;
            margin:0;
        }
        .image{
            display: none !important;
        }
    }
}
.about-us-subpage{
    .reveal-overlay .reveal{
        position: relative;
        li{
          list-style-type: disc;
        }
    }
    .reveal-overlay .reveal .reveal-header{
        margin-bottom: 10px !important;
        @include breakpoint(1024px down){
          padding-bottom: 15px;
        }
        h2{
          float:left;
          @include breakpoint(1024px down){
            margin-top: 15px;
          }
        }
        h2, .reveal-title{
          float:left;
          max-width: 90%;
          @include breakpoint(1024px down){
            max-width: 85%;
          }
          @include breakpoint(736px down){
            max-width: 80%
          }
        }
    }
    .reveal-overlay .reveal .reveal-content{
      padding-top: 25px;
      display: flex;
      @include breakpoint(1024px down){
        display: block;
      }
        .column:first-of-type{
            border-right: 1px solid rgba(128, 128, 128, 0.5);
            padding-right: 8%;
            padding-left: 0px;
            @include breakpoint(1024px down){
              border-bottom: 1px solid rgba(128, 128, 128, 0.5);
              border-right: none;
              padding-right: 0;
              margin-bottom: 15px;
            }
        }

        .reveal-content-right{
          // position: absolute;
          // top:53%;
          @include breakpoint(1024px down){
            font-size: 100%;
            position: relative;
            float: left;
          }
        }
        .column:last-child{
            padding-left: 7%;
            @include breakpoint(1024px down){
              padding-left: 0%;
            }
        }
        address{
            font-style: normal;
        }
        p{
          a{
              color: $red-color-site !important;
              font-weight: 700;
              font-size:20px;
              @include breakpoint(1024px down){
                font-size:18px;
              }
          }
        }

    }


}
#about-us-subpage{
    table,td,tr,tbody{
        padding: 0;
        border:0;
    }

    p{
        color: $red-color-site;
        font-size: 26px;
        margin-bottom: 60px;
    }
    .rd-section{

        table{
            h2{
                margin-bottom: 15px;
                margin-top:0px;
                font-weight: 700;
                font-size: 20px;
                color: $rd-h1-site;
            }
            p{
                font-size:18px;
                color:$black;
                margin-bottom: 0;
                line-height: 1.5;
            }
            td{
                margin-bottom: 60px;
                padding: 24px 30px 30px 30px;
                width: (100% / 3);
                float:left;
                &:first-child{
                    padding-left: 0px;
                }
                &:last-child{
                    padding-right:0;
                }

            }
        }
    }
    .section-logo{
        position: absolute;
        bottom: 15px;
        left: 4%;
        right: 4%;
        width: 88%;
        margin-left: 2%;
        padding: 10px 0;
        margin-right: 2%;
        @include breakpoint(736px down){
          left: 10px;
          width: 92%;
        }
        .row{
            max-width:990px;
            width:100%;
        }
        img{
          @include breakpoint(736px down){
            max-width: 60%;
          }
        }
        p{margin-bottom:0px !important;}
        .logo-left{
            float:left;
            text-align:left;
            width:50%;
            padding-right:10px;
        }
        .logo-right{
            float:right;
            text-align:right;
            width:50%;
            padding-left:10px;
        }
    }
}
#responsibility{
    p{
        font-size: 26px !important;
        margin-bottom: 60px !important;
        color:$black;
        @include breakpoint(736px down){
          margin-bottom: 30px !important;
          font-size: 18px !important;
        }
    }
    h2.heading{
        border-color: $red-color-site !important;
    }
    a{
      text-decoration: none;
      color: $red-color-site;
      font-weight: bold;
    }
    .column{
      padding-left: 0;
    }
    @include divcontent();
    ul{margin-left: 0px;list-style: none;}
    li{
        color: $rd-h1-site !important;
        margin-bottom: 15px !important;
        font-size: 18px!important;
        line-height: 1.5em !important;
        float: left!important;
        clear: both;
    }
    .medium-6.column:first-child{
        padding-left: 0 !important;
        padding-right: 35px !important;
    }
    .medium-6.column:last-child{
        padding-left: 30px;
        @include breakpoint(736px down){
          padding-left: 0px;
        }
    }
}


#environment,#grantsue{
    @include divcontent();
    li{
        color: $blue-light;
        font-size: 18px !important;
        line-height: 1.5em;
        list-style-type: disc !important;
        margin-bottom: 10px !important;
    }

    .paralax-content-columns{
        padding:70px 0 120px 0 !important;
        @include breakpoint(736px down){
          padding:40px 0 90px 0 !important;
        }
    }
    h3{
        font-size:18px;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.1;
        color:$white !important;
    }
    @include breakpoint(736px down){
      .medium-6.column p{
        font-size:16px !important;
      }
    }
}
#our-values{
    @include divcontent();
    @include divcontent-medium-4();
    // .widget{
    //     width: 92%;
    //     max-width: 990px;
    //     margin: 0 auto;
    //     overflow: hidden;
    // }
    .columns{
        padding: 0;
    }
}

#our-group{
    .row{
        .row{
            margin-right: auto;
            margin-left: auto;
        }
    }
    h2.heading{
        color:$white !important;
    }
    .paralax-content{
      h2{
          color:$red-color-site !important;
          font-size: 17px!important;
          margin-bottom: 5px!important;
          line-height: 1.5em!important;
          font-weight: 700;
      }
      a.our-group-simple-link{
          text-decoration: none;
          h2{
              color: $red-color-site !important;
              font-size: 17px !important;
              margin-bottom: 5px !important;
              line-height: 1.5em !important;
              font-weight: 500;

              p{
                  color: $white !important;
                  font-size: 17px !important;
                  margin-bottom: 5px !important;
                  line-height: 1.5em !important;
                  font-weight: 500;
              }
          }
          p{
              color: $white !important;
              font-size: 17px !important;
              margin-bottom: 5px !important;
              line-height: 1.5em !important;
              font-weight: 500 !important;
          }
      }
    }

    .our-group-simple{

        address{
            color:white;
        }
        &:hover{
            background-color: $red-color-site;
            p{
                color:$white !important;
                font-weight: 500 !important;
            }
            h2{
                p{
                    color:$white !important;
                    font-weight: 500 !important;
                }
                color:$white !important;
                font-weight: 500 !important;
            }
        }
    }
      a.our-group-simple-link:nth-child(n), a.our-group-simple-link:nth-child(4n){
        .medium-4.column{
          margin-left:0 !important;
        }
    }
    a.our-group-simple-link:nth-child(3n){
        .medium-4.column{
          margin-right:0 !important;
        }
    }

    .medium-4.column{
        width: 31.8% !important;
        border: 1px solid #FFF !important;
        padding: 20px !important;
        margin-right: 2% !important;
        margin: 1.6%;
        min-height: 220px;
        max-height: 220px;
        font-size: 18px;
        overflow: hidden;
        @include breakpoint(736px down){
          width: 98% !important;
          min-height: auto !important;
          max-height: 100% !important;
          margin-left: 0 !important;
        }

        h2{
            color:$red-color-site !important;
            font-weight: 800 !important;
        }
        &:hover{
            border: 1px solid $red-color-site !important;
            background-color: $red-color-site;
            h2{
                color:$white !important;
                font-weight: 500 !important;
            }
        }
    }

    address{
        font-style: normal  !important;
    }
}
#certificates{
    .row{
      .row{
        margin-left: 0;
        margin-right: 0;
        .large-2{
            margin-left: 3.33%;
              .images{
                -webkit-transition: all 1s ease;
                -moz-transition: all 1s ease;
                -o-transition: all 1s ease;
                &:hover{
                    -o-transition: all 0.6s;
                    -moz-transition: all 0.6s;
                    -webkit-transition: all 0.6s;
                    -moz-transform: scale(1.2);
                    -o-transform: scale(1.2);
                    -webkit-transform: scale(1.2);
                }
                .image:not(.no-zoom):after{
                  display: none;
                }
              }
        }
      }
    }
    h1{
        margin-bottom: 30px !important;
    }
    p{
        font-size: 18px !important;
        margin-bottom: 30px !important;
        color: $blue-light !important;
    }
    li{
        float: left;
        list-style:none;
        padding: 4.3%;
        float: left;
        margin-bottom: 35px;
        img{
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            &:hover{
                -o-transition: all 0.6s;
                -moz-transition: all 0.6s;
                -webkit-transition: all 0.6s;
                -moz-transform: scale(1.5);
                -o-transform: scale(1.5);
                -webkit-transform: scale(1.5);
            }
        }
    }
    .images.left {
      text-align: center!important;
      .image{
        @include breakpoint(1024px down){
          margin: 0 auto;
          width: 20%;
          float: none;
        }
      }
    }
}
#our-history{
    position:relative;
    @include breakpoint(736px down){
      width: 92%;
      margin: 0 auto;
    }
    .heading{
        border-bottom: 2px solid $blue-light;
        font-size: 36px;
        padding-bottom: 15px;
        padding-top: 6px;
        padding-left: 64px;
        margin-bottom: 70px;
        color: $lightblack;
        border-color: $lightblack;
    }
    .h1header{
      border-bottom: 2px solid #FFF !important;
      font-size: 36px !important;
      padding-bottom: 15px !important;
      padding-top: 6px !important;
      padding-left: 0px !important;
      margin-bottom: 70px !important;
      color: #1e252b !important;
      border-color: #1e252b !important;
      @include breakpoint(736px down){
        margin-bottom: 28px !important;
        font-size: 21px !important;
        padding-bottom: 15px !important;
      }
    }
    svg{
      width: 50px;
      height: 50px;
      float: left;
      margin-right: 15px;
      fill: #e52620 !important;
      @include breakpoint(736px down){
          width: 40px;
          margin-top:-10px;
      }
      .cls-1{
        fill: none;
      }
      .cls-2{
        fill: #e52620;
        stroke: none;
        stroke-miterlimit: 10;
        stroke-width: 1.42px;
      }
      .cls-3 {
          fill: $red-color-site !important;
      }
    }
    section.section-19{
        position:relative;
    }

    a.image{
        border-radius:50%;
    }

    .image{
        overflow:visible;
        &:before{
            content: '';
            height: 48px;
            width: 2px;
            background-color: #dddada;
            position: absolute;
            left: -1px;
            right: auto;
            -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
            top: -15px;
            @include breakpoint(736px down){
              display: none;
            }
        }
    }

    .circle-thumb{
        border-radius: 50%;
        width: 150px;
        height: 150px;
        border:3px solid #dddada;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        @include breakpoint(736px down){
          width: 120px;
          height: 120px;
        }
    }

    .widget{
        max-width:990px;
        margin:0 auto;
        .btnmore{
            position:absolute;
            bottom:0px;
            left:0;
            right:0;
            width:100%;
            z-index: 10;
            .read-more{
                font-size: 12px;
                height: 104px;
                bottom: 30px;
                width: 104px;
                line-height: 11px;
                display: inline-block;
                background-color: $red-color-site !important;
                color: #ffffff;
                border: 3px solid $red-color-site;
                border-radius: 50%;
                text-align: center;
                font-weight: bold;
                padding: 0;
                margin: auto;
                transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;

                &:hover{
                    background-color: #E84747 !important;
                    border-color: #E84747;
                    color: $white;
                }
            }
        }
        .news-shadow{
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 55%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            height: 420px;
            max-width: 990px;
            width: 100%;
            position: absolute;
            bottom: -15px;
            z-index: 0;
        }
        li{
            visibility: hidden;
            opacity: 0;
            height:0;
            transition: display 0s, opacity 0.5s linear;
        }
        li.visible{
            overflow: hidden;
            width: 52%;
            clear: both;
            float: left;
            height: auto;
            visibility: visible;
            opacity: 1;
            padding-right: 70px;
            @include breakpoint(736px down){
                width: 100%;
                padding-right: 0;
            }
            &:nth-child(odd){
                float:right;
                padding-left:65px;
                padding-right:0px;
                overflow:hidden!important;
                @include breakpoint(736px down){
                  padding-left:0px;
                }
                p{
                    text-align:left !important;
                    @include breakpoint(736px down){
                      text-align:center !important;
                      padding: 0 15% 0 15% !important;
                      margin-bottom: 10px !important;
                    }
                }

            }

            &:nth-child(even){
                .title{
                    text-align:right;
                    @include breakpoint(736px down){
                      text-align:center !important;
                    }
                }

                .image{
                    overflow:visible;
                    &:before{
                        content: '';
                        height: 48px;
                        width: 2px;
                        background-color: #dddada;
                        position: absolute;
                        right: -1px !important;
                        left: auto;
                        -webkit-transform: rotate(50deg);
                        transform: rotate(50deg);
                        top: -15px;
                        @include breakpoint(736px down){
                          display: none;
                        }
                    }
                }
                p{
                    text-align:right !important;
                    @include breakpoint(736px down){
                      text-align:center !important;
                      padding: 0 15% 0 15% !important;
                    }
                }
                .image{
                    float:right;
                    @include breakpoint(736px down){
                      float:none;
                    }
                }
                &::before{
                    position: absolute;
                    right: -10px;
                    top: 0px;
                    left:auto;
                    @include breakpoint(736px down){
                    }
                }

                &::after{
                    float: right;
                    position: absolute;
                    right: 18px;
                    left: auto;

                }
            }
        }
    }
    .news-list{
        position: relative;
    }
    .div-news{
        height: 100%;
        @include breakpoint(736px down){
          text-align: center;
          width: 100%;
        }

        p{
          color: #000;
          font-size: 18px;
          margin-bottom: 30px !important;
        }
    }

    .news{
        .title{
            font-size: 45px;
            color: #cb1218;
            line-height: 72px;
            font-weight: 700;
            @include breakpoint(736px down){
              margin-top: 100px;
              text-align: center;
              width: 100%;
            }
        }


        margin: 0;
        padding: 0;
        &::before{
            content: '';
            position: absolute;
            left: 19px;
            top: 0;
            transform: translateX(-50%);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: $red-color-site;
            z-index:1;
            @include breakpoint(736px down){
              content: '';
              width: 2px;
              height:100px;
              background: #dddada;
              float: left;
              position: absolute;
              left: 50% !important;
              top:0;
            }


        }
        &::after{
            content: '';
            width: 2px;
            height:100%;
            background: #dddada;
            float: left;
            position: absolute;
            left: 19px;
            top:40px;
        }
        @include breakpoint(736px down){
          &::after{
              display: none;
            }
        }
        .image{
            float:left;
            @include breakpoint(736px down){
              float:none;
            }
        }
    }
    &.rd-section{

        li{
            float: left;
            list-style:none;
            position: relative;
        }
    }
}

#researchdevelopment{
    table, tr, th, td{
        border:none !important;
    }
    table tbody tr:nth-child(even){
        background: transparent !important;
    }
    td{
        width: (100%/3);
        float: left;
        @include breakpoint(1024px down){
          padding-right: 20px !important;
        }
        @include breakpoint(736px down){
          width: 100%;
          margin-bottom: 35px;
          padding-right: 0px !important;
        }
    }

    #technologie{
        .cls-1 {
            fill: none;
        }
        table p {
          font-size:18px !important;
          @include breakpoint(736px down){
            font-size: 16px !important;
          }

        }
        @include section-black-font();
        h2{
            color: $rd-h2-color !important;
            margin-bottom: 20px !important;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
        }
        h2.heading{
            border-bottom:2px solid $black !important;
            padding-bottom: 25px !important;
            line-height: 32px !important;
        }
        p{
            color: $rd-h2-color !important;
            font-size: 26px;
            margin-bottom: 60px;
        }
    }
    #laboratorium{
        @include section-white-font();

        .cls-1 {
            fill: none;
        }
        h2{
            line-height: 20px;
        }
        h2.heading{
          padding-bottom: 25px !important;
          line-height: 32px !important;
        }
        td{
            padding-right: 20px !important;
        }
    }
    #slider{
      .cls-1 {
          fill: none;
      }
      .paralax-content-columns{
          margin-bottom: 200px;
      }
      .slider-columns-relative{
        position: relative;
      }
      .rotator-rd{
        position: absolute;
        bottom: 45%;
        transform: translateY(100%);
        background: transparent;
      }
      .owl-item{
         .item{
           margin-bottom: 0 !important;
         }
      }
      .owl-nav{
        .owl-prev, .owl-next{
          color:$white;
        }
      }
      &.typical-section{
        .widget{
          padding:0;
          background: transparent;
        }
      }
    }
}

.admin-preview{

    #researchdevelopment{

        #slider .rotator-rd{
            position: inherit;
            bottom: 0;
            transform: none;
        }
    }
}


.section-2,.section-3{
    @include breakpoint(768px down){
        padding: 0;
    }
}

.section-4, .section-5{
    .bottom-section{
        display: inline-block;
        vertical-align: top;
        color: #a9adb2;
        padding: 0 17px;
    }
}

.section-8 {
    .banner{
        float:left;

    }
}

.main-section {
    background: $white;
}

.big-title{
    border-bottom: 1px solid #dcdcdc;
    padding: 0 30px 25px;
    margin: 0 0 3rem;
    .heading{
        font-size: 55px;
        font-weight: bold;
        text-align: center;
        margin: 67px 0 42px;
        line-height: 64px;

    }
    .desc{
        font-size: 26px;
        padding: 0;
        text-align: center;
        margin-bottom: 26px;
    }
    .date{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 18px;
    }
    @include breakpoint(768px down){
        .heading{
            font-size: 40px;
            line-height: 50px;
            margin: 50px 0 25px;
        }
        .desc{
            font-size: 23px;
        }
    }
    @media #{breakpoint(small down)}{
        padding: 0 10px;
        .heading{
            font-size: 28px;
            line-height: 38px;
            margin: 15px 0 25px;
        }
        .desc{
            font-size: 19px;
        }
    }
}
.container-big-title{
    margin: 0 auto;
    max-width: 1050px;
    margin: 0 auto;
    width: 100%;

}

.content-big-title{
    @media #{breakpoint(small down)}{
        padding:10px;
    }
}

.inner-wrap {
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(40);
}

.beforeFooter{
    background: $lightblack;
    padding-top: 54px;
    padding-bottom: 50px;
    width:100%;
    float: left;
    @include breakpoint(768px down){
      text-align: center;
      padding-top: 30px;
    }
    h4{
        line-height: 1.25rem !important;
        margin-top: 22px !important;
    }
    .row{
        width: 92%;
        max-width: 1075px;
        margin: 0 auto;
        padding: 0;
    }
    .large-4{
      @include breakpoint(768px up){
          width:25% !important;
          padding-right: 40px !important;
        }
        padding-right: 0px;
        &:last-child{
            padding-right: 0 !important;
        }
    }
    .bottom-section{
        padding: 0;
    }
    .columns{
        padding: 0;
    }


    .section-8{
        padding-top: 95px;
        @include breakpoint(768px down){
          padding-top: 30px;
          padding-bottom: 30px;
        }
        ol{
            margin: 0 auto;
            width: 53%;
            height: 55px;
            // margin-bottom: 50px;
            // margin-top:100px;
            @include breakpoint(768px down){
              width: 100%;
              margin-top: 20px;
              margin-bottom: 25px;
              max-height: 55px;
              height: auto;
            }
        }
        li{
            float: left;
            clear: none !important;
            height: 55px;
            list-style-type: none;
            // margin-left:45px;
            width: 40%;
            text-align: left;
            margin-bottom:0 !important;
            &:last-child(){
              width:20% !important;
            }
            @include breakpoint(768px down){
              width: 29%;
              margin-left: 5%;
              max-height: 55px;
              height: auto;
              text-align: right;
            }
            img{
                height: 52px;
                width: auto;
                @include breakpoint(768px down){
                  height: auto;
                  max-height: 52px;
                  width:85%;
                }
            }
        }
    }

    .section-social-link{
        padding-top: 20px;
        float: right;
        @include breakpoint(768px down){
          width: 100%;
        }
        li{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            // text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            border:none !important;
            display: block;
            text-align: center;
            line-height: 31px;
            font-size: 19px;
            width: 31px;
            height: 31px;
            border-radius: 50%;
            background-color: $blue-light;
            color: #1a1a1a;
            -moz-transition: background-color 0.25s ease-in-out;
            -o-transition: background-color 0.25s ease-in-out;
            -webkit-transition: background-color 0.25s ease-in-out;
            transition: background-color 0.25s ease-in-out;
            float:right;
            overflow: hidden;
            position: relative;
            margin-left:10px;
            margin-bottom: 10px;
            @include breakpoint(768px down){
              display: inline-block;
              float: none;
              margin-left: 5px !important;
              margin-right: 5px !important;
            }
            &:hover{
                background-color: #b3b3b3;
            }
            a{
                text-indent: -99999px;
                height:31px;
                width: 31px;
                position: absolute;
                top: 0;
                border-radius: 50%;
            }
        }
    }
    .section-contact{
        margin-top:22px;
        width: 100%;
        float: right;
        ul{
            float: right;
            @include breakpoint(768px down){
              width: 100%;
            }
        }
        li{
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            // text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            border:none !important;
            display: block;
            text-align: center;
            line-height: 36px;
            font-size: 16px;
            background-color: $red-color-site;
            color: $white;
            height: 36px;
            padding: 0 9px;
            -moz-transition: background-color 0.25s ease-in-out;
            -o-transition: background-color 0.25s ease-in-out;
            -webkit-transition: background-color 0.25s ease-in-out;
            transition: background-color 0.25s ease-in-out;
            float:left;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            @include breakpoint(768px down){
              float: none;
              margin: auto;
              width: 181px;
            }
            &:hover{
                background-color: $btn-color;
            }

            a{                
                margin-left:10px;
                font-size: 14px;
                color:$white;
                text-decoration: none;
                letter-spacing: 0.4px;
                display: inline-block !important;
                &:not(.wcag-tooltip){
                    color:$white !important;
                    opacity:1 !important;
                    text-decoration: none;
                    &:focus{
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .section-5{
        p{
            line-height: 1.4;
            margin-bottom: 8px;
        }
    }
    .section-9{
      p{
        color: #999999 !important;
        font-size: 15px !important;
        margin-top: 30px !important;
        text-align: center !important;
        line-height: 1rem;
        @include breakpoint(736px down){
          margin-top: 15px !important;
        }
      }
    }
}

.widget{
    a{
        font-size: rem-calc(15px);
        color: #999999;
        &:hover{
            text-decoration: underline;
            color: #999999;
        }
    }
    p{
        font-size: rem-calc(15px);
        color: #999999;
    }
    li{
        float: left;
        color: $blue-light;
        font-size: rem-calc(16px) !important;
        margin-bottom: 10px !important;
        line-height: 1.3em !important;
    }
    h4{
        font-size: rem-calc(16px) !important;
        margin-bottom: 50px !important;
        font-weight: normal !important;

        @include breakpoint(768px down){
          margin: 0 0 10px 0 !important;
        }
    }
    thead, tbody, tfoot, td, tr{
        background: transparent;
        border:none;
    }
}

h4{
    text-transform: uppercase;
}
.section-4,.section-5{
    p{
        line-height: 1;
    }
    .widget{
        &:nth-child(even){
            padding-left: rem-calc(25);
        }
        li{
            float: left;
            clear: both;
        }
        @media #{breakpoint(768 down)}{
            border-right: 0px;
            border-bottom: 1px solid $navy-blue_light;
            padding-bottom: 20px;
            &:nth-child(even){
                padding-left: rem-calc(10);
            }
            &:last-of-type{
                border:0px;
                padding: 0px;
                margin-bottom:20px;
            }
        }
    }
}
.section-6{
    .widget{
        border: 0px;
        padding-left: rem-calc(10);
    }
    @include breakpoint(768px down){
        .widget{
            border-top: 1px solid $navy-blue_light;;
            margin-top: 20px;
            padding: rem-calc(0 10);
        }
    }

}


footer {
    background: $lightblack;
    color: $white;
    font-size: rem-calc(12);
    overflow: hidden;
    padding: rem-calc(20 0);

    .content {
        p {
            margin: 0;
        }
    }
}

.desc{
    ul{
        li{
            position:relative;
            &:before{
                content: '';
                display: inline-block;
                position: relative;
                width: 4px;
                height: 4px;
                background-color: $blue-primary;
                left: -14px;
                top: -4px;
                margin-right: -8px;
            }
        }
    }
}

.blue-section{
    background-color: $navy-blue;
    .heading{
        font-weight: bold;
        text-align: center;
        line-height: rem-calc(46);
        margin-top: rem-calc(59)!important;
        margin-bottom: rem-calc(50)!important;
        font-size: rem-calc(55)!important;
        color:$white;
        &.small-heading{
            font-size: rem-calc(24);
            margin-bottom: rem-calc(24);
            margin-top: rem-calc(0);
        }
        @media #{breakpoint(small down)}{
            font-size: rem-calc(30)!important;
            font-weight: bold;
            text-align: center;
            line-height: rem-calc(46);
            margin-top: rem-calc(13)!important;
            margin-bottom: rem-calc(31)!important;
        }
    }
    .blue-content{
        padding: 20px 104px 70px;
        @media #{breakpoint(small down)}{
            padding: 20px 10px 40px;
        }
        .section{
            color:$white;
        }
    }

}

@media #{$small-only} {
    footer {
        .columns:nth-child(2) {
            padding-top: rem-calc(20);
        }
    }
}

@media #{$medium-up} {
    footer {
        .columns:nth-child(2) {
            text-align: right;
        }
    }
}


// aside canvas menu
.off-canvas-wrap {
    .inner-wrap {
      margin-right: 1.25em;
      margin-left: 1.25em;
        .left-off-canvas-menu,
        .right-off-canvas-menu {
            min-height: rem-calc(10);
        }

        .left-off-canvas-toggle {
            float: right;
        }

        .right-off-canvas-toggle {
            float: left;
        }

        .left-off-canvas-toggle,
        .right-off-canvas-toggle {
            width: rem-calc(60);
            height: rem-calc(60);
            position: relative;
            background: $vapor;
            margin: rem-calc(0 0 1);

            span:after {
                @include burger-menu-icon($jet, $complete: true);
                margin-top: rem-calc(-12) !important;
            }

            &:hover {
                background: $blue-light;

                span:after {
                    @include burger-menu-icon($white, $complete: true);
                }
            }
        }
    }

    .exit-off-canvas {
        display: none;
    }

    &.move-left,
    &.move-right {
        .exit-off-canvas {
            display: block;
        }
    }
}

@include breakpoint(768px down) {

    .home,
    .none-section {
        .inner-wrap {
            padding: 0;
        }
    }
    .left-section {
        .inner-wrap {
            padding-right: 0;
        }
    }
    .right-section {
        .inner-wrap {
            padding-left: 0;
        }
    }

    .right-off-canvas-menu{
        padding:0 20px;
    }
    &.move-right {
        .inner-wrap {
            transform: translate3d(rem-calc(176), 0, 0);

            .left-off-canvas-menu {
                .left-off-canvas-column {
                    display: block;
                }
            }
        }
    }
    &.move-left {
        .inner-wrap {
            transform: translate3d(rem-calc(-176), 0, 0);

            .right-off-canvas-menu {
                .right-off-canvas-column {
                    display: block;
                }
            }
        }
    }
}

@media #{$small-only} {
    .off-canvas-wrap {
        .inner-wrap {
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(20);

            .left-off-canvas-menu {
                margin: rem-calc(0 0 0 -216);
            }
            .right-off-canvas-menu {
                margin: rem-calc(0 -216 0 0);
            }

            .exit-off-canvas {
                margin: rem-calc(0 40);
            }
        }

        &.move-right {
            .inner-wrap {
                transform: translate3d(rem-calc(216), 0, 0);
            }
        }
        &.move-left {
            .inner-wrap {
                transform: translate3d(rem-calc(-216), 0, 0);
            }
        }
    }
}

@media #{$large-up} {
    .left-off-canvas-menu,
    .right-off-canvas-menu {
        transition: none;
        transform: none;
        position: relative;
        background: transparent;
    }

    .left-off-canvas-menu,
    .left-submenu,
    .right-off-canvas-menu,
    .right-submenu {
        box-sizing: border-box;
        width: 25%;
    }

    .inner-wrap {
        transition: none !important;
        transform: none !important;
    }
}
