.surveys {
    .survey-messages {
        padding-bottom: rem-calc(5px);
    }

    .title{
        font-size: rem-calc(18px);
        font-weight: bold;
        color: $navy-blue;
    }
    .answers{
        .answer{
            margin-bottom: rem-calc(20px);
            > span{
                padding-bottom: rem-calc(6px);
                display: inline-block;
            }
            .progress{
                width: 100%;
                border: 1px solid $border-default-color;
                .meter{
                    display: block;
                    height: rem-calc(40px);
                    background-color: $navy-blue;
                    width: 0px;
                    @include duration();
                }
            }
        }
    }
}


/* home */
.widget {
}

@media #{$medium-up} {
}

@media #{$large-up} {
}