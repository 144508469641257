.faq-module {
    label {
        font-size: rem-calc(16px);
    }

    .button {
        margin: 0;
    }
    .rating {
        margin-bottom: rem-calc(20px);
        p {
            margin: rem-calc(0 0 10);
        }
    }

    &:not(.faq-list) {
        .button.tiny.right {
            margin: rem-calc(20 0 0);
        }
    }
    .list{
        li{
            padding: 0;
            &:before{
                display: none;
            }
            > *{
                display: block;
                line-height: normal;
            }
            margin-bottom: rem-calc(20px);
            border: 0;
            .title{
                display: block;
                font-size: rem-calc(26px);
                line-height: rem-calc(35px);
                margin: rem-calc(0 0 21px);
            }
            a{
                font-size: rem-calc(16);
                color: $blue-dark;
                border-bottom: 1px solid $border-default-color;
                position: relative;
                padding-left: rem-calc(10);
                // margin-right: rem-calc(28);
                padding: rem-calc(8 20 9 10);
                display:block;
                &:before{
                    // content: '?';
                    position: absolute;
                    right: 0.3rem;
                    left: inherit;
                    bottom: 0.5rem;
                    // top: initial;
                    // background-color: $white;
                    // border: 1px solid $border-default-color;
                    // font-size: rem-calc(31);
                    // width: rem-calc(46);
                    // text-align: center;
                    // border-radius: rem-calc(33);
                    // font-family: inherit;
                }
                &:hover{
                    background: #f7f7f7;
                    color:$blue-dark;
                    text-decoration:none;
                    &:before{
                        color:#969696;
                    }
                }
            }
        }
    }
    @media #{breakpoint(small down)}{
        margin: rem-calc(0 10px);
    }
}

.faq-asist {
    form {
        @include border(1, 'bottom');
        margin: rem-calc(0 0 20);
        padding: rem-calc(0 0 4);
        overflow: hidden;
    }
}
