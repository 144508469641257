#shareholders{
  .paralax-content-columns{
      padding-top: 80px !important;
      padding-bottom: 60px !important;
      h1{
        margin-bottom: 20px !important;
      }
      p{
        font-size: 20px !important;
        font-weight: 700 !important;
        color: #1e252b !important;
        text-align: center !important;
        margin-bottom: 20px !important;
        @include breakpoint(736px down){
          font-size: 14px !important;
        }
      }
  }
  table, tr, td, th{
    border: none !important;
  }
  table{
    tr{
      td{
        padding: 20px 15px;
        font-size: 16px;
        color: $red-color-site;
        font-weight: 700;
        text-align: center;
        line-height: 16px;
        vertical-align: top;
      }
      td:first-child{
        width: 50%;
        text-align: left;
        color: $lightblack;
      }
      &:first-child{
        background-color: $red-color-site;
        td{
          background-color: $red-color-site;
          font-size: 16px;
          color: $gray-dark;
          font-weight: 700;
          padding: 12px 0;
          text-align: center;
        }
      }
      &:nth-child(even){
        background-color: rgba(134, 121, 121, 0.05) !important;
      }
      &:nth-child(odd){
        background-color: #e6e6e6 !important;
      }
    }
  }
}
