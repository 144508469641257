.admin-preview {
    header {
        position: absolute; // height: 75px;
        top: 55px;
    }
    #home-main-paralax {
        height: 600px;
        max-height: 600px;
        position: relative;
    }
    .main-rotator {
        z-index: 1;
    }
    #error {
        margin-top: 150px;
    }
    .widget-map-address .address {
        visibility: visible !important;
        opacity: 1 !important;
        height: auto !important;
    }
}

.admin-widget-buttons {
    z-index: 999999999999999999 !important;
}

.admin-section {
    z-index: 1 !important;
}

.admin_button_up,
.admin_button_down,
.admin_button_move {
    display: none;
}

.admin-widget-button[data-action='up'],
.admin-widget-button[data-action='down'],
.admin-widget-button[data-action='move'] {
    display: none;
}