.maps-module{
    .maps{
        #map{
            width: 100%;
            height: 400px;
            .marker{
                .image_marker{
                    float: left;
                    margin-right: 15px;
                }
                .image_marker + .text{
                      padding-left: 179px;
                }
                .text{
                    .title{

                    }
                    .desc{
                        margin-bottom: 10px;
                        font-size: 15px;
                    }
                    .oder_desc{
                        span{
                            display: inline-block;
                            width: calc(50% - 4px);
                            margin-bottom: 3px;
                            i{
                                font-size: 19px;
                                padding-right: 5px;
                                float: left;
                                width: 30px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .wide &, .container-big-title &{
        .maps{
            #map{
                height: 600px;
            }
        }
    }
}