
.widget-financial-reports{

    padding: 100px 0px;
    @include breakpoint(736px down){
      padding: 40px 0px;
    }
    .fixed-row{
        margin-left: auto;
        margin-right: auto;
        max-width: 990px;
        @include breakpoint(medium down){
            padding: 0 20px;
        }
    }
    h2.title{
        border-bottom: 2px solid #1e252b !important;
    }
    .title{
        color: $lightblack;
        font-size: 35px;
        line-height: 65px;
        margin-bottom: 105px;

        @include breakpoint(736px down){
          margin-bottom: 10px;
        }
        @include breakpoint(480px down){
          font-size: 21px !important;
          margin-bottom: 20px;
        }
        svg{
            float: left;
            margin-right: 20px;
            position: relative;
            top: 4px;
            width: 50px;
            height: 50px;
        }



    }

    .reports{
        margin-bottom: 70px;
        .title{
            font-size: 20px;
            font-weight: 700;
            margin-bottom: auto;
        }
        .reports-button{
            background-color: $red-color-site;
            color: $white;
            display: inline-block;
            font-size: 14px;
            height: 54px;
            line-height: 54px;
            padding: 1px 16px 0;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color 0.25s ease-in-out 0s;
            @include breakpoint(1024px down){
              width: 100%;
            }
            &::after{
                content: "\f105";
                display: inline-block;
                float: right;
                font-family: "fontawesome";
                font-size: 30px;
                line-height: 51px;
                margin-left: 31px;
            }
            &:hover{
                background-color: $btn-hover-color;
            }
        }
        &.financial-info{
            .reports-button{
                margin-bottom: 10px;
            }
        }
        &.reports-other{
            .report-others-button{
              width: 25%;
              margin-right: 12%;
              float: left;
              text-align: center;
              position: relative;
              &:last-child{
                margin-right: 0;
              }
                a{
                    text-decoration: none;
                    span.button-title{
                        display: block;
                        color: $red-color-site;
                        font-size: 14px;
                        font-weight: 700;
                        margin-top: 5px;
                    }
                    span.svg-number{
                        position: absolute;
                        top: 0px;
                        font-size: 14px;
                        font-weight: 700;
                        color: $white;
                    }
                    svg {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        .path1{
                            fill: #e1e1e1;
                            transition: fill 0.25s ease-in-out 0s;
                        }
                        .path2, circle{
                            fill: #bf1818;
                            transition: fill 0.25s ease-in-out 0s;
                        }
                        &:hover{
                            .path1{
                                fill: $svg-hover-color;
                            }
                            .path2, circle{
                                fill: $btn-hover-color;
                            }
                        }
                    }
                }
                &.button-quarterly{
                    a{
                        span.svg-number{
                          top: 20%;
                          right: 23%;
                          transform: translate(20%,-23%);
                          @include breakpoint(480px down){
                            top: 15%;
                            right: 20%;
                            transform: translate(15%,-20%);
                          }                        
                        }                      
                    }
                }
                &.button-semi-annual{
                    a{
                        span.svg-number{
                          top: 38%;
                          right: 22%;
                          transform: translate(38%,-22%);
                          @include breakpoint(480px down){
                            top:36%;
                            transform: translateY(-36%);
                          }
                        }
                        .button-title{   
                            @include breakpoint(medium down){
                                margin-top:10%;
                            }                       
                            @include breakpoint(490px down){
                                margin-top:15% !important;
                            } 
                            @include breakpoint(380px down){
                                margin-top:12% !important;
                            }                         
                        }
                        svg{
                            height: 165px;
                            @include breakpoint(medium down){
                                height: 100%;        
                            }
                        }
                    }
                }
                &.button-annual{
                    a{
                        span.svg-number{
                          top: 42%;
                          right: 50%;
                          transform: translate(42%,-50%);
                          @include breakpoint(480px down){
                            top:36%;
                            transform: translateY(-36%);
                          }
                        }
                    }
                }
            }
        }
    }
}
