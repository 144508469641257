// top bar navigation
.main-menu{
    text-align: right;
    position: relative;
    z-index: 9999;
    > li{
        display: inline-block;
        height:70px;
        transition: padding-top 0.25s ease-in-out;
        margin-left: 27px;
        position: relative;
        > a{
            position: relative;
            color:$white;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            height: 100%;
            font-size: 13px;
            display: block;
            padding-top: 45px;
            box-sizing: border-box;
            -webkit-transition: padding-top 0.2s ease-in-out;
            transition: padding-top 0.2s ease-in-out;
            &:hover,&:focus{
                color:$white;
                box-shadow: none !important;
                border:none !important;
                text-decoration: none;
            }

        }
        > ul{
            &.open{
                display: block;
            }
            > li{
              display: inline-block;
              // margin-left: 27px;
              // height: 70px;
              position: relative;
            }
        }
        &.active{
            > a{
                span{
                    color:$white;
                    -moz-transition: border-color 0.25s ease-out;
                    -o-transition: border-color 0.25s ease-out;
                    -webkit-transition: border-color 0.25s ease-out;
                    transition: border-color 0.25s ease-out;
                    padding-bottom: 2px;
                    border-bottom: 2px solid;
                    border-color: #bf1818;
                }
                &:before{
                  display: none;
                }
            }
        }
    }
    @media #{breakpoint(large up)} {
        display: block!important;

        > li{
            > a{
                &:before {
                    // content: '';
                    // position: absolute;
                    // bottom: 5px;
                    // left: 50%;
                    // margin-left: -16px;
                    // width: 32px;
                    // height: 2px;
                    // opacity: 1;
                    // background: $redmenu;
                    // -webkit-transform: scale3d(0, 1, 1);
                    // transform: scale3d(0, 1, 1);


                    content: '';
                    position: absolute;
                    bottom: 2px;
                    left: 1%;
                    right: 6%;
                    width: 100%;
                    height: 2px;
                    opacity: 1;
                    background: $red-color-site;
                    -webkit-transform: scale3d(0, 1, 1);
                    transform: scale3d(0, 1, 1);
                    // -webkit-transform-origin: 100% 50%;
                    // transform-origin: 100% 50%;
                    // -webkit-transition: -webkit-transform 0s 0.2s, opacity 0.2s;
                    // transition: transform 0s 0.2s, opacity 0.2s;
                }
            }

            &.active{
                > a{
                    color:$white;
                }
            }
            &:hover,&:focus{
                > a{
                    &:before{
                        opacity: 1;
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                        // -webkit-transition: -webkit-transform 0.2s, opacity 0.1s;
                        // transition: transform 0.2s, opacity 0.1s;
                        // -webkit-transition-delay: 0.35s;
                        // transition-delay: 0.35s;
                        // -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
                        // transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
                    }
                }
                > ul{
                    display: block!important;
                }
            }

            > ul{
                position: absolute;
                width: 100%;
                left: -15px;
                top:70px;
                // top: rem-calc(109);
                // background: $white;
                z-index: 2;
                margin: 0;
                padding: 15px;
                min-width: 200px;
                // padding: 50px calc((100% - #{$global-width}) / 2);
                text-align: left;
                display:none;
                // border-top: 1px solid rgba(255, 255, 255, 0.3);
                background-color: rgba(30, 37, 43, 0.8);
                html[lang="de"] &{
                    min-width: 215px;
                }
                li{
                    display: inline-block;
                    // width: calc(25% - 5px);
                    vertical-align: top;
                    margin-bottom: 4px;
                    float: left;
                    clear: both;
                    width: 100%;
                    a{
                        opacity: 1;
                        color: $white;
                        // padding: rem-calc(0 0 13);
                        // margin-bottom: rem-calc(13);
                        position: relative;
                        display: block;
                        line-height: 19px;
                        font-size: 14px;
                        width: 100%;
                        &:before{
                            position: absolute;
                            content: '';
                            width: rem-calc(64);
                            height: rem-calc(1);
                            // background: $white;
                            bottom: 0;
                            // opacity: 0.75;
                        }
                        &:hover,&:focus{
                            opacity: 1;
                            color: $white;
                            text-decoration: underline;
                        }
                    }
                    > ul{
                        display: block!important;
                        height: auto;
                        margin: 0;
                        li{
                            width: 100%;
                            a{
                                font-size: rem-calc(15);
                                color: $white;
                                opacity: 0.75;
                                font-family: $main-font;
                                font-weight: normal;
                                margin: 0;
                                padding: rem-calc(4 10);
                                &:before{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media #{breakpoint(medium down)}{
        display: none;
        position: absolute;
        width: 100%;
        left: 0;
        margin: 0;
        z-index: 3;
        top: 50px;
        margin-top:20px;
        background: #1e252b;
        padding: 20px 0 0 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        > li{
            > a {
                // border-bottom: 1px solid rgba(255, 255, 255, 0.11);
            }
            ul{
                display: none;
                margin: 0;
            }
            > ul{
              display: none!important;
                padding: 0px;
                background: #004969;

                li > ul{
                    background: #00354c;
                }
            }
        }
        li{
            position:relative;
            width: 100%;
            text-align: center;
            margin-left: 0px;
            height: auto;
            a{
                line-height: 13px;
                padding-top: 0;
                color: $white;
                // height: 30px;
                margin-bottom: 22px;
            }
            .open_submenu{
                display:  none;
                width: 60px;
                height: 50px;
                position: absolute;
                right: 0px;
                cursor: pointer;
                top: 0;
                &:before,&:after{
                    content: '';
                    display: block;
                    width: 30px;
                    height: 1px;
                    position: absolute;
                    background-color: $white;
                }
                &:before{
                    top: 50%;
                    @include transform(rotate(90deg) translateX(0px) translateY(12.5px));
                    @include duration();
                    left: 50%;
                }
                &:after{
                    @include center();
                }
                &.active{
                    &:before{
                        @include transform(rotate(360deg) translateX(-15px) translateY(0px));
                    }
                }
            }
            &:last-child{
              margin-bottom: 0;
            }
            &.lang-first{
              border-top: 1px solid rgba(255, 255, 255, 0.4);
              padding-top: 22px;
            }
        }
    }
    @include breakpoint(736px down){
      padding-bottom: 10px;
      li{
          a{
              height: auto;
              margin-bottom: 10px;
          }
          &.lang:nth-child(2n-1){
            padding-top: 10px;
          }
        }

    }
    @media #{breakpoint(small down)}{
        top: 50px;
        margin-top:20px;
    }
}

// side navigation
.side-nav {
    margin: 0;
    li{
        border: 1px solid $border-default-color;
        border-width: rem-calc(0 0 1 0);
        a:not(.wcag-tooltip){
            font-family: $main-font;
            color: $navy-blue;
            opacity: 0.75;
            font-size: rem-calc(13);
            // padding: rem-calc(15 8 15 13);
            height: auto;
            font-weight: 100;
            display: block;
            border-top: 0px;
            &:hover,&:focus{
                color:$blue-primary;
            }
            &:focus{
                outline: 0px;
                text-decoration: underline;
            }
        }

        &.active{
            > a{
                color:$white!important;
                background-color: $blue-primary;
                position:relative;
                &:not(.level-one){
                    padding-left: rem-calc(16);
                    &:before{
                        content: '';
                        @include triangle($smoke,rem-calc(10),rem-calc(10),right);
                        display: block;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        @include transform(translateY(-50%));
                    }
                }
            }
        }
        a.wcag-tooltip{
            display: none;
        }
        > ul{
            margin:0px;
            li{
                background-color: $smoke;
                padding-left:rem-calc(10);
                &:first-of-type{
                    border-top-width: rem-calc(1);
                }
                &:last-of-type{
                    border-bottom-width: 0px;
                }
                a:not(.wcag-tooltip){
                    color: $navy-blue;
                    // padding: rem-calc(7 0 7 13);
                    font-size: rem-calc(11);
                    display: block;
                }
            }
        }
    }
}

// footer navigation
.bottom-nav {
    margin: rem-calc(0 -10);
    list-style: none;
    padding: 0;

    li {
        margin: 0;
        padding: rem-calc(0 10px);
        list-style: none;
        display: inline-block;

        a {
            font-size: rem-calc(15);
            color: #999999;
            display: block;

            &:hover,&focus {
                text-decoration: underline !important;
                color: #999999 !important;
            }
        }

        &.active {
            > a {
                text-decoration: underline;
                opacity: 1;
            }
        }
    }
    @media #{breakpoint(large up)}{
        li {
            a {
                width: auto;
            }
        }
    }
    @media #{breakpoint(medium up)}{
        li {
            text-align: left;
            a {
                float: left;

                &:hover {
                    background: transparent;
                    color: $blue-light;
                }
            }


            &.active {
                > a {
                    background: transparent;
                    color: $blue-light;
                }
            }
        }
    }

    @include breakpoint(767px down){
      li {
          width: 100%;
          a {
              width: 100%;
              text-align: center;
            }
          }
    }
}

.social_menu{
    text-align: right;
    margin: rem-calc(12 0 -18);
    position: relative;
    z-index: 1;
    .social{
        display: inline-block;
        margin:0px;
        li{
            display: inline-block;
            a{
                line-height: rem-calc(22);
                font-size: rem-calc(12);
                color: $gray;
                position: relative;
                padding: rem-calc(0 16);
                vertical-align: middle;
                &:hover,&:focus{
                    color: $navy-blue;
                }
            }

        }
    }
}
