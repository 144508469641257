 .cookieInfo{
     position: fixed;
     bottom: 0;
     left: 0;
     width: 100%;
     padding: 15px 45px;
     background-color: rgba(0,0,0,0.75) !important;
     z-index: 999999;
    //  .btn{
    //      &:focus,&:hover{
    //        &:before{
    //          transform:none !important;
    //        }
    //      }
    //  }
    // .btn:hover:before, .btn:focus:before {
    //   transform: scale3d(0.6, 0.6, 1) !important;
    // }
    .btn:focus{
      background-color: #fff !important;
    }
    button{
      position: absolute;
      width: 26px;
      height: 26px;
      right: -30px;
      top:-5px;
      background-color: #fff !important;
      text-align: center;
      cursor: pointer;
      border-radius: 50%;
      padding: 0 !important;
      text-indent: -9999px;
      &:hover{
        background-color: #fff !important;
      }
      // &:focus,&:hover{
      //   &:before{
      //     transform:none !important;
      //   }
      // }
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 12px;
        display: block;
        background-color: #000;
        top: 7px;
        left: 12px;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity:1 !important;
        border: none !important;
        z-index:1;

      }

      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 12px;
        display: block;
        background-color: #000;
        top: 7px;
        left: 12px;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        }
    }
    > div {
        padding: 0 15px;
        position: relative;
        max-width: 100%;
        width: 100%;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    .cookieBtn{
        height: unset;
        line-height: unset;
        font-size: 12px;
        margin-right: 10px;
        margin-top: 5px;
        padding: 5px 14px;
        &:hover{
            cursor: pointer;
        }
    }
 }
