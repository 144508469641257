.widget {
    .news-list {
        margin: 0;
        .news {
            margin: rem-calc(0 0 20);
            padding: rem-calc(0 0 20);
            overflow: hidden;
            display: block;
            clear: both;
            @media screen and (max-width: 736px){
              margin: 0;
              padding: 0;
            }
            .title {
                padding-bottom: rem-calc(2);
            }

            .date {
                margin: 0;
                padding: rem-calc(0 0 5);
            }

            p {
                overflow: hidden;
                margin: 0;
                padding: 0 0 rem-calc(5);
                text-align: justify;
            }

            &:last-child {
                border: 0;
            }
        }

        + .read-more {
            margin-top: rem-calc(5);
            font-size: 14px;
            height: 36px;
            padding: 0 14px;
            line-height: 36px;
            display: inline-block;
            background-color: #bf1818;
            color: #fff;
        }
        /* home */
        .row {
            ul{
                margin: 0;
            }
            .columns {
                .news{
                    .title{
                        margin: rem-calc(0 0 22);
                        text-align: left;
                        a{
                            font-size: rem-calc(24);
                            line-height: rem-calc(30);
                            display: block;
                            color: $navy-blue;
                            font-weight: 600;
                            margin: 0px;
                            &:hover{
                                color:$blue-primary;
                            }
                        }
                    }
                    .date{
                        float: left;
                        font-size: rem-calc(16);
                        text-align: left;
                        display: block;
                        width: 100%;
                        text-transform: lowercase;
                        font-weight: bold;
                    }
                    .description{
                        font-size: rem-calc(14);
                        overflow: hidden;
                        display: block;
                        font-family: $arial-font;
                        clear: both;
                    }
                    @media #{breakpoint(small down)}{
                         margin-bottom: 25px;
                        .title{
                            margin: rem-calc(0 0 5);
                            a{
                                font-size: rem-calc(16);
                                line-height: rem-calc(23);
                            }
                        }
                        .date{
                            margin-bottom: rem-calc(9);
                            font-size: em-calc(12);
                        }

                    }
                }
            }
        }
        /* ^ home ^ */
    }
    #side-right &{
        .news-list{
            .news{
                .title{
                    font-size: rem-calc(17);
                    font-weight: bold;
                    color:$blue-dark;
                    line-height: rem-calc(24);
                }
                .image{
                    margin: 0px;
                }
                @media #{breakpoint(medium down)}
                {
                    width: calc(50% - 3px);
                    display: inline-block;
                    padding: rem-calc(0 20px 20px);
                    vertical-align: top;
                    text-align:center;
                    .image{
                        display: inline-block;
                        text-align: center;
                        margin: rem-calc(15px 0px);
                    }
                    .date{
                        text-align: left;
                    }
                }
                @media #{breakpoint(small down)}
                {
                    width:100%;
                    padding:rem-calc(0 10px 20px);
                }
            }
        }
    }
}
