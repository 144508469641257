#contact {
    #subpage-main-paralax {
        .paralax-content {
            h1 {
                margin-bottom: 0px;
                padding: 0px;
                border-bottom: none;
                font-size: 42px;
                line-height: 1.25em;
                text-transform: uppercase;
            }

            h2 {
                font-size: 32px;
                line-height: 1.9em;
                font-weight: 500;
            }
        }
    }

    .inner {
        margin: 0 auto;
        max-width: 990px;
        overflow: hidden;
        width: 92%;
    }

    .contact-section {
        @include breakpoint(850px down) {
            margin-top: 300px;
        }

        @include breakpoint(750px down) {
            margin-top: 250px;
        }

        @include breakpoint(650px down) {
            margin-top: 180px;
        }

        @include breakpoint(540px down) {
            margin-top: 0px;
        }
    }

    .section-adresses {
        padding-top: 120px;
        position: relative; // z-index: 9999;

        @include breakpoint(850px down) {
            padding-top: 190px;
        }

        @include breakpoint(540px down) {
            padding-top: 90px;
        }

        .widget-map-address {
            .contact-info {
                color: $lightblack;

                &.bordered {
                    border-bottom: 2px solid $lightblack; // padding-bottom: 30px;
                    transition: visibility 0s, opacity 0.5s ease-in-out;
                }

                h2 {
                    font-size: 26px;
                    font-weight: 700;
                    margin: 0px;
                    line-height: 36px;
                }

                div * {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                    margin-top: 20px;
                    padding: 0;
                }

                p {
                    margin: 0;
                    color: $lightblack;
                    line-height: 20px;
                    font-size: 16px;
                    font-style: normal;
                }

                a {
                    color: $lightblack;
                }
            }

            .address {
                color: $lightblack;
                transition: visibility 0s, opacity 0.5s ease-in-out;

                h1,
                h2,
                h3 {
                    font-size: 24px;
                    margin: 0; // margin-top: 30px;
                    font-weight: 700;
                }

                p {
                    color: $lightblack;
                    font-size: 18px;
                    font-style: normal;
                }
            }
        }
    }

    .section-form-popup {
        margin-top: 15px;
    }

    .section-krs-info {
        .widget .content {
            h2 {
                color: $red-color-site;
                font-size: 35px;
                margin-bottom: 80px;
                margin-top: 100px;
            }

            p {
                color: $lightblack;
                font-size: 14px;
                line-height: 1.7em;
            }
        }
    }

    .map-navigation {
        position: absolute;
        right: 0px;
        max-width: 60%;
        height: auto;

        @include breakpoint(850px down) {
            max-width: 100%;
        }

        @include breakpoint(540px down) {
            position: static;
            padding-left: 20px;
        }

        .mobile-header {
            display: none;
            margin-bottom: 20px;
            color: $lightblack;
            float: left;
            font-size: 20px;
            font-weight: 700;
            width: 100%;
            margin-top: 40px;
            @include clearfix;

            @include breakpoint(540px down) {
                display: block;
            }
        }

        .china-header,
        .mexico-header,
        .finland-header {
            position: absolute;
            color: $gray-medium;
            font-size: 110%;
            font-weight: 700;

            @include breakpoint(540px down) {
                display: none;
            }
        }

        .china-header {
            top: 17.3%;
            left: 69.3%;
        }

        .mexico-header {
            top: 63%;
            right: 69%;
        }

        .map-layer {
            height: 100%;
            width: 100%;

            @include breakpoint(540px down) {
                display: none;
            }
        }

        .map-navigation-buttons {
            width: 100%;

            .map-nav-item {
                position: absolute;
                top: 0%;
                left: 0%;
                width: 42px;

                @include breakpoint(1024px down) {
                    width: 25px;
                }

                @include breakpoint(540px down) {
                    position: static;
                    width: 50%;
                    float: left;
                    transition: color 0.25s ease-out 0s;
                }

                svg {
                    display: none;
                    fill: $red-color-site;
                    transition: fill 0.25s ease-out 0s;
                    height: 33px;

                    @include breakpoint(1280 down) {
                        height: 24px;
                    }

                    @include breakpoint(850px down) {
                        height: 30px;
                    }

                    @include breakpoint(680px down) {
                        height: 24px;
                    }

                    @include breakpoint(540px down) {
                        display: none !important;
                    }
                }

                span.country-name {
                    display: none;
                    color: #bf1818;
                    font-size: 18px;
                    font-weight: 700;

                    @include breakpoint(540px down) {
                        display: block;
                    }
                }

                &[data-country-name="poland"] {
                    top: 35.8%;
                    left: 51%;

                    svg {
                        fill: #1a1a1a;
                    }
                }

                &[data-country-name="germany"] {
                    top: 32%;
                    left: 29.2%;
                }

                &[data-country-name="france"] {
                    top: 45%;
                    left: 28%;
                }

                &[data-country-name="ukraine"] {
                    top: 40%;
                    left: 61%;
                }

                &[data-country-name="belarus"] {
                    top: 29.5%;
                    left: 55.2%;
                }

                &[data-country-name="russia"] {
                    left: 63%;
                    top: 24%;
                }

                &[data-country-name="russia2"] {
                    left: 73%;
                    top: 8%;
                }

                &[data-country-name="china"] {
                    top: 27%;
                    left: 84.3%;
                }

                &[data-country-name="mexico"] {
                    top: 73.3%;
                    left: 19.5%;
                }

                &[data-country-name="finland"] {
                    top: 0.5%;
                    left: 52%;
                }

                &.active-marker[data-country-name="poland"] svg,
                &.active-marker svg {
                    fill: $white;
                }
            }

            a.active-marker {
                color: $black;

                span.country-name {
                    color: $black !important;
                }
            }
        }
    }

    .map-section {
        margin-top: 90px;

        #map {
            height: 768px;
        }
    }

    #odprawy-celne {
        .paralax-content>p {
            margin-bottom: 45px;
            font-size: 18px;
            line-height: 1.5em;
        }

        .custom-left {
            float: left;
            width: 60%;

            @include breakpoint(736px down) {
                width: 100%;
            }

            p {
                font-size: 18px;
                margin-bottom: 0px;

                @include breakpoint(736px down) {
                    font-size: 16px;
                }
            }

            ul {
                margin-top: 10px;

                li {
                    list-style-type: disc;
                    margin-bottom: 10px !important;
                    font-size: 18px !important;
                    float: left;
                    width: 100%;
                    line-height: 1.5 !important;
                }
            }
        }

        .custom-right {
            float: right;
            width: 35%;

            @include breakpoint(736px down) {
                width: 100%;
                float: left;
            }

            p {
                margin-bottom: 45px;
                font-size: 18px;

                @include breakpoint(736px down) {
                    font-size: 16px;
                }
            }

            address {
                font-size: 18px;
                line-height: 1.5em;
                font-style: normal;
            }
        }
    }
}