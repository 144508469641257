.widget-quotations-bg .paralax-content-columns{
    max-width: rem-calc(990px);
    margin: 0 auto;
    overflow: hidden;
    float: none !important;
    width: 92%;
    padding-top: 20px !important;
    @include breakpoint(736px down)
    {
        width: 92%;
        padding-top: 0px !important;
    }
    .paralax-svg-icon {
        width: rem-calc(50px);
        height: rem-calc(50px);
        float: left;
        margin-left: 0;
        margin-top: 0;
        position: relative;
        top: rem-calc(80px);
        @include breakpoint(736px down)
        {
            width: rem-calc(40px);
            top: rem-calc(75px);
        }
        .cls-1{
            fill: none;
        }
    }
}
#quotations {

    .inner {
        padding-top: rem-calc(20px);
        width: 100%;
        max-width: rem-calc(990px);
        min-height: rem-calc(500px);
        margin: 0 auto;
        overflow: hidden;
    }
    h2 {
        color: #1e252b;
        border-bottom: rem-calc(2px) solid #1e252b;
        font-size: rem-calc(36px);
        padding-bottom: rem-calc(14px);
        margin-bottom: rem-calc(70px);
        padding-top: rem-calc(10px);
        padding-left: rem-calc(65px);
        @include breakpoint(736px down)
        {
            margin-bottom: rem-calc(20px);
            font-size: rem-calc(21px);
            padding-left: rem-calc(55px);
        }
    }
    p {
        color: #1e252b;
        margin-bottom: 0;
    }
    .quotations-inner {
        width: 75%;
        margin: 0 auto;

        @include breakpoint(736px down)
        {
            width: 100%;
        }

        .quotations-date-container {
            overflow: hidden;
            padding-top: rem-calc(13px);

            .quotations-date {
                font-size: rem-calc(13px);
                float: left;
                margin-bottom: rem-calc(50px);

                span {
                    font-weight: 700;
                }
            }
            #datepicker {
                display: none;
            }
            .ui-datepicker-trigger {
                font-size: rem-calc(13px);
                color: #1e252b;
                position: relative;
                float: right;

                &:before {
                    content: '';
                    width: rem-calc(40px);
                    background: url("../../images/calendar.png") no-repeat;
                    background-size: cover;
                    background-position: center;
                    position: absolute;
                    left: rem-calc(-50px);
                    height: rem-calc(40px);
                    top: rem-calc(-13px);
                }
            }
        }
        .inner-grid {
            overflow: hidden;

            div {
                width: 33.33%;
                float: left;
                margin-bottom: rem-calc(90px);
                border-left: rem-calc(6px) solid #bf1818;
                padding-left: rem-calc(10px);
                @include breakpoint(736px down)
                {
                    width: 50%;
                    margin-bottom: 40px;
                }
                .quotations-description {
                    font-size: rem-calc(14px);
                }
                .quotations-value {
                    font-size: rem-calc(20px);
                    font-weight: 700;
                }
            }
        }
        .not-in-archive {
            color: #bf1818;
            text-align: center;
            font-weight: 700;
            display: none;
        }
        .quotations-warning {
            color: #999999;
            text-align: center;
            font-size: rem-calc(14px);
            font-weight: 700;
            margin-bottom: rem-calc(40px);
        }
    }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: none;
  color: #1e252b;
}

.ui-state-default:hover,
.ui-widget-content .ui-state-default:hover,
.ui-widget-header .ui-state-default:hover {
  background: #bf1818;
  color: #fff;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border-color: #1e252b;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: #bf1818;
  color: #fff;
}

.ui-widget-header {
  background: #bf1818;
}

@media screen and (max-width: 736px)
{
    #quotations .quotations-inner {
        width: 100%;
    }
}
