
.widget-corporate-governance{
  .paralax-content-columns{
    padding: 152px 0 140px 0;
    h1{    
      margin-bottom: 60px;
      @include breakpoint(736px down){
          margin-bottom: 54px !important;
      }
    }
  }
    .paralax-content{
        padding: 0;
        ul {
            float:left;
            margin-left:0;
            column-count: 2;
            -moz-column-count:2;
            -webkit-column-count: 2;          
            @include breakpoint(736px down){
                column-count: 1;
                -moz-column-count: 1;
                -webkit-column-count: 1;
            }
            li{
                display: block;
                width: 100%;            
                margin-bottom:20px !important;
                margin-top: -1px;
                float:none;
                a{
                    display: block;                    
                    padding-left: 50px;
                    padding-right: 30px;
                    margin-bottom: 10px;
                    position: relative;
                    font-size: 18px;
                    color: $white;
                    line-height: 1.8em;
                    @include breakpoint(736px down){
                          width: 100%;
                          font-size: 14px;
                    }
                    strong{
                        display: block;
                        font-size: 16px;
                        font-weight: 700;
                    }
                    // &:hover:before{
                    //     text-decoration: none;
                    // }
                    &:before{
                        background-color: $red-color-site;
                        color: #fff;
                        content: "\f105";
                        font-family: fontawesome;
                        font-size: 20px;
                        height: 30px;
                        left: 0;
                        line-height: 30px;
                        position: absolute;
                        text-align: center;
                        width: 30px; 
                        text-decoration:underline;
                        display:inline-block;                     
                    }
                    &:before,
                    &:hover:before {text-decoration:none !important;}
                }
            }
        }
    }
}

.modal-cg{
    p{
        font-size: 18px;
        line-height: 1.7em;
        margin: 0;
    }
    &.modal-cg-files{
        ul{
            max-width: 850px;
            li{
                display: block;
                width: 275px;
                float: left;
                a{
                    display: block;
                    font-size: 12px;
                    padding-left: 50px;
                    padding-right: 30px;
                    margin-bottom: 25px;
                    position: relative;
                    font-size: 18px;
                    color: $white;
                    @include breakpoint(small down){
                        width: 100%;
                    };
                    strong{
                        display: block;
                        font-size: 16px;
                        font-weight: 700;
                    }
                    &::before{
                        background-color: $red-color-site;
                        color: #fff;
                        content: "\f105";
                        font-family: fontawesome;
                        font-size: 20px;
                        height: 30px;
                        left: 0;
                        line-height: 30px;
                        position: absolute;
                        text-align: center;
                        top: 0;
                        width: 30px;
                    }
                }
            }
        }
    }
    &.modal-cg-biography{
        .biography-title{
            font-size: 36px;
            line-height: 1em;
            margin-top: 28px;
        }
        .biography-positions{
            min-height: 50px;
            p{
                font-size: 20px;
                line-height: 1.2em;
                margin: 0;
            }
        }     
    }
    &.modal-cg-biography-single{
        .reveal-header{
            margin-bottom: 15px;
        }
        .biography-description{
            h2{
                color: $white;
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 15px;
            }
            h3{
                font-size: 20px;
                font-weight: 700;
                margin: 20px 0 5px;
            }
            ul{
                padding-left: 0px;
                li{
                    position: relative;
                    padding-left: 20px;
                    &::after{
                        content: "";
                        display: block;
                        height: 2px;
                        width: 2px;
                        font-size: 20px;
                        position: relative;
                        top: -35px;
                        left: -40px;
                    }
                }
            }
        }   
    }
}
