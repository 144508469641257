
.header-lang-switcher{

    &.desktop{
        right: 0;
        float: right;
        position: absolute;
        width: 80px;
        top: -1px;
        transition: 0.2s ease-in-out 0s;
        overflow: hidden;
        height: 70px;
        @include breakpoint(1024px down){
            display: none
        }

        .lang-switcher{
            height: 100%;
            margin: 0 auto;
            padding-top: 20px;
            padding-bottom: 10px;
            background-color: transparent;
            -moz-transition: background-color 0.25s ease-in-out;
            -o-transition: background-color 0.25s ease-in-out;
            -webkit-transition: background-color 0.25s ease-in-out;
            transition: background-color 0.25s ease-in-out;
            .lang-current{
                color: $white;
                font-size: 13px;
                height: 70px;
                padding-top: 26px;

            }
            .lang-menu{
                opacity: 0;
                width: 100%;
                padding: 0;
                margin: 0;
                background-color: rgba(30, 37, 43, 0.8);
                border-top: 1px solid rgba(255, 255, 255, 0.3);
                transition: 0.25s ease-in-out 0s;
                padding: 15px;
                li{
                    display: block;
                    a{
                        padding-bottom: 2px;
                        display: block;
                        text-align: center;
                        font-size: 13px;
                        color: $white;
                        &:active, &:focus, &:hover{
                            color: $white;
                        }
                    }                
                }
            }
            &:hover{
                .lang-menu{
                    opacity: 1;
                }
            }
        }
      &:hover{
        overflow: visible;
        height: auto;
      }
    }

    &.mobile{

        display: none;
    }
}

.fixedMenu .header-lang-switcher.desktop{
    top: -22px;
    .lang-switcher:hover .lang-current{
      background-color: #161819
    }
}

.main-menu li.lang{
    @include breakpoint(large up){
        display: none;
    }
}
