.breadcrumbs {
    background-color:$white;
    ol{
        list-style: none;
        margin: 0;
        padding: 22px 0;
        li{
            margin-right: 10px;
            display: inline-block;
            a,span{
                font-size: 12px;
                font-family: $arial-font;
                color:$gray;
            }
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
            .icon{
                display: inline-block;
                vertical-align: middle;
                color:$gray;
                margin-right: 10px;
            }
            &:last-of-type{
                span:not(.icon){
                    color:$navy-blue;
                }
            }
        }
    }
}