.search-module {
    .suggest {
        margin: rem-calc(0 0 20);
        padding: rem-calc(0 0 20);
        @include border(1, 'bottom');
    }
    input[type='text']{
        height: 50px;
        display: inline-block;
        width: calc(100% - 99px);
        vertical-align: top;
        margin-right: -4px;
    }
    .total-results {
        @extend .suggest;
    }
    .results {
        margin: 0;
        padding: 0;
        list-style: none;

        .result {
            margin: rem-calc(0 0 20);
            padding: rem-calc(0 0 20);
            @include border(1, 'bottom');
            overflow: hidden;
            &:last-child {
                margin: 0;
                padding: 0;
                border: 0;
            }
            .title{
                font-weight: bold;
                font-size: 20px;
            }

            .sources {
                padding: rem-calc(0 0 5);

                .label {
                    float: left;
                    padding: rem-calc(0 10 0 0);
                }
                .links {
                    overflow: hidden;
                }
            }

            .description{
                margin-bottom: 10px;
            }
        }
    }
    @media #{breakpoint(small down)}{
        padding: 0 10px;
    }
}

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: rem-calc(160);
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: $white;
    @include border(1, '', $gray);

    .ui-menu-item {
        display: block;
        padding: rem-calc(8);
        clear: both;
        font-weight: normal;
        line-height: rem-calc(18);
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            color: $white;
            background-color: $secondary-color;
        }
    }
}