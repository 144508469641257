
//=========COLORS=============//

$jet:red;
$vapor:#FFF;
$blue-light:#FFF;
$blue-primary:#00608a;
$blue-dark:#0e1824;
$gray-dark:#FFF;
$gray:#969696;
$black:#000;
$gray-light:#f2f2f2;
$white:#FFF;
$smoke:#f6f6f6;
$lightblack:#1e252b;
$navy-blue:#121e2c;
$navy-blue_light:#35404b;
$red:red;
$green:#71be00;
$yellow:#FFF000;
$border-default-color:#dcdcdc;
$edito:#e85f0e;
$redmenu:#bf1818;
$facebook-color:#3765a3;
$google-color:#dc4b3e;
$twitter-color:#00aced;
$linkedin-color:#0077b5;
$rd-h1-site:#1e252b;
$rd-h1-border-color:#1e252b;
$rd-p-color-under-h1:#bf1818;
$rd-h1-border-color-red:#bf1818;
$rd-h2-color:#bf1818;
$red-color-site:#bf1818;
$home-color-text:#333333;
$info-color:#23b8b0;
$success-color:#72b55f;
$warning-color:#f58229;
$error-color:#d82b2b;
$news-color-date:#8a8989;
$btn-color:#E84747;
$gray-medium: #868686;
$grayblack: #4B4B4B;
$btn-hover-color: #E84747;
$svg-hover-color: #F1EFEF;
$input-dark-bg: #141a1f;
$input-dark-color: #9C9D9F;
//============================//

$theme-dir:'/themes/stomil/';
$font-dir:$theme-dir+'fonts/';
$fa-font-path:$theme-dir+'fonts/';

$main-font:'Roboto', sans-serif;
$arial-font: Arial, Helvetica, sans-serif;


$border-default-width:1px;
$border-default-style:solid;
$burger-height:1px;
$burger-width:1px;
$burger-spacing:1px;
