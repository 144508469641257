
  .widget {
      .rotator-widget{
          margin: 0;
          padding: 0;
          border: 0;
          max-height: rem-calc(576);
          overflow: hidden;
          position:relative;
          .owl-item{
              .item {
                  width: 100%;
                  height: 576px;
                  .text-container{
                      overflow: hidden;
                      margin: 0 auto;
                      padding: rem-calc(160) calc(#{$global-width} / 2) 0 0;
                      width: $global-width;
                      left: -200px;
                      opacity: 0;
                      position:relative;
                      background: transparent!important;
                      @include duration(1s);
                      .slide-title {
                          font-size: rem-calc(36);
                          line-height: rem-calc(42);
                          font-weight: bold;
                          margin: rem-calc(10 26 19 0);
                          text-align: left;
                          background: transparent!important;
                          .more {
                              color:$navy-blue;
                              &:hover{
                                  color:$blue-primary;
                              }
                          }
                      }

                      .desc {
                          font-size: rem-calc(18);
                          margin-right: 40%;
                      }
                  }
              }
              &.active{
                  .item{
                      .text-container{
                          left: 0px;
                          opacity: 1;
                      }
                  }
              }
          }

          .owl-dots {
              position: absolute;
              width: $global-width;
              text-align: left;
              bottom: rem-calc(94);
              height: 0;
              padding:0 calc((100% - #{$global-width}) / 2);
              z-index:9;
              .owl-dot{
                  display: inline-block;
                  padding: rem-calc(6 10 6 6);
                  margin: rem-calc(0 3);
                  vertical-align: middle;
                  position: relative;
                  @include duration();
                  a:not(.wcag-tooltip),span{
                      display: block;
                      width: rem-calc(9);
                      height: rem-calc(9);
                      background-color: $navy-blue;
                      border: rem-calc(2) solid $navy-blue;
                      border-radius: rem-calc(100);
                      margin-left: rem-calc(-4.5);
                      position: relative;
                      left: rem-calc(4.5);
                      @include duration();
                  }
                  a.wcag-tooltip{
                      left: 50%;
                      @include transform(translateX(-50%));
                      margin-left: rem-calc(2px);
                      top: rem-calc(27px);
                      white-space: nowrap;
                      padding: rem-calc(7px 8px);
                      display: none;
                  }
              }

              .owl-dot.active{
                  span,a:not(.wcag-tooltip){
                      width: rem-calc(13px);
                      height: rem-calc(13px);
                      background-color: transparent;
                      margin-left: rem-calc(-6.5px);
                  }
                  a.wcag-tooltip{
                      display: block;
                  }
              }
          }

          .owl-nav {
              display: none;
              .owl-prev{
              }
              .owl-next{
              }
          }
          .customers{
              .owl-item{

                  .item {
                      height: auto;
                      img{
                          width: auto;
                          height: auto;
                      }
                }
              }
          }

          @media #{breakpoint(medium down)}{
              .owl-item{
                  .item{
                      overflow: hidden;
                      .text-container{
                          padding-left: rem-calc(20);
                      }
                  }
              }
          }
          @media #{breakpoint(small down)}{
              .owl-item{
                  .item{
                      height: rem-calc(450);
                      margin-bottom: rem-calc(80);
                      .text-container{
                          position: absolute;
                          bottom: rem-calc(25);
                          box-sizing: border-box;
                          width: 100%;
                          padding: rem-calc(0 10);
                          .slide-title{
                              margin: 0;
                              text-align: center;
                              font-size: rem-calc(24p);
                          }
                          .desc{
                              display: none;
                          }
                      }
                  }
              }
              .owl-dots{
                  bottom: rem-calc(22);
                  margin: 0;
                  padding: 0px;
                  width: 100%;
                  text-align: center;
              }
          }
      }
  }
