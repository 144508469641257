.pagination-centered {
    // overflow: hidden;
    margin-bottom: 20px;
}

ul.pagination {
    // margin: rem-calc(30 0 0);
    // padding: rem-calc(0 0 1);
    height: auto;
    text-align: center;

    li {
        // padding: rem-calc(0 2);
        // margin: rem-calc(10 0 0);
        display: inline-block;

        a {
            // width: rem-calc(35);
            // height: rem-calc(35);
            font-size: rem-calc(13);
            // line-height: rem-calc(35);
            text-align: center;
            margin: 0;
            padding: 0;
            border-radius: 0;

            &:hover {
                box-shadow: none;
            }

            &.fa {
                position: relative;
                // top: rem-calc(1);
            }
        }
        &.first,&.last{
          display: none;
            a, span{
                font-size: 33px;
                // width: 40px;
                display: inline-block;
                // height: 40px;
                // line-height: 40px;
                vertical-align: top;
				        margin:0px;
            }
            &.unavailable{
                a{
                    color: $white;
                    @include background($blue-dark,0.25);
                    // box-shadow: 0 0 6px rgba(0, 0, 0, 0.27) inset!important;
                    cursor:context-menu;
                    &:focus,&:active{
                        outline: 0px;
                    }
                }
            }

        }
        &.first{
            float:left;
        }
        &.last{
            float: right;
        }

        &.next,&.prev{
            font-size: 10px;
            a{
                font-size: 16px;
                width: 30px;
                height: 30px;
                // line-height: 40px;
                // border: 1px solid $border-default-color;
                display: inline-block;
                vertical-align: inherit;
                color:#8a8989;
            }
            &.unavailable{
                a{
                    color:$border-default-color;
                    cursor:context-menu;
                }
            }
        }

        &.current {
            a {
                box-shadow: none;
                font-weight: normal;


                &:focus {
                    // box-shadow: inset 0 0 rem-calc(4) 0 $black !important;
                }
            }
        }

        &.element{
            display: inline-block;
            vertical-align: middle;
            a{
                font-size: 16px;
                color: #8a8989;
                padding: 0 7px;
                &:hover,&:focus{
                    text-decoration: none;
                    overflow: 0px;
                }
            }
            &.current{
                a{
                    color:$white;
                    width:20px;
                    height: 20px;
                    background-color:#bf1818;
                    cursor:context-menu;
                    &:hover,&:focus{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
