a.contrast {
    vertical-align: bottom;
    font-size: 23px;
    line-height: 26px;
    margin: rem-calc(0 0 0 15);
    color:$blue-dark;
    &:hover,&:focus{
        color:$blue-primary;
    }
    &:focus{
        outline: 1px solid $blue-dark;
    }

}

@media #{breakpoint(medium down)}{
    .contrast {
        padding: rem-calc(16 0 0);
    }
}

.contrast-black-yellow {
    background-color: $black !important;
    color: $yellow !important;
    border-color: $yellow !important;

    * {
        background-color: $black !important;
        color: $yellow !important;
        border-color: $yellow !important;
    }

    *:before,
    *:after {
        color: $yellow !important;
    }

    :focus {
        box-shadow: inset 0 0 rem-calc(4) 0 $yellow !important;
    }

    ::selection {
        background-color: $yellow !important;
        color: $black !important;
    }

    a:hover,
    button:hover {
        color: $yellow !important;
        text-decoration: underline !important;
    }

    .button {
        background-color: $yellow !important;
        color: $black !important;

        .fa {
            &:before {
                background-color: $yellow !important;
                color: $black !important;
            }
        }
    }

    .contrast {
        li {
            a {
                border-color: $white !important;
            }

            &:nth-child(1) {
                height: auto;
                width: auto;
                position: static !important;
                clip: auto;
            }
            &:nth-child(2) {
            }
        }
    }

    .top-bar {		
        .logo {
            padding: rem-calc(7px 0 9px)!important;
            background: url($theme-dir + 'images/logo-yellow.png') no-repeat 0 rem-calc(17);

            img {
                visibility: hidden;
            }
        }
        &.fixedMenu{
            .logo{
                background-position: 0px 15px;
                transform: scale(0.9);
                width: 205px;
            }
        }

        .toggle-topbar {
            &.menu-icon {
                a {
                    background-color: $yellow !important;

                    span {
                        &::after {
                            box-shadow: 0 0 0 0.166667rem $black, 0 0.833333rem 0 0.166667rem $black, 0 1.66667rem 0 0.166667rem $black;
                        }
                    }

                    &:hover {
                        background-color: $black !important;
                        @include border(3, '', $yellow);

                        span {
                            &::after {
                                box-shadow: 3px 0 0 0.166667rem $yellow, 3px 0.833333rem 0 0.166667rem $yellow, 3px 1.66667rem 0 0.166667rem $yellow;
                            }
                        }
                    }
                }
            }
        }

        .top-bar-section {
            .has-dropdown {
                > a {
                    &:after {
                        border-color: transparent transparent transparent $yellow !important;
                    }

                    &:hover {
                        &:after {
                            background-color: $yellow !important;
                            border-color: transparent transparent transparent $black !important;
                        }
                    }
                }
            }

            ul {
                li {
                    a {
                        &:hover {
                            background-color: $yellow !important;
                            color: $black !important;
                        }
                    }

                    &.active:not(.has-form) {
                        > a {
                            background-color: $yellow !important;
                            color: $black !important;
                        }
                    }
                }
            }
        }
    }

    .left-off-canvas-toggle,
    .right-off-canvas-toggle {
        background-color: $yellow !important;

        span:after {
            box-shadow: 0 0.833333rem 0 0 $black, 0 1.66667rem 0 0 $black !important;
        }

        &:hover {
            background-color: $black !important;
            @include border(3, '', $yellow);

            span:after {
                background-color: $yellow !important;
                box-shadow: 0 0.833333rem 0 0 $yellow, 0 1.66667rem 0 0 $yellow !important;
            }
        }
    }

    .exit-off-canvas {
        background-color: transparent !important;
    }

    .side-nav {
        li {
            a:not(.button) {
                &:hover {
                    background-color: $yellow !important;
                    color: $black !important;
                }
            }

            &.active {
                > a {
                    background-color: $yellow !important;
                    color: $black !important;
                }
            }
        }
    }

    .lightbox {
        .lb-nav {
            background-color: transparent !important;

            a.lb-prev,
            a.lb-next {
                background-color: transparent !important;
            }
        }
    }

    ul.pagination {
        li {
            a {
                box-shadow: none !important;
                @include border(1, '', $yellow);

                &:hover {
                    background-color: $yellow !important;
                    color: $black !important;
                    text-decoration: none !important;

                    &:before {
                        color: $black !important;
                    }
                }
            }

            &.current {
                a {
                    background-color: $yellow !important;
                    color: $black !important;
                    text-decoration: none !important;
                }
            }
        }
    }

    input,
    select,
    textarea {
        color: $yellow !important;
        background-color: $black !important;
    }

    label input[type=checkbox] + span:before, 
    label input[type=checkbox] + span:after,
    label input[type=radio] + span:before{
        background-color: $yellow;
    }

    *::-webkit-input-placeholder {
        color: $yellow !important;
        background-color: $black !important;
    }
    *:-moz-placeholder {
        color: $yellow !important;
        background-color: $black !important;
    }
    *::-moz-placeholder {
        color: $yellow !important;
        background-color: $black !important;
    }
    *:-ms-input-placeholder {
        color: $yellow !important;
        background-color: $black !important;
    }

    .alert-box {
        color: $black !important;
        background-color: $yellow !important;

        * {
            color: $black !important;
            background-color: $yellow !important;
        }

        i.fa:before {
            color: $black !important;
        }

        a.close {
            opacity: 1;
            color: $black !important;
            background-color: $yellow !important;
        }
    }

    .widget {
        &.login-widget {
            .dropdown{ 
                .user{
                    &:before{
                        border-top-color: $yellow;
                    }
                }
                .submenu .is-submenu-item:not(.welcome){
                    border-top: 1px solid $black!important;
                    a,button{
                        background-color: $yellow!important;
                        color: $black!important;
                        &:hover{
                            background-color: $black!important;
                            color: $yellow!important;
                            border:0px;
                        }
                    }
                }
            }
            &.logged {
                .button {
                    background: transparent !important;
                    color: $yellow !important;
                    border: 0 !important;
                }
            }
        }
    }

    .progress {
        .meter {
            background-color: $yellow !important;
        }
    }

    .btn{
        background: $yellow!important;
        color:$black!important;
        &:before{
            border-color:$yellow!important;
        }
        &:hover,&:focus{
            background: $black!important;
            color:$yellow!important;
        }
    }

    .faq-module .list li a:before{
        background: $black;
        border-color: $yellow;
    }
    .login-modal{
        form{
            > .row{
                .registered-users{
                    .input-section{
                        @include autofill-color($yellow);
                        input{
                            border:1px solid $yellow;
                        }
                        span{
                            background-color: transparent!important;
                        }
                    }
                }
            }
        }
    }
}

@media #{breakpoint(medium down)} {
    .contrast-black-yellow {
        .top-bar {
            .title-area {
                .logo {
                    background-position: rem-calc(20 1);
                    background-size: rem-calc(144) auto;
                }
            }
        }
    }
}
