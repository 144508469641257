.content-module {
    .title {
        color: $secondary-color;
        font-size: rem-calc(18);
        line-height: rem-calc(22);
        font-weight: normal;
        margin: 0;
        padding: rem-calc(0 0 10);
    }

    .content {
        overflow: hidden;
        margin: 0;
        padding: 0;

        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}