.widget {
    .gallery {
        .read-more {
            clear: both;
            float: right;
        }
    }
    [class*='side-right']{
        .item{
            text-align: center;
        }
    }
}