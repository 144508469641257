.widget {
    .content {
        p:last-child {
            margin: 0;
            padding: 0;
        }
    }
}
.section-5{
    .widget{
        .content{
            color:$smoke;
            > div.kontakt{
                display: inline-block;
                width: calc(50% - 3px); 
                font-family: $arial-font;
                font-size: rem-calc(14);
            }
        }
    }
}