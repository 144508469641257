.files{
    
    margin: 0;
    .file{
        display: inline-block;
        width: calc(33% - 3px);
        color:$gray;
        font-family: $main-font;
        margin-bottom: rem-calc(20px);
        vertical-align: top;
        font-size: rem-calc(17px);
        i{
            margin-right: rem-calc(11px);
            display: inline-block;
            vertical-align: text-top;
            font-size: rem-calc(40);
            &.file-pdf{

            }
            &.file-png{

            }
            &.file-jpg,
            &.file-jpeg{

            }
            &.file-doc,
            &.file-docx{

            }
            &.file-txt,
            &.file-plain{

            }
            &.file-zip{

            }
            &.file-ppt,
            &.file-pptx,
            &.file-octet-stream{

            }
            &.file-xls,
            &.file-xlsx{

            }
            &.file-eps{

            }
        }
        span{
            display: inline-block;
            position: relative;
            top: 3px;
            width: calc(100% - 60px);
            vertical-align: top;
        }
        &:hover{
              color:$blue-primary;
        }
    }
    &.horizontal{
        margin: 0px;
        width: 100%;
    }
    &.vertical{
        .file{
            display: block;
            width: 100%;
        }
    }
    @media #{breakpoint(small down)}{
        .file{
            width: calc(50% - 3px);
        }
    }
    @media #{breakpoint(400 down)}{
        .file{
            width: 100%;
        }
    }
}
// ## Ustawienie defaultowych rodzajów icon ## //
[class*="edito_file"]:before{
    content: "\e91e";
}
[class*="edito-media"]:before{
    content: "\e918";
}
[class*="edito-video"]:before{
    content: "\e917";
}
[class*="edito-audio"]:before{
    content: "\e919";
}
