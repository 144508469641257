
.custom-tabs{

    // core

    .tabs-nav{
        .tabs-nav-item{
            &.is-active{
                a{
                    font-weight: bold;
                }
            }
        }
    }
    .tabs-content{
      visibility: hidden;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 0.5s ease-in-out;
        &.is-active{
          visibility: visible;
          opacity: 1;
          height: auto;
        }
        .for-suppliers-modal &{
            overflow: visible;
            &.is-active{              
                height: 100%;
              }
        }
    }

    // globalne stylowanie pod widgety

    .nav-arrows-column{

        width: 50px;
        float: left;
        @include breakpoint(736px down){
          display: none;
        };
        .tabs-nav-prev, .tabs-nav-next{
            display: inline-block;
            background-color: $red-color-site;
            color: #fff;
            height: 45px;
            margin-bottom: 20px;
            width: 45px;
            text-align: center;
            line-height: 45px;
        }
    }
    .tabs-nav-column{
        width: 140px;
        float: left;
        @include breakpoint(736px down){
          width: 100px;
        }
        .tabs-nav{
          @include breakpoint(736px down){
              margin-left:0;
          }
          .tabs-nav-item{
              position: relative;
              top: -13px;
              height: 35px;
              margin-bottom: 20px;
              a{
                  color: $gray;
                  font-size: 25px;
                  text-decoration: none;
                  transition: color 0.25s ease-in-out 0s, font-size 0.25s ease-in-out 0s;
              }
              &.is-active{
                  a{
                      color: $white;
                      font-size: 35px;
                  }
              }
          }
        }

    }
    .tabs-content-column{
        float: left;
        color: $white;
        width: 75%;
        @include breakpoint(1024px down){
            width: 62%;
        }
        @include breakpoint(736px down){
          width:calc(100% - 120px);
        //   margin-left: 20px;
        }
        p{
            padding: 0;
            margin: 0;
            min-height: 0;
        }
        ul {
            margin: 0;
            float: left;
            li{
                margin-bottom: 15px;
                @include breakpoint(736px down){
                  float: left;
                  line-height: 21px;
                  width: 100%;
                }
                a{
                    font-size: 22px;
                }
            }
        }
    }

}
#cg-modal-reports-6{
  .tabs-content-column{
      p{
          padding: 0;
          margin: 0;
          min-height: 0;
          font-size:18px;
          a{
            font-size:18px;
            color:$red-color-site;
            font-weight: bold;
            &:hover{
              text-decoration: underline;
            }
          }
      }
      ul{
          li{
              font-size:18px;
              a{
                  font-size:18px;
              }
          }
      }
  }
}
#cg-modal-reports-3{
  .tabs-content-column{
      color: $white;
      float: right;
      width: calc(100% - 190px);
      opacity: 1;
      -moz-transition: opacity 0.25s ease-in-out;
      -o-transition: opacity 0.25s ease-in-out;
      -webkit-transition: opacity 0.25s ease-in-out;
      transition: opacity 0.25s ease-in-out;
      @include breakpoint(736px down){
        width:calc(100% - 120px);
        margin-left: 20px;
      }
      a{
          font-size: 18px;
          color:$red-color-site;
          font-weight: bold;
          &:hover{
            text-decoration: underline;
          }
      }
      p{
          padding: 0;
          margin: 0;
          min-height: 0;
          font-size: 18px;
          a{
              font-size: 18px;
              color:$red-color-site;
              font-weight: bold;
              &:hover{
                text-decoration: underline;
              }
          }
      }
      ul {
          margin: 0;
          float: left;
          width: 100%;
          li{
              list-style-type: disc;
              font-size: 14px;
              margin-left: 20px;
              margin-bottom: 0;
              @include breakpoint(736px down){
                float: left;
                line-height: 21px;
                width: 100%;
              }
              a{
                  font-size: 14px;
                  color:$red-color-site;
                  font-weight: bold;
                  &:hover{
                    text-decoration: underline;
                  }
              }
          }
      }
  }
}
