form fieldset > .row, .form fieldset > .row{
    border:0px;
    margin-bottom: 0px!important;
    padding: 0px;
    border-top: 0.0625rem solid $border-default-color;
    margin-top: 1.25rem !important;
    padding-top: 1.25rem;
    &:first-of-type{
        border:0px;
        margin-top: 0px;
        padding-top: 0px;
    }
    &:last-of-type{
        border-bottom: 0.0625rem solid $border-default-color;
        margin-bottom: 1.25rem !important;
        padding-bottom: 1.25rem;
    }
}

.forms-module {
    .title {
        color: $secondary-color;
        font-size: rem-calc(18);
        line-height: rem-calc(22);
        font-weight: normal;
        margin: 0;
        padding: rem-calc(0 0 10);
    }

    .content {
        overflow: hidden;
        margin: 0;
        padding: rem-calc(0 0 20);
    }	
    .form-radios-alt{
        border: 0px;
        margin-top: 0px!important;
        padding-top: 0px;
        margin-left: rem-calc(-8px);
        height: 0;
        opacity: 0;
        @include duration();
        &.open{
            opacity: 1;
            height: rem-calc(39px);
            .row{
                margin-left:15px;
            }
        }
    }
    .error{
        color: rgb(255, 29, 0);
        display: inline-block;
        position: relative;
    }
}

.contact{
    .desc_between{
        text-align: center;
        font-size: rem-calc(14);
        font-family: $arial-font;
        margin: rem-calc(20 0);
        padding: rem-calc(0 15);
        max-width: rem-calc(556);
        margin: 0 auto;
        width: 100%;
        margin-bottom: rem-calc(14);
    }

    .error{
        color: rgb(255, 29, 0);
        display: block;
        position: relative;
        top: rem-calc(-10px);
    }

    .form-contant{
        max-width: rem-calc(650);
        width: 100%;
        margin: 0 auto;

        .element{
            label{
                color:$white;
                margin: rem-calc(11 0 8 5);
                font-size: rem-calc(11);
                font-weight: bold;
            }
            input[type=text], input[type=email]{
                height: rem-calc(50px);
            }
            .checkbox{
                li{
                    label{
                        font-size: rem-calc(14px);
                        font-weight: normal;
                        span{
                            margin-top: rem-calc(-3px);
                        }
                    }
                }
            }
        }		
        button{
            display: block;
            margin: 0 auto;
            margin-bottom: rem-calc(30px);
            margin-top: rem-calc(30px);
        }
    }
    .desc_footer{
        width: 100%;
        max-width: rem-calc(650px);
        margin: 0 auto;
        margin-top: rem-calc(58px);
        margin-bottom: rem-calc(86px);
        label{
            font-size: rem-calc(36px);
            font-weight: bold;
            color: #121e2c;
            margin: rem-calc(12px 0 23px);
        }
        p{
            display: none;
        }

        span{
            display: block;
            font-size: rem-calc(14px);
        }
        @media #{breakpoint(small down)}{
            padding: rem-calc(0 10px);
        }
    }
}