form,
.form {
    overflow: hidden;
    margin: 0;
    padding: 0;

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;

        legend {
            @extend .show-for-sr;
        }

        > .row {
            @include border(1, 'bottom');
            margin-bottom: rem-calc(20) !important;
            padding-bottom: rem-calc(20);
            overflow: hidden;

            .columns {
                > label {
                    display: inline-block;
                    padding: 0;
                    margin: rem-calc(0 0 5);
                    font-weight: bold;
                }

                input,
                select,
                textarea {
                    margin: 0 !important;
                }

                textarea {
                    resize: vertical;
                }

                p {
                    margin: 0;
                }

                .required {
                    color: $red;
                    font-family: Verdana;
                    font-size: rem-calc(12);
                    font-style: normal;
                }

                ul {
                    margin: 0 !important;
                    padding: 0 !important;
                    list-style: none !important;

                    li {
                        display: block;
                        margin: 0 !important;
                        padding: 0 !important;

                        label {
                            font-weight: normal;
                        }

                        input {
                            float: left;
                            margin: rem-calc(4 4 0 0) !important
                        }
                    }

                    &.inline-list {
                        li {
                            float: left;
                            width: 33.3%;

                            &:nth-child(4n+4) {
                                clear: both;
                            }
                        }
                    }
                }

                .error {
                    margin: rem-calc(5 0 0);
                    font-style: normal;
                }

                .row,
                ul {
                    + p {
                        color: $gray;
                        clear: both;
                        margin: rem-calc(5 0 0);
                        display: inline-block;
                    }
                }
            }
        }
    }
}
label{
    position: relative;
    display: inline-block;
    input{
        display: none;
    }
    span{
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border: 1px solid $blue-dark;
        background-color: $white;
        margin-right: 5px;
        position: relative;
    }
    input[type=checkbox] + span{
        &:before,&:after{
            width: 3px;
            content: '';
            background-color: $blue-dark;
            display: inline-block;
            position: absolute;
            opacity: 0;
        }
        &:before{
            height: 9px;
            left: 4px;
            top: 7px;
            @include transform(rotate(-32deg));
            @include duration();
        }
        &:after{
            height: 14px;
            left: 10px;
            top: 3px;
            @include transform(rotate(47deg));
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
        }
    }
    input[type=radio] + span{
        border-radius: 30px;
        &:before{
            content: '';
            width: 10px;
            height: 10px;
            background-color: $blue-dark;
            display: block;
            border-radius: 31px;
            @include center();
            position: absolute;
            opacity: 0;
            @include duration();
        }
    }

    input:checked + span:before,
    input:checked + span:after{
        opacity: 1;
    }
}